import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D03PieChart1 = ({ filterCriteria = {} }) => {
  const [showMessage, setShowMessage] = useState(false); // State for controlling the message visibility

  useEffect(() => {
    if (!jsonData || !Array.isArray(jsonData)) {
      console.error("jsonData is not loaded correctly or is not an array.");
      return;
    }

    const filteredData = jsonData.filter((item) => {
      if (!filterCriteria || typeof filterCriteria !== "object") {
        console.error("filterCriteria is not an object.");
        return false;
      }
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    });

    let favorable = 0;
    let neutral = 0;
    let unfavorable = 0;
    let noResponse = 0;

    filteredData.forEach((response) => {
      const { MQ0026, MQ0095, MQ0302, MQ0321 } = response;

      if (
        (MQ0026 === 4 || MQ0026 === 5) &&
        (MQ0095 === 4 || MQ0095 === 5) &&
        (MQ0302 === 4 || MQ0302 === 5) &&
        (MQ0321 === 4 || MQ0321 === 5)
      ) {
        favorable++;
      } else if (
        (MQ0026 === 3 || MQ0095 === 3 || MQ0302 === 3 || MQ0321 === 3) &&
        !(
          MQ0026 === 1 ||
          MQ0026 === 2 ||
          MQ0095 === 1 ||
          MQ0095 === 2 ||
          MQ0302 === 1 ||
          MQ0302 === 2 ||
          MQ0321 === 1 ||
          MQ0321 === 2
        )
      ) {
        neutral++;
      } else if (
        MQ0026 === 1 ||
        MQ0026 === 2 ||
        MQ0095 === 1 ||
        MQ0095 === 2 ||
        MQ0302 === 1 ||
        MQ0302 === 2 ||
        MQ0321 === 1 ||
        MQ0321 === 2
      ) {
        unfavorable++;
      } else {
        noResponse++;
      }
    });

    // Calculate total respondents
    const totalRespondents = favorable + neutral + unfavorable + noResponse;

    // Show or hide the message based on respondent count
    setShowMessage(totalRespondents < 4);

    // Prepare the chart data
    const optionsPie1 = {
      series: [favorable, neutral, unfavorable, noResponse],
      chart: {
        height: "400px",
        offsetY: 35,
        type: "pie",
        toolbar: {
          show: true,
        },
      },
      labels: ["Favorable", "Neutral", "Unfavorable", "No Response"],
      colors: ["#499894", "#f1ce63", "#f28e2b", "#bab0ac"],
      legend: {
        position: "bottom",
      },
    };

    const chartElement = document.querySelector("#pie-1");

    if (chartElement) {
      chartElement.innerHTML = ''; // Clear previous chart instance
    }

    const pie1 = new ApexCharts(chartElement, optionsPie1);
    pie1.render();

    // Clean up the chart on component unmount
    return () => {
      if (pie1) {
        pie1.destroy();
      }
    };
  }, [filterCriteria]);

  return (
    <div>
      {/* Show message if fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}
      {/* Chart will be displayed if there are enough respondents */}
      <div id="pie-1" style={{ display: showMessage ? "none" : "block" }}></div>
    </div>
  );
};

export default D03PieChart1;
