import { Text } from "./..";
import React from "react";

export default function LandingPageStackvectorOne({ facebookads = "Facebook Ads", ...props }) {
  return (
    <div {...props} className={`${props.className} h-[17px] w-full md:h-auto relative`}>
      <div className="h-[8px] w-[8px] rounded bg-pink-500" />
      <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max w-full items-center gap-[9px]">
        <div className="h-[8px] w-[8px] self-end rounded bg-pink-500" />
        <Text size="xs" as="p" className="!text-blue_gray-800">
          {facebookads}
        </Text>
      </div>
    </div>
  );
}
