import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useFilter } from "../../../../FilterContext"; // Ensure the path is correct
import jsonData from "../../../../som_2024-sm-data-numeric.json";

const D03HorizontalBarCharts = () => {
  const { filterCriteria } = useFilter();

  const [filteredScores1, setFilteredScores1] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [unfilteredScores1, setUnfilteredScores1] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [filteredScores2, setFilteredScores2] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [unfilteredScores2, setUnfilteredScores2] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [showMessage, setShowMessage] = useState(false); // State for controlling the message visibility

  useEffect(() => {
    const processScores = (data, question) => {
      const scores = {
        NR: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      };

      data.forEach((item) => {
        const score = item[question];
        if (
          score === 1 ||
          score === 2 ||
          score === 3 ||
          score === 4 ||
          score === 5
        ) {
          scores[score]++;
        } else {
          scores.NR++;
        }
      });

      return scores;
    };

    if (!jsonData || !Array.isArray(jsonData)) {
      console.error("jsonData is not loaded correctly or is not an array.");
      return;
    }

    if (!filterCriteria || typeof filterCriteria !== "object") {
      console.error("filterCriteria is not an object.");
      return;
    }

    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        const criteria = filterCriteria[key];
        if (Array.isArray(criteria)) {
          return criteria.length === 0 || criteria.includes(item[key]);
        }
        return criteria === "" || item[key] === criteria;
      });
    });

    // Determine the total number of filtered respondents
    const totalFilteredRespondents = filteredData.length;

    // Show or hide the message based on respondent count
    setShowMessage(totalFilteredRespondents < 4);

    // If not enough respondents, stop further processing
    if (totalFilteredRespondents < 4) return;

    const newFilteredScores1 = processScores(filteredData, "MQ0299");
    const newUnfilteredScores1 = processScores(jsonData, "MQ0299");

    setFilteredScores1(newFilteredScores1);
    setUnfilteredScores1(newUnfilteredScores1);

    const newFilteredScores2 = processScores(filteredData, "MQ0301");
    const newUnfilteredScores2 = processScores(jsonData, "MQ0301");

    setFilteredScores2(newFilteredScores2);
    setUnfilteredScores2(newUnfilteredScores2);
  }, [filterCriteria]);

  const calculatePercentages = (scores) => {
    const total = Object.values(scores).reduce((acc, curr) => acc + curr, 0);
    return total > 0
      ? Object.keys(scores).reduce((acc, key) => {
          acc[key] = (scores[key] / total) * 100;
          return acc;
        }, {})
      : scores;
  };

  const createSeriesData = (filteredScores, unfilteredScores) => {
    const filteredPercentages = calculatePercentages(filteredScores);
    const unfilteredPercentages = calculatePercentages(unfilteredScores);

    return Object.keys(filteredPercentages).map((key) => {
      return {
        x: key,
        y: filteredPercentages[key],
        goals: [
          {
            name: "Unfiltered",
            value: unfilteredPercentages[key],
            strokeWidth: 5,
            strokeHeight: 10,
            strokeLineCap: "round",
            strokeColor: "#888888",
          },
        ],
      };
    });
  };

  const seriesData1 = createSeriesData(filteredScores1, unfilteredScores1);
  const seriesData2 = createSeriesData(filteredScores2, unfilteredScores2);

  const options = {
    chart: {
      type: "bar",
      height: "225px",
    },
    colors: ["#BAB0AC", "#F28E2B", "#F28E2B", "#F1CE63", "#499894", "#499894"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 70,
      textAnchor: "end",
      style: {
        colors: ["#000"],
      },
      formatter: function (val, opt) {
        const goals =
          opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;
        if (goals && goals.length) {
          return `${parseFloat(val).toFixed(1)}% / ${parseFloat(
            goals[0].value
          ).toFixed(1)}%`;
        }
        return `${parseFloat(val).toFixed(1)}%`;
      },
    },
    xaxis: {
      categories: ["NR", "1", "2", "3", "4", "5"],
    },
    tooltip: {
      enabled: true,
      y: {
        title: "Score",
      },
    },
    legend: {
      show: false,
    },
    annotations: {
      points: Object.keys(unfilteredScores1).map((key) => {
        return {
          x: key,
          y: unfilteredScores1[key],
          marker: {
            size: 0,
            fillColor: "#888888",
            strokeColor: "#888888",
          },
          label: {
            borderColor: "#888888",
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#888888",
            },
          },
        };
      }),
    },
  };

  return (
    <div>
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}
      {/* Only render charts if there are enough respondents */}
      <div style={{ display: showMessage ? "none" : "block" }}>
        <p>Self Reported Engagement</p>
        <div id="sre-bar">
          <Chart
            options={options}
            series={[{ name: "Filtered", data: seriesData1 }]}
            type="bar"
            height="225px"
          />
        </div>
        <p>Self Reported Retention</p>
        <div id="srr-bar">
          <Chart
            options={options}
            series={[{ name: "Filtered", data: seriesData2 }]}
            type="bar"
            height="225px"
          />
        </div>
      </div>
    </div>
  );
};

export default D03HorizontalBarCharts;
