import React, { useState, useCallback } from 'react';
import NavigationSidebar from '../components/Navigationsidebar/NavigationSidebar';
import Header from '../components/Header';
import BarChartViz01 from '../components/Dashboardviz/D14/D14Viz01';
import './CCIYOYAreaDashboard.css'; // Import the CSS file

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Default state is now closed

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prevState => !prevState);
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className="cciyoyarea-dashboard-container">
      <Header title="Dashboard Title" toggleSidebar={toggleSidebar} refreshPage={refreshPage} />
      <NavigationSidebar className={isSidebarOpen ? 'cciyoyarea-sidebar-open' : 'cciyoyarea-sidebar-closed'} isOpen={isSidebarOpen} />
      <div className="cciyoyarea-main-content">
        <div className="cciyoyarea-charts-container">
          <div className="cciyoyarea-chart">
            <h2 className="cciyoyarea-chart-title">Year over Year by Area</h2>
            <div className="cciyoyarea-chart-container">
              <BarChartViz01 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
