import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D01BarChartViz08 = ({ filterCriteria }) => {
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const filterData = (item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    };

    const filteredData = jsonData.filter(filterData);

    const genderCounts = filteredData.reduce((acc, item) => {
      const gender = item.gender_name;
      if (!acc[gender]) {
        acc[gender] = 0;
      }
      acc[gender]++;
      return acc;
    }, {});

    // Sort genderNames alphabetically
    const genderNames = Object.keys(genderCounts).sort((a, b) => a.localeCompare(b));
    const chartData = genderNames.map(gender => genderCounts[gender]);

    setCategories(genderNames);
    setData(chartData);

    const barHeight = 25; // Set bar height in pixels
    const minBars = 10; // Minimum number of bars to display
    const chartHeight = Math.max(
      barHeight * genderNames.length,
      barHeight * minBars
    ); // Total chart height

    const optionsViz08 = {
      series: [
        {
          name: "Count",
          data: chartData,
        },
      ],
      colors: ["#004983"],
      chart: {
        type: "bar",
        height: chartHeight, // Dynamic height based on number of bars
        width: "95%",
      },
      legend: {
        show: false,
      },
      title: {
        text: 'Gender',
        align: 'Center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '20px',
          fontWeight:  'bold',
          fontFamily:  undefined,
          color:  '#263238'
        },
    },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
          distributed: false,
          barHeight: "22.5px",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 28, // Move labels off the bars
        style: {
          colors: ["#000"], // Set data labels to black
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 2.5,
          color: "#fff",
          opacity: 1,
        },
      },
      xaxis: {
        categories: genderNames,
      },
    };

    const viz08 = new ApexCharts(
      document.querySelector("#viz-08"),
      optionsViz08
    );
    viz08.render();

    // Clean up the chart on component unmount
    return () => {
      viz08.destroy();
    };
  }, [filterCriteria]); // Re-run effect when filterCriteria changes

  return (
    <div style={{ overflowY: "auto", maxHeight: "450px", minWidth: "500px"  }}>
      <div id="viz-08"></div>
    </div>
  );
};

export default D01BarChartViz08;
