import { Helmet } from "react-helmet";
import { Button, Text, Img, Heading } from "../components";
import { ReactTable } from "../components/ReactTable";
import Sidebar2 from "../components/Sidebar2";
import { createColumnHelper } from "@tanstack/react-table";
import React, { Suspense } from "react";

const data = [
  { priceText: "$230,220", statusText: "+55% since last month", salesLabel: "KPI1" },
  { priceText: "3.200", statusText: "+12% since last month", salesLabel: "KPI2" },
  { priceText: "3.200", statusText: "+12% since last month", salesLabel: "KPI3" },
];
const table1Data = [
  { productColumn: "Nike v22 Running" },
  {},
  { productColumn: "Business Kit (Mug + Notebook)" },
  {},
  { productColumn: "images/img_image_product_1.png" },
  {},
];
const table2Data = [
  { productTextClone: "Nike v22 Running" },
  {
    productLineClone: "images/img_image_product_48x48.png",
    productTextClone: "Business Kit (Mug + Notebook)",
    valueTextClone: "\t$80.250",
    adsSpentTextClone: "$4.200",
    refundsTextClone: "40",
  },
  {},
  {
    productLineClone: "images/img_image_product_48x48.png",
    productTextClone: "Black Chair",
    valueTextClone: "$40.600",
    adsSpentTextClone: "$9.430",
    refundsTextClone: "54",
  },
  { productTextClone: "Wireless Charger" },
  {
    productLineClone: "images/img_image_product_2.png",
    productTextClone: "Mountain Trip Kit (Camera + Backpack)",
    valueTextClone: "$140.925",
    adsSpentTextClone: "$20.531",
    refundsTextClone: "121",
  },
];

export default function ComposePage() {
  const table1Columns = React.useMemo(() => {
    const table1ColumnHelper = createColumnHelper();
    return [
      table1ColumnHelper.accessor("productColumn", {
        cell: (info) => (
          <div className="flex-1 md:self-stretch">
            <div className="flex items-center md:flex-col">
              <Img
                src="images/img_image_product.png"
                alt="imageproduct"
                className="h-[48px] w-[48px] rounded-[24px] object-cover md:w-full"
              />
              <div className="ml-4 flex flex-1 flex-col items-start gap-0.5 md:ml-0 md:self-stretch">
                <Heading as="h6" className="!text-blue_gray-800">
                  {info?.getValue?.()}
                </Heading>
                <Text as="p" className="!text-blue_gray-800">
                  <span className="text-green-500">8.232</span>
                  <span className="text-blue_gray-800">&nbsp;</span>
                  <span className="text-blue_gray-400_01">orders</span>
                </Text>
              </div>
              <Text as="p" className="ml-[537px] md:ml-0">
                $130.992
              </Text>
              <Text as="p" className="ml-[166px] md:ml-0">
                $9.500
              </Text>
              <Text as="p" className="ml-[162px] h-[17px] w-[16px] md:ml-0">
                13
              </Text>
            </div>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-col items-center gap-[15px]">
            <div className="flex flex-wrap">
              <Heading size="xs" as="h2">
                PRODUCT
              </Heading>
              <Heading size="xs" as="h3" className="ml-[664px]">
                VALUE
              </Heading>
              <Heading size="xs" as="h4" className="ml-[190px]">
                ADS SPENT
              </Heading>
              <Heading size="xs" as="h5" className="ml-[151px]">
                REFUNDS
              </Heading>
            </div>
            <div className="h-px w-full self-stretch bg-gray-100" />
          </div>
        ),
        meta: { width: "1278px" },
      }),
    ];
  }, []);
  const table2Columns = React.useMemo(() => {
    const table2ColumnHelper = createColumnHelper();
    return [
      table2ColumnHelper.accessor("productLineClone", {
        cell: (info) => (
          <Img
            src="images/img_image_product.png"
            alt="product image clone"
            className="h-[48px] w-[48px] rounded-[24px] object-cover md:w-full"
          />
        ),
        header: (info) => <div className="relative z-[2] h-px bg-gray-100" />,
        meta: { width: "1278px" },
      }),
      table2ColumnHelper.accessor("productTextClone", {
        cell: (info) => (
          <div className="ml-4 flex flex-1 flex-col items-start gap-0.5 md:ml-0 md:self-stretch">
            <Heading as="p" className="!text-blue_gray-800">
              {info?.getValue?.()}
            </Heading>
            <Text as="p" className="!text-blue_gray-800">
              <span className="text-green-500">8.232</span>
              <span className="text-blue_gray-800">&nbsp;</span>
              <span className="text-blue_gray-400_01">orders</span>
            </Text>
          </div>
        ),
        header: (info) => (
          <Heading size="xs" as="p" className="relative mb-4 ml-[-1253px] md:ml-0">
            PRODUCT
          </Heading>
        ),
        meta: { width: "46px" },
      }),
      table2ColumnHelper.accessor("valueTextClone", {
        cell: (info) => (
          <Text as="p" className="ml-[537px] md:ml-0">
            $130.992
          </Text>
        ),
        header: (info) => (
          <Heading size="xs" as="p" className="ml-[664px] md:ml-0">
            VALUE
          </Heading>
        ),
        meta: { width: "31px" },
      }),
      table2ColumnHelper.accessor("adsSpentTextClone", {
        cell: (info) => (
          <Text as="p" className="ml-[166px] md:ml-0">
            $9.500
          </Text>
        ),
        header: (info) => (
          <Heading size="xs" as="p" className="ml-[190px] md:ml-0">
            ADS SPENT
          </Heading>
        ),
        meta: { width: "54px" },
      }),
      table2ColumnHelper.accessor("refundsTextClone", {
        cell: (info) => (
          <Text as="p" className="ml-[162px] h-[17px] w-[16px] md:ml-0">
            13
          </Text>
        ),
        header: (info) => (
          <Heading size="xs" as="p" className="ml-[151px] md:ml-0">
            REFUNDS
          </Heading>
        ),
        meta: { width: "44px" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Sales Dashboard - Monitor Your KPIs and Top Products</title>
        <meta
          name="description"
          content="Explore our Sales Dashboard to track KPIs, analyze top-selling products, and optimize ad spend. Gain insights into your sales performance and growth trends."
        />
      </Helmet>

      {/* main layout section */}
      <div className="w-full bg-gray-100 px-[17px] pt-[17px]">
        {/* content area section */}
        <div className="flex items-start gap-[31px] md:flex-col">
          {/* sidebar navigation section */}
          <Sidebar2 />
          <div className="mt-[22px] flex flex-1 flex-col items-start md:self-stretch">
            {/* header section */}
            <header className="flex flex-col gap-[41px] self-stretch">
              {/* header content section */}
              {/* <div className="flex items-center justify-between gap-5 sm:flex-col">
                <div className="flex w-[21%] justify-center sm:w-full">
                  <div className="flex w-full flex-col">
                    <div className="relative z-[1] flex flex-wrap items-center gap-[7px]">
                      <a href="#">
                        <Img
                          src="images/img_icon_16px_home_breadcrumbs.svg"
                          alt="home icon"
                          className="h-[16px] w-[16px]"
                        />
                      </a>
                      <Text as="p" className="self-end">
                        / Pages /{" "}
                      </Text>
                      <Text as="p" className="!text-blue_gray-800">
                        Sales
                      </Text>
                    </div>
                    <div className="relative mt-[-3px] flex items-start justify-between gap-5">
                      <Heading size="lg" as="h6" className="!text-blue_gray-800">
                        Sales
                      </Heading>
                      <a href="#">
                        <Img src="images/img_megaphone.svg" alt="megaphone icon" className="h-[16px] w-[16px]" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex w-[24%] items-center justify-center sm:w-full">
                  <div className="relative h-[42px] flex-1 rounded-lg border border-solid border-blue_gray-400_01 md:h-auto">
                    <Text as="p" className="ml-[15px] md:ml-0">
                      Search here
                    </Text>
                  </div>
                  <a href="#">
                    <Img src="images/img_lock.svg" alt="lock icon" className="ml-[17px] h-[16px] w-[16px]" />
                  </a>
                  <a href="#">
                    <Img src="images/img_search.svg" alt="search icon" className="ml-6 h-[16px] w-[16px]" />
                  </a>
                  <div className="relative ml-4 mt-1.5 h-[23px] w-[15%] self-start">
                    <a href="#">
                      <Img
                        src="images/img_icon_16px_notification.svg"
                        alt="notification icon"
                        className="absolute bottom-[0.00px] left-[0.00px] m-auto h-[16px] w-[16px]"
                      />
                    </a>
                    <Heading
                      size="xs"
                      as="p"
                      className="absolute right-[0.00px] top-[0.00px] m-auto flex items-center justify-center rounded-[9px] bg-pink-500 px-2 py-[3px] !text-white-A700"
                    >
                      11
                    </Heading>
                  </div>
                </div>
              </div> */}

              {/* dashboard summary section */}
              <div className="flex gap-6 md:flex-col">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {data.map((d, index) => (
                    <div
                      key={"listColumn" + index}
                      className="flex w-full flex-col items-start justify-center gap-[5px] rounded-[12px] bg-white-A700 p-4 shadow-xs"
                    >
                      <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                        <Heading as="p">{d.salesLabel}</Heading>
                        <Text size="xs" as="p" className="self-end !text-blue_gray-400">
                          6 May - 7 May
                        </Text>
                      </div>
                      <Heading size="xl" as="h5" className="!text-blue_gray-800">
                        {d.priceText}
                      </Heading>
                      <Heading as="p" className="!text-blue_gray-800">
                        <span className="text-green-500">+55%</span>
                        <span className="text-blue_gray-800">&nbsp;</span>
                        <span className="font-normal text-blue_gray-400">since last month</span>
                      </Heading>
                    </div>
                  ))}
                </Suspense>
              </div>
            </header>

            {/* top selling products section */}
            <div className="mt-[69px] flex flex-col items-start gap-6 self-stretch rounded-[12px] bg-white-A700 py-3.5 shadow-xs">
              <Heading size="lg" as="h1" className="ml-[23px] mt-4 !text-blue_gray-800 md:ml-0">
                Top Selling Products
              </Heading>
              <ReactTable
                bodyProps={{ className: "" }}
                headerProps={{ className: "" }}
                rowDataProps={{ className: "md:flex-col" }}
                className="self-stretch"
                columns={table1Columns}
                data={table1Data}
              />
            </div>

            {/* additional top selling products section */}
            <div className="mt-[21px] flex flex-col items-start gap-6 self-stretch rounded-[12px] bg-white-A700 py-3.5 shadow-xs">
              <Heading size="lg" as="h6" className="ml-[23px] mt-4 !text-blue_gray-800 md:ml-0">
                Top Selling Products
              </Heading>
              <ReactTable
                bodyProps={{ className: "" }}
                headerProps={{ className: "md:flex-col" }}
                rowDataProps={{ className: "" }}
                className="self-stretch"
                columns={table2Columns}
                data={table2Data}
              />
            </div>

            {/* channels info section */}
            <div className="ml-[13px] mt-[21px] flex w-[32%] justify-center rounded-[12px] bg-white-A700 p-4 shadow-xs md:ml-0 md:w-full">
              <div className="flex w-full flex-col gap-[45px]">
                <div className="flex flex-wrap items-center justify-between gap-5">
                  <Heading size="lg" as="h6" className="self-start !text-blue_gray-800">
                    Channels
                  </Heading>
                  <Heading
                    as="p"
                    className="flex h-[24px] w-[24px] items-center justify-center rounded-[12px] border border-solid border-blue_gray-400_01 text-center"
                  >
                    !
                  </Heading>
                </div>
                <div className="ml-[7px] flex w-[80%] md:ml-0 md:w-full">
                  <div className="flex w-full items-center gap-[30px]">
                    <Img src="images/img_chart.svg" alt="chart image" className="h-[198px] w-[198px]" />
                    <div className="flex w-[26%] flex-col gap-[18px]">
                      <div className="flex items-center gap-[9px]">
                        <div className="h-[8px] w-[8px] self-end rounded bg-blue-700" />
                        <Text size="xs" as="p" className="!text-blue_gray-800">
                          Facebook
                        </Text>
                      </div>
                      <div className="flex w-[70%] items-center gap-[9px] md:w-full">
                        <div className="h-[8px] w-[8px] rounded bg-pink-500" />
                        <Text size="xs" as="p" className="!text-blue_gray-800">
                          Direct
                        </Text>
                      </div>
                      <div className="flex items-start gap-[9px]">
                        <div className="h-[8px] w-[8px] rounded bg-blue_gray-800" />
                        <Text size="xs" as="p" className="!text-blue_gray-800">
                          Organic
                        </Text>
                      </div>
                      <div className="flex items-center gap-[9px]">
                        <div className="h-[8px] w-[8px] self-end rounded bg-blue_gray-400_01" />
                        <Text size="xs" as="p">
                          Referral
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-5">
                  <Text as="p" className="w-[61%] leading-[140%]">
                    <span className="text-blue_gray-400_01">More than&nbsp;</span>
                    <span className="font-bold text-blue_gray-400_01">1,200,000</span>
                    <span className="text-blue_gray-400_01">
                      &nbsp;sales are made using referral marketing, and&nbsp;
                    </span>
                    <span className="font-bold text-blue_gray-400_01">700,000</span>
                    <span className="text-blue_gray-400_01">&nbsp;are from social media.</span>
                  </Text>
                  <Button shape="round" className="min-w-[115px] self-end sm:px-5">
                    READ MORE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
