import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct
import "../../../../pages/CCIDemoResponseDashboard.css"; // Import the CSS file

const MultipleBarCharts = ({
  selectedQuestion,
  selectedDemographic,
  filterCriteria,
}) => {
  const [chartConfigs, setChartConfigs] = useState([]);
  const [demographicValues, setDemographicValues] = useState([]);

  useEffect(() => {
    const getSortedFieldValues = (fieldName, order) => {
      return [...new Set(jsonData.map((item) => item[fieldName]))].sort(
        (a, b) => order.indexOf(a) - order.indexOf(b)
      );
    };

    const demographicValues =
      selectedDemographic === "length_of_service_name"
        ? getSortedFieldValues("length_of_service_name", [
            "Less than 1 year",
            "1 to 2 years",
            "3 to 5 years",
            "6 to 10 years",
            "11 to 19 years",
            "20 years or more",
          ])
        : selectedDemographic === "generation_name"
        ? getSortedFieldValues("generation_name", [
            "Traditionalist",
            "Baby Boomer",
            "Generation X",
            "Millennial",
            "Generation Z",
            "Generation Next",
            "No Response",
          ])
        : getFilteredDemographics(selectedDemographic);

    setDemographicValues(demographicValues);

    const totalResponses = jsonData.length;

    const favorabilityData = demographicValues.reduce(
      (acc, value) => {
        const filteredData = filterDataByCriteria(
          jsonData,
          selectedDemographic,
          value,
          filterCriteria
        );
        let favorable = 0;
        let neutral = 0;
        let unfavorable = 0;
        let noResponse = 0;

        filteredData.forEach((response) => {
          const answer = response[selectedQuestion];

          if (answer === 4 || answer === 5) {
            favorable++;
          } else if (answer === 3) {
            neutral++;
          } else if (answer === 1 || answer === 2) {
            unfavorable++;
          } else {
            noResponse++;
          }
        });

        acc.favorable.push((favorable / totalResponses) * 100);
        acc.neutral.push((neutral / totalResponses) * 100);
        acc.unfavorable.push((unfavorable / totalResponses) * 100);
        acc.noResponse.push((noResponse / totalResponses) * 100);

        return acc;
      },
      { favorable: [], neutral: [], unfavorable: [], noResponse: [] }
    );

    const configs = [
      {
        id: "bars-NR",
        title: "No Response",
        series: [
          {
            name: "Percent",
            data: favorabilityData.noResponse,
          },
        ],
        colors: ["#bab0ac"],
      },
      {
        id: "bars-UF",
        title: "Unfavorable",
        series: [
          {
            name: "Percent",
            data: favorabilityData.unfavorable,
          },
        ],
        colors: ["#f28e2b"],
      },
      {
        id: "bars-NT",
        title: "Neutral",
        series: [
          {
            name: "Percent",
            data: favorabilityData.neutral,
          },
        ],
        colors: ["#f1ce63"],
      },
      {
        id: "bars-FV",
        title: "Favorable",
        series: [
          {
            name: "Percent",
            data: favorabilityData.favorable,
          },
        ],
        colors: ["#499894"],
      },
    ];

    setChartConfigs(configs);
  }, [selectedQuestion, selectedDemographic, filterCriteria]);

  useEffect(() => {
    const barHeight = 30; // Standard height for each bar
    const chartHeight = demographicValues.length * barHeight + 100; // 100px for padding and legends

    const charts = chartConfigs.map((config) => {
      const element = document.querySelector(`#${config.id}`);
      if (element) {
        const chart = new ApexCharts(element, {
          series: config.series,
          colors: config.colors,
          chart: {
            type: "bar",
            height: chartHeight,
            width: "100%",
            stacked: true,
          },
          legend: {
            show: false,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 8,
                  style: {
                    fontSize: "12px",
                    fontWeight: 700,
                  },
                },
              },
            },
          },
          title: {
            text: config.title,
            align: "center",
            style: {
              fontSize: "14px",
              fontFamily: "Ubuntu",
              color: "#3d3d3d",
            },
          },
          dataLabels: {
            enabled: false,
            offsetX: -2,
            style: {
              colors: ["#3d3d3d"],
            },
            formatter: function (val) {
              return isNaN(val) ? "0%" : `${val.toFixed(1)}%`;
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return isNaN(val) ? "0%" : `${val.toFixed(1)}%`;
              },
            },
          },
          xaxis: {
            categories: demographicValues,
          },
        });

        chart.render();
        return chart;
      }
      return null;
    });

    return () => {
      charts.forEach((chart) => {
        if (chart) {
          chart.destroy();
        }
      });
    };
  }, [chartConfigs, demographicValues]);

  const getFilteredDemographics = (demographicType) => {
    return [...new Set(jsonData.map((item) => item[demographicType]))].sort();
  };

  const filterDataByCriteria = (
    data,
    demographicType,
    demographicValue,
    criteria
  ) => {
    return data.filter((item) => {
      let match = true;
      Object.keys(criteria).forEach((key) => {
        if (criteria[key].length > 0 && !criteria[key].includes(item[key])) {
          match = false;
        }
      });
      return match && item[demographicType] === demographicValue;
    });
  };

  return (
    <div className="CCIDemoResponse-internal-charts">
      {chartConfigs.map((config) => (
        <div
          key={config.id}
          className="CCIDemoResponse-internal-chart-container"
        >
          <div id={config.id} className="CCIDemoResponse-internal-chart"></div>
        </div>
      ))}
    </div>
  );
};

export default MultipleBarCharts;
