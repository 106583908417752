import React, { useRef, useState, useEffect, useCallback } from 'react';
import PptxGenJS from 'pptxgenjs';
import Select from 'react-select';
import captureChartAsImage from '../utils/captureChartAsImage'; // Updated for optimized image capture
import D01BarChartViz01 from '../components/Dashboardviz/D01/DB01Viz1/index copy';
import D01BarChartViz02 from '../components/Dashboardviz/D01/DB01Viz2/index copy';
import D01BarChartViz03 from '../components/Dashboardviz/D01/DB01Viz3/index copy';
import D01BarChartViz04 from '../components/Dashboardviz/D01/DB01Viz4/index copy';
import D01BarChartViz05 from '../components/Dashboardviz/D01/DB01Viz5/index copy';
import D01BarChartViz06 from '../components/Dashboardviz/D01/DB01Viz6/index copy';
import D01BarChartViz07 from '../components/Dashboardviz/D01/DB01Viz7/index copy';
import D01BarChartViz08 from '../components/Dashboardviz/D01/DB01Viz8/index copy';
import D01BarChartViz09 from '../components/Dashboardviz/D01/DB01Viz9/index copy';
import D01BarChartViz10 from '../components/Dashboardviz/D01/Db01Viz10/index copy';
import D02DonutChart1 from '../components/Dashboardviz/D02/DB02Viz2/index copy';
import D02DonutChart2 from '../components/Dashboardviz/D02/DB02Viz3/index copy';
import D02DonutChart3 from '../components/Dashboardviz/D02/DB02Viz4/index copy';
import D02DonutChart4 from '../components/Dashboardviz/D02/DB02Viz5/index copy';
import D02PieChart1 from '../components/Dashboardviz/D02/DB02Viz1/index copy';
import D03BarChartViz03 from '../components/Dashboardviz/D03/DB03Viz3/index copy';
import RoleClarityChart from '../components/Dashboardviz/D03/DB03Viz4/RoleClarityChart';
import OrganizationalCommitmentchart from '../components/Dashboardviz/D03/DB03Viz4/OrganizationalCommitmentChart';
import WorkContextChart from '../components/Dashboardviz/D03/DB03Viz4/WorkContextChart';
import JobSatisfactionChart from '../components/Dashboardviz/D03/DB03Viz4/JobSatisfactionChart';
import D03Scatterplot01 from '../components/Dashboardviz/D03/DB03Viz2/index copy';
import D04StackedBarChart from '../components/Dashboardviz/D04/DB04Viz1/index copy';
import D04MultipleBarCharts from '../components/Dashboardviz/D04/DB04Viz2/index copy';
import D05BarChartViz01 from '../components/Dashboardviz/D05/D05Viz01/index copy';
import D05BarChartViz02 from '../components/Dashboardviz/D05/D05Viz02/index copy';
import D05BarChartViz03 from '../components/Dashboardviz/D05/D05Viz03/index copy';
import D05BarChartViz04 from '../components/Dashboardviz/D05/D05Viz04/index copy';
import D06Heatmap1 from '../components/Dashboardviz/D06/D06Viz05/index copy';
import D06Heatmap2 from '../components/Dashboardviz/D06/D06Viz06/index copy';
import D06Heatmap3 from '../components/Dashboardviz/D06/D06Viz07/index copy';
import D06Heatmap4 from '../components/Dashboardviz/D06/D06Viz08/index copy';
import D07RangeChart1 from '../components/Dashboardviz/D07/D07Viz01/index copy';
import D07RangeChart2 from '../components/Dashboardviz/D07/D07Viz02/index copy';
import D07RangeChart3 from '../components/Dashboardviz/D07/D07Viz03/index copy';
import D07RangeChart4 from '../components/Dashboardviz/D07/D07Viz04/index copy';
import D08BarChartViz01 from '../components/Dashboardviz/D08/D08Viz01/index copy';
import D08BarChartViz02 from '../components/Dashboardviz/D08/D08Viz02/index copy';
import D08BarChartViz03 from '../components/Dashboardviz/D08/D08Viz03/index copy';
import D08BarChartViz04 from '../components/Dashboardviz/D08/D08Viz04/index copy';
import D08BarChartViz05 from '../components/Dashboardviz/D08/D08Viz05/index copy';
import NavigationSidebar from '../components/Navigationsidebar/NavigationSidebar';
import Header from '../components/Header';
import FilterSidebar from '../components/FilterSidebar/FilterSidebar';
import ErrorBoundary from '../components/ErrorBoundary';
import { useFilter } from '../FilterContext';
import jsonData from '../som_2024-sm-data-numeric.json';
import './GenerateReport.css';

const GenerateReport = () => {
  const { filterCriteria, setFilterCriteria } = useFilter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [selectedDashboards, setSelectedDashboards] = useState([]);
  const [selectedDemographic, setSelectedDemographic] = useState('generation_name');
  const [selectedQuestion, setSelectedQuestion] = useState('MQ0026');

  const chartRefs = {
    D01: [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)],
    D02: [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)],
    D03: [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)],
    D04: [useRef(null), useRef(null)],
    D05: [useRef(null), useRef(null), useRef(null), useRef(null)],
    D06: [useRef(null), useRef(null), useRef(null), useRef(null)],
    D07: [useRef(null), useRef(null), useRef(null), useRef(null)],
    D08: [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)],
  };

  useEffect(() => {
    const uniqueLocations = getUniqueFieldValues('location_name');
    const uniqueBusinessUnits = getUniqueFieldValues('business_unit_name');
    const uniqueDepartments = getUniqueFieldValues('department_name');
    const uniqueJobClassifications = getUniqueFieldValues('job_classification_name');
    const uniqueTenures = getSortedFieldValues('length_of_service_name', [
      'Less than 1 year', '1 to 2 years', '3 to 5 years', '6 to 10 years', '11 to 19 years', '20 or more years'
    ]);
    const uniqueGenerations = getSortedFieldValues('generation_name', [
      'Traditionalist', 'Baby Boomer', 'Generation X', 'Millennial', 'Generation Z', 'Generation Next', 'No Response'
    ]);
    const uniqueExemptStatuses = getUniqueFieldValues('exempt_status_name');
    const uniqueGenders = getUniqueFieldValues('gender_name');
    const uniqueRaces = getUniqueFieldValues('race_name');
    const uniqueSupervisors = getUniqueFieldValues('additional_demographic_01_name');

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  // Function to get unique field values from jsonData
  const getUniqueFieldValues = (fieldName) => {
    return [...new Set(jsonData.map(item => item[fieldName]))].sort();
  };

  // Function to get sorted field values based on a specific order
  const getSortedFieldValues = (fieldName, order) => {
    return [...new Set(jsonData.map(item => item[fieldName]))].sort((a, b) => order.indexOf(a) - order.indexOf(b));
  };

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prevState => !prevState);
  }, []);

  const toggleFilterSidebar = useCallback(() => {
    setIsFilterSidebarOpen(prevState => !prevState);
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  const handleFilterChange = (selectedOptions, actionMeta) => {
    const { name } = actionMeta;
    const allOptions = filterData[name];
    if (selectedOptions && selectedOptions.some(option => option.value === 'select-all')) {
      if (selectedOptions.length === allOptions.length + 1) {
        setFilterCriteria(prevState => ({
          ...prevState,
          [name]: [],
        }));
      } else {
        setFilterCriteria(prevState => ({
          ...prevState,
          [name]: allOptions.map(option => option.value),
        }));
      }
    } else {
      setFilterCriteria(prevState => ({
        ...prevState,
        [name]: selectedOptions ? selectedOptions.map(option => option.value) : [],
      }));
    }
  };

  const handleDemographicChange = selectedOption => {
    setSelectedDemographic(selectedOption.value);
  };

  const handleQuestionChange = selectedOption => {
    setSelectedQuestion(selectedOption.value);
  };

  const handleDashboardChange = (selectedOptions) => {
    setSelectedDashboards(selectedOptions.map(option => option.value));
  };

  const resetSelections = () => {
    setSelectedDashboards([]);
    setSelectedDemographic('generation_name');
    setSelectedQuestion('MQ0026');
    setFilterCriteria({});
  };

  const selectAllDashboards = () => {
    setSelectedDashboards([
      'D01', 'D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08'
    ]);
  };

  const demographicOptions = [
    { value: 'business_unit_name', label: 'Location 2' },
    { value: 'department_name', label: 'Department' },
    { value: 'exempt_status_name', label: 'Work Status' },
    { value: 'generation_name', label: 'Generation' },
    { value: 'length_of_service_name', label: 'Tenure' },
    { value: 'gender_name', label: 'Gender' },
    { value: 'race_name', label: 'Ethnicity' },
  ];

  const questionOptions = [
    { value: 'MQ0044', label: 'My job responsibilities are clear to me.' },
    { value: 'MQ0047', label: 'I am given realistic and achievable goals and objectives.' },
    { value: 'MQ0017', label: 'I know what my Manager/Supervisor expects of me.' },
    { value: 'MQ0048', label: 'I have the tools and resources to do my job effectively.' },
    { value: 'MQ0051', label: 'I am given sufficient opportunity to improve the skills I use in my current job.' },
    { value: 'MQ0050', label: 'When moved to a new position or given a new assignment, I am given sufficient training/support to do the new job properly.' },
    { value: 'MQ0095', label: 'I understand how my work directly contributes to the success of the organization.' },
    { value: 'MQ0016', label: 'I understand my department‘s role and how it supports the company’s overall objectives.' },
    { value: 'MQ0059', label: 'My Manager/Supervisor clearly defines performance goals and expectations.' },
    { value: 'MQ0104', label: 'I receive useful and constructive feedback from my Manager or Supervisor.' },
    { value: 'MQ0173', label: 'Feedback from my Manager/Supervisor helps me to improve my performance.' },
    { value: 'MQ0054', label: 'I am given feedback by my Manager/Supervisor on a regular and timely basis.' },
    { value: 'MQ0190', label: 'This company has a safe working environment or I feel safe at work.' },
    { value: 'MQ0141', label: 'This company is committed to the health and safety of all employees.' },
    { value: 'MQ0343', label: 'This organization demonstrates its commitment to providing an environment that does not tolerate harassment or discrimination.' },
    { value: 'MQ0088', label: 'My compensation/salary is competitive with similar jobs elsewhere.' },
    { value: 'MQ0090', label: 'I believe our benefits are comparable to those offered by other companies.' },
    { value: 'MQ0091', label: 'Overall, I am compensated fairly for the work I do.' },
    { value: 'MQ0086', label: 'The Senior Leadership Team acts with integrity.' },
    { value: 'MQ0034', label: 'The Senior Leadership Team is trustworthy.' },
    { value: "MQ0041", label: "The Senior Leadership is 'in touch' or connected to its workers." },
    { value: 'MQ0194', label: 'The company provides an environment for the free and open expression of ideas, opinions, and beliefs.' },
    { value: 'MQ0321', label: 'I am comfortable asking questions and sharing thoughts, concerns and ideas with management.' },
    { value: 'MQ0058', label: 'My Manager/Supervisor values my input and is open to hearing my ideas and suggestions.' },
    { value: 'MQ0056', label: 'My Manager/Supervisor is open, consistent, and honest.' },
    { value: 'MQ0057', label: 'My Manager/Supervisor is engaged and responsive.' },
    { value: 'MQ0038', label: 'My Manager/Supervisor is an effective leader.' },
    { value: 'MQ0053', label: 'I get praise and recognition when I do a good job.' },
    { value: 'MQ0171', label: 'Good performance is rewarded with recognition.' },
    { value: 'MQ0103', label: 'Recognition is effectively used to motivate employees.' },
    { value: 'MQ0071', label: 'My ideas and opinions matter.' },
    { value: 'MQ0202', label: 'I have an appropriate level of autonomy in how I accomplish tasks.' },
    { value: 'MQ0027', label: 'I feel empowered to do the right thing to meet company objectives.' },
    { value: 'MQ0023', label: 'Our products and/or services provide great value to our customers.' },
    { value: 'MQ0024', label: 'I feel that my efforts contribute to the success of this company.' },
    { value: 'MQ0026', label: 'My job and the work I do give me a personal sense of accomplishment.' },
    { value: 'MQ0064', label: 'I have opportunities for career development.' },
    { value: 'MQ0043', label: 'This is a good company for career growth and development.' },
    { value: 'MQ0062', label: 'My Manager/Supervisor supports me when I express interest in career development.' },
    { value: 'MQ0340', label: 'There is a strong spirit of teamwork and cooperation amongst the team.' },
    { value: 'MQ0341', label: 'In this organization, it feels like everyone is on the same team and working towards common goals.' },
    { value: 'MQ0094', label: 'Collaboration and teamwork are encouraged.' },
    { value: 'MQ0021', label: 'I am proud to tell others that I am part of this company.' },
    { value: 'MQ0080', label: 'I feel a strong sense of belonging to this company.' },
    { value: 'MQ0302', label: 'I would recommend this company as a good place to work.' },
    { value: 'MQ0072', label: 'I understand the company’s strategy and guiding principles.' },
    { value: 'MQ0029', label: 'Trust, respect, and integrity are evident in the way employees are treated at all levels in the company.' },
    { value: 'MQ0012', label: 'I feel confident that the company has positioned itself well to achieve future performance.' },
    { value: 'MQ0331', label: 'This company has a diverse workforce.' },
    { value: 'MQ0311', label: 'Through its actions, Management shows they value diversity.' },
    { value: 'MQ0312', label: 'Within this organization, people are treated respectfully regardless of their differences.' },
    { value: 'MQ0320', label: 'There is ample opportunity for me to ask questions and share thoughts, concerns, ideas with the Management Team.' },
    { value: 'MQ0046', label: 'There is an atmosphere of trust at this organization.' },
    { value: 'MQ0100', label: 'Within this organization, important information is readily shared and available.' }
  ];

  const dashboardOptions = [
    { value: 'D01', label: 'Survey Demographics' },
    { value: 'D02', label: 'Overall Engagement' },
    { value: 'D03', label: 'Engagement Summary' },
    { value: 'D04', label: 'Responses by Demographic Category' },
    { value: 'D05', label: 'Response Distribution' },
    { value: 'D06', label: 'Detailed Scored by Demographic Category' },
    { value: 'D07', label: 'Range of Responses' },
    { value: 'D08', label: 'Self Reported Engagement' },
  ];

  const renderSelectedDashboards = () => {
    const groupComponents = {
      D01: [
        <D01BarChartViz01 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D01BarChartViz02 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D01BarChartViz03 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D01BarChartViz04 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D01BarChartViz05 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D01BarChartViz06 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D01BarChartViz07 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D01BarChartViz08 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D01BarChartViz09 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D01BarChartViz10 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
      ],
      D02: [
        <D02DonutChart1 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D02DonutChart2 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D02DonutChart3 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D02DonutChart4 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D02PieChart1 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
      ],
      D03: [
        <D03BarChartViz03 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <OrganizationalCommitmentchart selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <RoleClarityChart selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <JobSatisfactionChart selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <WorkContextChart selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D03Scatterplot01 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
      ],
      D04: [
        <D04StackedBarChart selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D04MultipleBarCharts selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
      ],
      D05: [
        <D05BarChartViz01 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D05BarChartViz02 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D05BarChartViz03 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D05BarChartViz04 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
      ],
      D06: [
        <D06Heatmap1 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D06Heatmap2 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D06Heatmap3 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D06Heatmap4 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
      ],
      D07: [
        <D07RangeChart1 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D07RangeChart2 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D07RangeChart3 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D07RangeChart4 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
      ],
      D08: [
        <D08BarChartViz01 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D08BarChartViz02 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D08BarChartViz03 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D08BarChartViz04 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
        <D08BarChartViz05 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} selectedQuestion={selectedQuestion} />,
      ],
    };

    return selectedDashboards.map(group => (
      groupComponents[group]?.map((component, index) => (
        <div key={`${group}-${index}`} className={`chart ${group}-chart`} ref={chartRefs[group][index]}>
          {component}
        </div>
      ))
    ));
  };

  // Generate PowerPoint with multiple images per slide
  const generatePptx = async () => {
    const pptx = new PptxGenJS();
    let slide;
    let imageCounter = 0;

    for (const group in chartRefs) {
      for (const ref of chartRefs[group]) {
        if (ref.current) {
          try {
            const chartImage = await captureChartAsImage(ref.current, { pixelRatio: 2 }); // Custom quality
            if (chartImage) {
              // Create a new slide every 4 images (2x2 grid)
              if (imageCounter % 4 === 0) {
                slide = pptx.addSlide();
              }

              // Calculate position for the image based on the counter (2x2 grid layout)
              const positions = [
                { x: 0.5, y: 0.5 },   // Top-left
                { x: 5.0, y: 0.5 },   // Top-right
                { x: 0.5, y: 3 },   // Bottom-left
                { x: 5.0, y: 3 }    // Bottom-right
              ];
              const position = positions[imageCounter % 4];

              // Add the image to the slide
              slide.addImage({ data: chartImage, x: position.x, y: position.y, w: 4.5, h: 1.5 });

              imageCounter++;
            }
          } catch (error) {
            console.error('Error capturing chart image:', error);
          }
        }
      }
    }

    pptx.writeFile({ fileName: 'ChartsPresentation.pptx' });
  };

  return (
    <ErrorBoundary>
      <div className="generate-report-dashboard-container">
        <Header title="Generate Report" toggleSidebar={toggleSidebar} refreshPage={refreshPage} toggleFilterSidebar={toggleFilterSidebar} />
        <div className="generate-report-controls">
          <button onClick={generatePptx}>Generate PowerPoint</button>
          <button onClick={resetSelections}>Reset Selections</button>
          <button onClick={selectAllDashboards}>Select Default Report</button>
        </div>
        <NavigationSidebar className={isSidebarOpen ? 'generate-report-sidebar-open' : 'generate-report-sidebar-closed'} isOpen={isSidebarOpen} />
        <div className="generate-report-main-content">
          <div className="dropdown">
            <label htmlFor="dashboardSelect">Select Dashboard: </label>
            <Select
              id="dashboardSelect"
              options={dashboardOptions}
              isMulti
              value={dashboardOptions.filter(option => selectedDashboards.includes(option.value))}
              onChange={handleDashboardChange}
            />
          </div>
          <div className="dropdown">
            <h2>Select Demographic Category for Applicable Dashboards</h2>
            <Select
              options={demographicOptions}
              value={demographicOptions.find(option => option.value === selectedDemographic)}
              onChange={handleDemographicChange}
            />
          </div>
          <div className="dropdown">
            <h2>Select Question for Applicable Dashboards</h2>
            <Select
              options={questionOptions}
              value={questionOptions.find(option => option.value === selectedQuestion)}
              onChange={handleQuestionChange}
            />
          </div>
          <div className="charts-container">
            {renderSelectedDashboards()}
          </div>
        </div>
        <FilterSidebar
          filterCriteria={filterCriteria}
          handleFilterChange={handleFilterChange}
          isOpen={isFilterSidebarOpen}
          locations={filterData.locations}
          businessUnits={filterData.businessUnits}
          departments={filterData.departments}
          jobClassifications={filterData.jobClassifications}
          tenures={filterData.tenures}
          generations={filterData.generations}
          exemptStatuses={filterData.exemptStatuses}
          genders={filterData.genders}
          races={filterData.races}
          supervisors={filterData.supervisors}
        />
      </div>
    </ErrorBoundary>
  );
};

export default GenerateReport;
