import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const BarChartViz01 = ({ filterCriteria }) => {
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const filterData = (item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    };

    const filteredData = jsonData.filter(filterData);

    const locationCounts = filteredData.reduce((acc, item) => {
      const location = item.location_name;
      if (!acc[location]) {
        acc[location] = 0;
      }
      acc[location]++;
      return acc;
    }, {});

    // Get the sorted location names
    const locationNames = Object.keys(locationCounts).sort((a, b) =>
      a.localeCompare(b)
    );
    const chartData = locationNames.map((location) => locationCounts[location]);

    setCategories(locationNames);
    setData(chartData);

    const barHeight = 25;
    const minBars = 10;
    const chartHeight = Math.max(
      barHeight * locationNames.length,
      barHeight * minBars
    );

    const optionsViz01 = {
      series: [
        {
          name: "Count",
          data: chartData,
        },
      ],
      colors: ["#004983"],
      chart: {
        type: "bar",
        height: chartHeight,
        width: "95%",
      },
      legend: {
        show: false,
      },
      title: {
        text: 'Location',
        align: 'Center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '20px',
          fontWeight:  'bold',
          fontFamily:  undefined,
          color:  '#263238'
        },
    },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
          distributed: false,
          dataLabels: {
            position: "top",
          },
          // barHeight: `${barHeight}px`,
          barHeight: "22.5px",
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 20, // Move labels off the bars
        style: {
          colors: ["#000"], // Set data labels to black
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 2.5,
          color: "#fff",
          opacity: 1,
        },
      },
      xaxis: {
        categories: locationNames,
      },
    };

    const viz01 = new ApexCharts(
      document.querySelector("#viz-01"),
      optionsViz01
    );
    viz01.render();

    return () => {
      viz01.destroy();
    };
  }, [filterCriteria]);

  return (
    <div style={{ overflowY: "auto", maxHeight: "450px", minWidth: "500px" }}>
      <div id="viz-01"></div>
    </div>
  );
};

export default BarChartViz01;
