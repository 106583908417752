import React, { useState, useEffect, useCallback } from "react";
import NavigationSidebar from "../components/Navigationsidebar/NavigationSidebar";
import Header from "../components/Header";
import D05StackedBarChart01 from "../components/Dashboardviz/D05/D05Viz01";
import BarChartViz02 from "../components/Dashboardviz/D05/D05Viz02";
import BarChartViz03 from "../components/Dashboardviz/D05/D05Viz03";
import BarChartViz04 from "../components/Dashboardviz/D05/D05Viz04";
import AverageScoresTable from "../components/Dashboardviz/D05/D05Viz05";
import WorkContextScoresTable from "../components/Dashboardviz/D05/D05Viz06";
import JobSatisfactionScoresTable from "../components/Dashboardviz/D05/D05Viz07";
import OrganizationalCommitmentScoresTable from "../components/Dashboardviz/D05/D05Viz08";
import FilterSidebar from "../components/FilterSidebar/FilterSidebar";
import ResponseDistributionTable from "../components/Dashboardviz/D05/D05Viz09";
import WorkContextDistributionTable from "../components/Dashboardviz/D05/D05Viz10";
import JobSatisfactionDistributionTable from "../components/Dashboardviz/D05/D05Viz11";
import OrganizationalCommitmentDistributionTable from "../components/Dashboardviz/D05/D05Viz12";
import { useFilter } from "../FilterContext";
import jsonData from "../som_2024-sm-data-numeric.json";
import "./CCIDistributionDashboard.css";

const CCIDistributionDashboard = () => {
  const { filterCriteria, setFilterCriteria } = useFilter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Default state is now closed
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });

  useEffect(() => {
    const uniqueLocations = [
      ...new Set(jsonData.map((item) => item.location_name)),
    ].sort();
    const uniqueBusinessUnits = [
      ...new Set(jsonData.map((item) => item.business_unit_name)),
    ].sort();
    const uniqueDepartments = [
      ...new Set(jsonData.map((item) => item.department_name)),
    ].sort();
    const uniqueJobClassifications = [
      ...new Set(jsonData.map((item) => item.job_classification_name)),
    ].sort();

    const tenureOrder = [
      "Less than 1 year",
      "1 to 2 years",
      "3 to 5 years",
      "6 to 10 years",
      "11 to 19 years",
      "20 years or more",
    ];
    const uniqueTenures = [
      ...new Set(jsonData.map((item) => item.length_of_service_name)),
    ].sort((a, b) => {
      return tenureOrder.indexOf(a) - tenureOrder.indexOf(b);
    });

    const generationOrder = [
      "Traditionalist",
      "Baby Boomer",
      "Generation X",
      "Millennial",
      "Generation Z",
      "No Response",
    ];
    const uniqueGenerations = [
      ...new Set(jsonData.map((item) => item.generation_name)),
    ].sort((a, b) => {
      return generationOrder.indexOf(a) - generationOrder.indexOf(b);
    });

    const uniqueExemptStatuses = [
      ...new Set(jsonData.map((item) => item.exempt_status_name)),
    ].sort();
    const uniqueGenders = [
      ...new Set(jsonData.map((item) => item.gender_name)),
    ].sort();
    const uniqueRaces = [
      ...new Set(jsonData.map((item) => item.race_name)),
    ].sort();
    const uniqueSupervisors = [
      ...new Set(jsonData.map((item) => item.additional_demographic_01_name)),
    ].sort();

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  const toggleSidebar = useCallback(() => {
    console.log("Toggling sidebar visibility");
    setIsSidebarOpen((prevState) => !prevState);
    console.log("Sidebar state:", !isSidebarOpen);
  }, [isSidebarOpen]);

  const toggleFilterSidebar = useCallback(() => {
    console.log("Toggling filter sidebar visibility");
    setIsFilterSidebarOpen((prevState) => !prevState);
    console.log("Filter sidebar state:", !isFilterSidebarOpen);
  }, [isFilterSidebarOpen]);

  const refreshPage = useCallback(() => {
    console.log("Refreshing page");
    window.location.reload();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="ccidistribution-dashboard-container">
      <Header
        title="Engagement Summary"
        toggleSidebar={toggleSidebar}
        refreshPage={refreshPage}
        toggleFilterSidebar={toggleFilterSidebar}
      />
      <NavigationSidebar
        className={
          isSidebarOpen
            ? "ccidemographics-sidebar-open"
            : "ccidemographics-sidebar-closed"
        }
        isOpen={isSidebarOpen}
      />
      <FilterSidebar
        className={
          isFilterSidebarOpen
            ? "ccidemographics-filter-sidebar-open"
            : "ccidemographics-filter-sidebar-closed"
        }
        isOpen={isFilterSidebarOpen}
        filterCriteria={filterCriteria}
        locations={filterData.locations}
        businessUnits={filterData.businessUnits}
        departments={filterData.departments}
        jobClassifications={filterData.jobClassifications}
        tenures={filterData.tenures}
        generations={filterData.generations}
        exemptStatuses={filterData.exemptStatuses}
        genders={filterData.genders}
        races={filterData.races}
        supervisors={filterData.supervisors}
        handleFilterChange={handleFilterChange}
      />
      <div className="ccidistribution-main-content">
        <div className="ccidistribution-charts-container">
          <div className="ccidistribution-row">
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">
                Avg Scores and Responses
              </h2>
              <AverageScoresTable filterCriteria={filterCriteria} />
            </div>
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">Role Clarity</h2>
              <D05StackedBarChart01 filterCriteria={filterCriteria} />
            </div>
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">
                Response Distribution
              </h2>
              <ResponseDistributionTable
                data={jsonData}
                filterCriteria={filterCriteria}
              />
            </div>
          </div>
          <div className="ccidistribution-row">
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">
                Avg Scores and Responses
              </h2>
              <WorkContextScoresTable filterCriteria={filterCriteria} />
            </div>
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">Work Context</h2>
              <BarChartViz02 filterCriteria={filterCriteria} />
            </div>
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">
                Response Distribution
              </h2>
              <WorkContextDistributionTable
                data={jsonData}
                filterCriteria={filterCriteria}
              />
            </div>
          </div>
          <div className="ccidistribution-row">
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">
                Avg Scores and Responses
              </h2>
              <JobSatisfactionScoresTable filterCriteria={filterCriteria} />
            </div>
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">Job Satisfaction</h2>
              <BarChartViz03 filterCriteria={filterCriteria} />
            </div>
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">
                Response Distribution
              </h2>
              <JobSatisfactionDistributionTable
                data={jsonData}
                filterCriteria={filterCriteria}
              />
            </div>
          </div>
          <div className="ccidistribution-row">
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">
                Avg Scores and Responses
              </h2>
              <OrganizationalCommitmentScoresTable
                filterCriteria={filterCriteria}
              />
            </div>
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">
                Organizational Commitment
              </h2>
              <BarChartViz04 filterCriteria={filterCriteria} />
            </div>
            <div className="ccidistribution-chart-card">
              <h2 className="ccidistribution-chart-title">
                Response Distribution
              </h2>
              <OrganizationalCommitmentDistributionTable
                data={jsonData}
                filterCriteria={filterCriteria}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCIDistributionDashboard;
