import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import './NavigationSidebar.css'; // Import your CSS file
import SOMLogo from '../Navigationsidebar/SOM_logo.png'; // Import the image file

const NavigationSidebar = ({ className, isOpen }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    try {
      await Authenticator.signOut();
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <div className={`navigation-sidebar ${isOpen ? 'navigation-sidebar-open' : 'navigation-sidebar-closed'}`}>
      <div className="navigation-sidebar-header">
        <img src={SOMLogo} alt="Logo" className="navigation-sidebar-logo" />
      </div>
      <ul className="navigation-sidebar-menu">
        <li onClick={() => handleNavigation('/ccidemographs')}>Survey Demographics</li>
        <li onClick={() => handleNavigation('/ccitotalengage')}>Overall Engagement</li>
        <li onClick={() => handleNavigation('/CCIEngageSummary')}>Engagement Summary</li>
        <li onClick={() => handleNavigation('/ccidemoresponse')}>Responses by Demographic Category</li>
        <li onClick={() => handleNavigation('/ccidistrbution')}>Response Distribution</li>
        <li onClick={() => handleNavigation('/cciscoredetail')}>Detailed Scores by Demographic Category</li>
        <li onClick={() => handleNavigation('/cciresponserange')}>Range of Responses</li>
        <li onClick={() => handleNavigation('/cciselfengage')}>Self Reported Engagement</li>
        <li onClick={() => handleNavigation('/cciengagesorted')}>Engagement Questions Sorted</li>
        <li onClick={() => handleNavigation('/ccitextresponse')}>Open Text Responses</li>
        <li onClick={() => handleNavigation('/ccitextmap')}>Open Text Heat Map</li>
        <li onClick={() => handleNavigation('/cciimpactarea')}>Engagement Areas by Impact</li>
        <li onClick={() => handleNavigation('/ccisurveyspecific')}>Survey Specific Questions</li>
        <li onClick={() => handleNavigation('/ccitexttheme')}>Open Text Top Themes</li>
        <li onClick={() => handleNavigation('/generatereport')}>Generate Report</li>
      </ul>
    </div>
  );
};

export default NavigationSidebar;
