import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D02PieChart1 = ({ filterCriteria }) => {
  useEffect(() => {
    const filterData = (item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    };

    const filteredData = jsonData.filter(filterData);

    let favorable = 0;
    let neutral = 0;
    let unfavorable = 0;
    let noResponse = 0;

    filteredData.forEach((response) => {
      const { MQ0026, MQ0095, MQ0302, MQ0321 } = response;

      if (
        (MQ0026 === 4 || MQ0026 === 5) &&
        (MQ0095 === 4 || MQ0095 === 5) &&
        (MQ0302 === 4 || MQ0302 === 5) &&
        (MQ0321 === 4 || MQ0321 === 5)
      ) {
        favorable++;
      } else if (
        (MQ0026 === 3 || MQ0095 === 3 || MQ0302 === 3 || MQ0321 === 3) &&
        !(
          MQ0026 === 1 ||
          MQ0026 === 2 ||
          MQ0095 === 1 ||
          MQ0095 === 2 ||
          MQ0302 === 1 ||
          MQ0302 === 2 ||
          MQ0321 === 1 ||
          MQ0321 === 2
        )
      ) {
        neutral++;
      } else if (
        MQ0026 === 1 ||
        MQ0026 === 2 ||
        MQ0095 === 1 ||
        MQ0095 === 2 ||
        MQ0302 === 1 ||
        MQ0302 === 2 ||
        MQ0321 === 1 ||
        MQ0321 === 2
      ) {
        unfavorable++;
      } else {
        noResponse++;
      }
    });

    // Prepare the data for the chart, excluding categories with fewer than 4 results
    const chartData = [
      { name: "Favorable", value: favorable },
      { name: "Neutral", value: neutral },
      { name: "Unfavorable", value: unfavorable },
      { name: "No Response", value: noResponse },
    ];

    const totalRespondents = favorable + neutral + unfavorable + noResponse;

    // Reference the message and chart DOM elements
    const messageElement = document.querySelector("#message");
    const chartElement = document.querySelector("#pie-1");

    // If fewer than 4 respondents, show message and don't render chart
    if (totalRespondents < 4) {
      if (messageElement) {
        messageElement.textContent = "Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.";
      }
      // Hide the chart element if message is shown
      if (chartElement) {
        chartElement.style.display = "none";
      }
      return; // Stop the execution here if condition is met
    }

    // Clear the message if there are enough respondents
    if (messageElement) {
      messageElement.textContent = "";
    }

    // Ensure the chart element is visible
    if (chartElement) {
      chartElement.style.display = "block";
    }

    const filteredChartData = chartData.filter(item => item.value >= 4);

    // Extract series and labels from filtered data
    const series = filteredChartData.map(item => item.value);
    const labels = filteredChartData.map(item => item.name);

    const optionsPie1 = {
      series: series,
      chart: {
        height: "300px",
        type: "pie",
        toolbar: {
          show: true,
        },
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -8,
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "16px",
        },
      },

      labels: labels,
      colors: ["#499894", "#f1ce63", "#f28e2b", "#bab0ac"],
      legend: {
        position: "bottom",
      },
    };

    const pie1 = new ApexCharts(chartElement, optionsPie1);
    pie1.render();

    // Clean up the chart on component unmount
    return () => {
      pie1.destroy();
    };
  }, [filterCriteria]);

  return (
    <div>
      <div id="pie-1"></div>
      <div id="message" style={{ color: "red", marginTop: "20px" }}></div> {/* Message element */}
    </div>
  );
};

export default D02PieChart1;
