import { toPng } from 'html-to-image';

/**
 * Capture a chart as an image.
 * @param {HTMLElement} chartElement - The chart DOM element to capture.
 * @param {object} options - Optional configurations for image quality.
 * @returns {Promise<string>} A promise that resolves with the image data URL.
 */
const captureChartAsImage = async (chartElement, options = {}) => {
  const defaultOptions = {
    width: chartElement.clientWidth,
    height: chartElement.clientHeight,
    pixelRatio: 2, // Increase for higher resolution images
  };

  try {
    const dataUrl = await toPng(chartElement, { ...defaultOptions, ...options });
    return dataUrl;
  } catch (error) {
    console.error('Error capturing chart as image:', error);
    throw error;
  }
};

export default captureChartAsImage;
