import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D02DonutChart3 = ({ filterCriteria }) => {
  const [favorableCount, setFavorableCount] = useState(0);
  const [neutralCount, setNeutralCount] = useState(0);
  const [unfavorableCount, setUnfavorableCount] = useState(0);
  const [noResponseCount, setNoResponseCount] = useState(0);

  useEffect(() => {
    const filterData = (item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    };

    const filteredData = jsonData.filter(filterData);

    let favorable = 0;
    let neutral = 0;
    let unfavorable = 0;
    let noResponse = 0;

    filteredData.forEach((response) => {
      const { MQ0302 } = response;

      if (MQ0302 === 4 || MQ0302 === 5) {
        favorable++;
      } else if (MQ0302 === 3) {
        neutral++;
      } else if (MQ0302 === 1 || MQ0302 === 2) {
        unfavorable++;
      } else {
        noResponse++;
      }
    });

    setFavorableCount(favorable);
    setNeutralCount(neutral);
    setUnfavorableCount(unfavorable);
    setNoResponseCount(noResponse);
  }, [filterCriteria]);

  useEffect(() => {
    const optionsDonut3 = {
      series: [noResponseCount, unfavorableCount, neutralCount, favorableCount],
      labels: ["No Response", "Unfavorable", "Neutral", "Favorable"],
      colors: ["#bab0ac", "#f28e2b", "#f1ce63", "#499894"],
      chart: {
        type: "donut",
        height: "550px",
        toolbar: {
          show: true,
        },
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      title: {
        text: 'Recommendation',
        align: 'Center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238',
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 5,
          },
        },
      },
      legend: {
        show: true, // Enable the legend
        position: 'bottom', // Position the legend at the bottom
        fontSize: '14px', // Set font size for better readability
        labels: {
          colors: ['#000'], // Font color for the legend labels
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "16px",
        },
      },
    };

    const donut3 = new ApexCharts(
      document.querySelector("#donut-3"),
      optionsDonut3
    );
    donut3.render();

    // Clean up the chart on component unmount
    return () => {
      donut3.destroy();
    };
  }, [favorableCount, neutralCount, unfavorableCount, noResponseCount]);

  return (
    <div>
      <div id="donut-3"></div>
    </div>
  );
};

export default D02DonutChart3;
