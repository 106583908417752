import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import NavigationSidebar from '../components/Navigationsidebar/NavigationSidebar';
import Header from '../components/Header';
import Heatmap1 from '../components/Dashboardviz/D06/D06Viz05';
import Heatmap2 from '../components/Dashboardviz/D06/D06Viz06';
import Heatmap3 from '../components/Dashboardviz/D06/D06Viz07';
import Heatmap4 from '../components/Dashboardviz/D06/D06Viz08';
import Score01 from '../components/Dashboardviz/D06/D06Viz01';
import Score02 from '../components/Dashboardviz/D06/D06Viz02';
import Score03 from '../components/Dashboardviz/D06/D06Viz03';
import Score04 from '../components/Dashboardviz/D06/D06Viz04';
import FilterSidebar from '../components/FilterSidebar/FilterSidebar';
import { useFilter } from '../FilterContext';
import jsonData from '../som_2024-sm-data-numeric.json';
import hamburgerMenuImg from '../components/Sidebar3/HamburgerMenu_img.png';
import './CCIScoreDetailDashboard.css';

const CCIScoreDetailDashboard = () => {
  const { filterCriteria, setFilterCriteria } = useFilter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [selectedDemographic, setSelectedDemographic] = useState('business_unit_name');
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });

  useEffect(() => {
    const uniqueLocations = getUniqueFieldValues('location_name');
    const uniqueBusinessUnits = getUniqueFieldValues('business_unit_name');
    const uniqueDepartments = getUniqueFieldValues('department_name');
    const uniqueJobClassifications = getUniqueFieldValues('job_classification_name');
    const uniqueTenures = getSortedFieldValues('length_of_service_name', [
      'Less than 1 year',
      '1 to 2 years',
      '2 to 5 years',
      '5 to 10 years',
      '11 to 15 years',
      '16 to 20 years',
      '20+ years'
    ]);
    const uniqueGenerations = getSortedFieldValues('generation_name', [
      'Traditionalists',
      'Baby Boomers',
      'Generation X',
      'Millennials',
      'Generation Z',
      'Generation Next',
      'NR'
    ]);
    const uniqueExemptStatuses = getUniqueFieldValues('exempt_status_name');
    const uniqueGenders = getUniqueFieldValues('gender_name');
    const uniqueRaces = getUniqueFieldValues('race_name');
    const uniqueSupervisors = getUniqueFieldValues('additional_demographic_01_name');

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  const toggleSidebar = useCallback(debounce(() => {
    setIsSidebarOpen(prevState => {
      const newState = !prevState;
      console.log('Sidebar state before change:', prevState);
      console.log('Sidebar state after change:', newState);
      return newState;
    });
  }, 300), []);

  const toggleFilterSidebar = useCallback(debounce(() => {
    setIsFilterSidebarOpen(prevState => {
      const newState = !prevState;
      console.log('Filter sidebar state before change:', prevState);
      console.log('Filter sidebar state after change:', newState);
      return newState;
    });
  }, 300), []);

  const refreshPage = () => {
    console.log('Refreshing page');
    window.location.reload();
  };

  const handleFilterChange = (selectedOptions, actionMeta) => {
    const { name } = actionMeta;
    const allOptions = filterData[name];
    if (selectedOptions && selectedOptions.some(option => option.value === 'select-all')) {
      if (selectedOptions.length === allOptions.length + 1) {
        setFilterCriteria(prevState => ({
          ...prevState,
          [name]: [],
        }));
      } else {
        setFilterCriteria(prevState => ({
          ...prevState,
          [name]: allOptions.map(option => option.value),
        }));
      }
    } else {
      setFilterCriteria(prevState => ({
        ...prevState,
        [name]: selectedOptions ? selectedOptions.map(option => option.value) : [],
      }));
    }
  };

  const getUniqueFieldValues = (fieldName) => {
    return [...new Set(jsonData.map(item => item[fieldName]))].sort();
  };

  const getSortedFieldValues = (fieldName, order) => {
    return [...new Set(jsonData.map(item => item[fieldName]))].sort((a, b) => order.indexOf(a) - order.indexOf(b));
  };

  const chartComponents = [
    { heatmap: <Heatmap1 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} />, score: <Score01 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} />, title: 'Role Clarity' },
    { heatmap: <Heatmap2 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} />, score: <Score02 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} />, title: 'Work Context' },
    { heatmap: <Heatmap3 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} />, score: <Score03 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} />, title: 'Job Satisfaction' },
    { heatmap: <Heatmap4 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} />, score: <Score04 selectedDemographic={selectedDemographic} filterCriteria={filterCriteria} />, title: 'Organizational Commitment' }
  ];

  return (
    <div className="dashboard-container">
      <Header
        toggleSidebar={toggleSidebar}
        toggleFilterSidebar={toggleFilterSidebar}
        refreshPage={refreshPage}
      />
      <div className="main-content">
        <NavigationSidebar className={isSidebarOpen ? 'navigation-sidebar-open' : 'navigation-sidebar-closed'} isOpen={isSidebarOpen} />
        <div className="cciscore-detail-content-wrapper">
          <FilterSidebar
            filterCriteria={filterCriteria}
            locations={filterData.locations}
            businessUnits={filterData.businessUnits}
            departments={filterData.departments}
            jobClassifications={filterData.jobClassifications}
            tenures={filterData.tenures}
            generations={filterData.generations}
            exemptStatuses={filterData.exemptStatuses}
            genders={filterData.genders}
            races={filterData.races}
            supervisors={filterData.supervisors}
            handleFilterChange={handleFilterChange}
            isOpen={isFilterSidebarOpen}
            className={isFilterSidebarOpen ? 'filter-sidebar-open' : 'filter-sidebar-closed'}
          />
          <div className="cciscore-detail-charts-wrapper">
            <div className="demographic-selector">
              <label>
                Select Demographic:
                <select value={selectedDemographic} onChange={(e) => setSelectedDemographic(e.target.value)}>
                  <option value="business_unit_name">Location 2</option>
                  <option value="department_name">Department</option>
                  <option value="exempt_status_name">Work Status</option>
                  <option value="generation_name">Generation</option>
                  <option value="length_of_service_name">Tenure</option>
                  <option value="gender_name">Gender</option>
                  <option value="race_name">Ethnicity</option>
                </select>
              </label>
            </div>
            <div className="cciscore-detail-charts-container">
              {chartComponents.map((chart, index) => (
                <div key={index} className="cciscore-detail-chart-row">
                  <div className="score">
                    {chart.score}
                  </div>
                  <div className="heatmap">
                    {chart.heatmap}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <button className="toggle-filter-sidebar-btn" onClick={toggleFilterSidebar}>
      </button>
    </div>
  );
};

export default CCIScoreDetailDashboard;
