import { Img, Text, Heading } from "./..";
import React from "react";
import { MenuItem, SubMenu, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";
import ProjectRoutes from "../../ProjectRoutes";

export default function Sidebar2({ ...props }) {
  return (
    <Sidebar
      {...props}
      width="252px !important"
      rootStyles={{ [`.${sidebarClasses.container}`]: { gap: 23 } }}
      className={`${props.className} flex flex-col h-screen pt-4 gap-[23px] top-0 px-4 bg-cyan-600 !sticky overflow-auto rounded-[12px] md:hidden`}
    >
      <div className="flex flex-col gap-4 self-stretch">
        <div className="flex items-center justify-center gap-2 pr-7 sm:pr-5">
          <div className="h-[28px] w-[17%] bg-[url(/public/images/img_group_6.svg)] bg-cover bg-no-repeat p-[3px] md:h-auto">
            <div className="flex flex-col items-start">
              <Img src="images/img_vector.svg" alt="primary vector" className="h-[4px]" />
              <Img
                src="images/img_vector_white_a700.svg"
                alt="secondary vector"
                className="relative mt-[-4px] h-[4px] self-end"
              />
              <Img
                src="images/img_vector_white_a700_4x7.svg"
                alt="tertiary vector"
                className="mt-2.5 h-[4px] self-center"
              />
            </div>
          </div>
          <Heading as="p" className="!text-white-A700">
            AI Architect
          </Heading>
        </div>
        <div className="h-px bg-white-A700" />
      </div>
      <Menu
        menuItemStyles={{
          button: {
            padding: "15px",
            color: "#ffffff",
            fontWeight: 400,
            fontSize: "14px",
            gap: "21px",
            borderRadius: "4px",
            [`&:hover, &.ps-active`]: { backgroundColor: "##8298b9 !important" },
          },
        }}
        rootStyles={{ ["&>ul"]: { gap: "11px" } }}
        renderExpandIcon={() => (
          <Img
            src="images/img_arrow_down.svg"
            alt="dropdown icon"
            className="h-[8px] w-[7px] cursor-pointer self-start"
          />
        )}
        className="mb-3.5 flex w-full flex-col self-stretch pb-7 sm:pb-5"
      >
        <div>
          <SubMenu
            icon={
              <Img
                src="images/img_image.png"
                alt="menu image"
                className="h-[32px] w-[31px] rounded-[15px] object-cover"
              />
            }
            label="Profile Name"
          >
          </SubMenu>
        </div>
        <div className="h-px bg-white-A700" />
        <div className="flex flex-col gap-1">
          <SubMenu
            icon={<Img src="images/img_grid.svg" alt="grid image" className="h-[16px] w-[16px]" />}
            label="Home Page"
          >
            <div className="flex flex-col gap-1">
              <MenuItem icon={<Img src="images/img_a.svg" alt="compose image" className="h-[17px] w-[9px]" />}>
                <a href="compose" target="_blank" className="flex items-center">
                  Compose
                </a>
              </MenuItem>
              <MenuItem
                icon={<Img src="images/img_a_white_a700.svg" alt="discover image" className="h-[17px] w-[9px]" />}
              >
                <a href="discover" target="_blank" className="flex items-center">
                  Discover
                </a>
              </MenuItem>
              <MenuItem
                icon={<Img src="images/img_a_white_a700_17x10.svg" alt="analyze image" className="h-[17px] w-[10px]" />}
              >
                <a href="analyze" target="_blank" className="flex items-center">
                Analyze CAPA
                </a>
              </MenuItem>
              <MenuItem
                icon={<Img src="images/img_a_white_a700_17x10.svg" alt="analyze image" className="h-[17px] w-[10px]" />}
              >
                <a href="analyze2" target="_blank" className="flex items-center">
                Analyze Batch
                </a>
              </MenuItem>
              <MenuItem
                icon={<Img src="images/img_a_white_a700_17x10.svg" alt="analyze image" className="h-[17px] w-[10px]" />}
              >
                <a href="analyze3" target="_blank" className="flex items-center">
                Analyze Supplier
                </a>
              </MenuItem>
              <MenuItem
                icon={<Img src="images/img_a_white_a700_17x10.svg" alt="analyze image" className="h-[17px] w-[10px]" />}
              >
                <a href="analyze2" target="_blank" className="flex items-center">
                Analyze FTR
                </a>
              </MenuItem>
            </div>
          </SubMenu>
        </div>
      </Menu>
    </Sidebar>
  );
}
