import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports'; // Updated to a relative path
import LandingPage from '../pages/LandingPage'; // Updated to a relative path
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';

Amplify.configure(awsExports);

const AuthenticatedApp = ({ signOut }) => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
    navigate('/landing');
  };

  return (
    <main>
      <header className='App-header'>
        <LandingPage />
        <button 
          onClick={handleSignOut} 
          style={{ 
            color: '##8298b9',
            margin: '20px', 
            fontSize: '0.8rem', 
            padding: '5px 10px', 
            marginTop: '20px'
          }}
        >
          Sign In
        </button>
      </header>
    </main>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/landing" element={<LandingPage />} />
        <Route path="*" element={
          <Authenticator>
            {({ signOut }) => <AuthenticatedApp signOut={signOut} />}
          </Authenticator>
        } />
      </Routes>
    </Router>
  );
}

export default withAuthenticator(App);
