import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const engagementGroups = {
  "Belonging": ["MQ0021", "MQ0080", "MQ0302"],
  "Vision and Values": ["MQ0072", "MQ0029", "MQ0012"],
  "Inclusion": ["MQ0311", "MQ0312", "MQ0331"],
  "Openness": ["MQ0320", "MQ0046", "MQ0100"],
};

const Heatmap4 = ({ selectedDemographic, filterCriteria }) => {
  const [chartData, setChartData] = useState({
    series: [],
    categories: [],
  });
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility

  useEffect(() => {
    // Filter the data based on the filter criteria
    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true;
        }
        return filterCriteria[key].includes(item[key]);
      });
    });

    // Show message if there are fewer than 4 respondents
    setShowMessage(filteredData.length < 4);

    // Process the JSON data if enough respondents
    if (filteredData.length >= 4) {
      const demographicValues = [
        ...new Set(filteredData.map((item) => item[selectedDemographic])),
      ].sort();

      const seriesData = Object.keys(engagementGroups).map((group) => {
        return {
          name: group,
          data: demographicValues.map((value) => {
            const responses = filteredData.filter(
              (item) => item[selectedDemographic] === value
            );
            const scores = responses.map((response) => {
              const totalScore = engagementGroups[group].reduce(
                (acc, question) => {
                  return acc + (parseFloat(response[question]) || 0);
                },
                0
              );
              return totalScore / engagementGroups[group].length; // Average score for the group
            });
            const averageScore = scores.length
              ? scores.reduce((acc, score) => acc + score, 0) / scores.length
              : 0;
            return {
              x: value,
              y: averageScore.toFixed(1), // Keep the average score between 1 and 5
            };
          }),
        };
      });

      setChartData({
        series: seriesData,
        categories: demographicValues,
      });
    }
  }, [selectedDemographic, filterCriteria]);

  const options4 = {
    series: chartData.series,
    chart: {
      height: "100%", // Ensure the chart takes full height of the container
      type: "heatmap",
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            {
              from: 1,
              to: 1.99,
              color: "#ffe6e6", // Lightest shade
              name: "Very Low",
            },
            {
              from: 2,
              to: 2.99,
              color: "#ff9999", // Lighter shade
              name: "Low",
            },
            {
              from: 3,
              to: 3.99,
              color: "#ff4d4d", // Medium shade
              name: "Medium",
            },
            {
              from: 4,
              to: 4.99,
              color: "#ff0000", // Darker shade
              name: "High",
            },
            {
              from: 5,
              to: 5,
              color: "#b30000", // Darkest shade
              name: "Very High",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000000"],
      },
      formatter: function (val) {
        return val.toFixed(1);
      },
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: ["#6d6d6d"],
          fontSize: "0.8rem",
        },
      },
    },
    colors: ["#ff0000"],
  };

  return (
    <div>
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}

      {/* Chart will only be displayed if there are enough respondents */}
      {!showMessage && (
        <div
          id="heat-04"
          style={{ height: "100%", width: "100%", position: "relative" }}
        >
          <Chart
            options={options4}
            series={chartData.series}
            type="heatmap"
            height="100%"
            width="100%"
          />
        </div>
      )}
    </div>
  );
};

export default Heatmap4;
