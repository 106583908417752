import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useFilter } from '../../FilterContext';
import './FilterSidebar.css';

const FilterSidebar = ({
  isOpen,
  locations = [],
  businessUnits = [],
  departments = [],
  jobClassifications = [],
  tenures = [],
  generations = [],
  exemptStatuses = [],
  genders = [],
  races = [],
  supervisors = [],
}) => {
  const { filterCriteria, setFilterCriteria } = useFilter();

  // Define order arrays for sorting
  const tenureOrder = [
    'Less than 1 year',
    '1 to 2 years',
    '3 to 5 years',
    '6 to 10 years',
    '11 to 19 years',
    '20 years or more',
    'No Response'
  ];

  const generationOrder = [
    'Traditionalist',
    'Baby Boomer',
    'Generation X',
    'Millennial',
    'Generation Z',
    'No Response'
  ];

  const handleMultiSelectChange = (selectedOptions, actionMeta) => {
    const { name } = actionMeta;
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFilterCriteria(prevState => ({
      ...prevState,
      [name]: selectedValues.includes('all') ? [] : selectedValues,
    }));
  };

  const createOptions = (items = [], sortFunction) => {
    if (!Array.isArray(items)) return [];
    if (sortFunction) items = sortFunction(items);
    const options = items.map(item => ({ value: item, label: item }));
    return [{ value: 'all', label: 'Select All' }, ...options];
  };

  const getSelectedOptions = (selectedValues = [], items = []) => {
    if (!selectedValues.length || !items.length) return [];
    if (selectedValues.includes('all') || selectedValues.length === items.length) {
      return [{ value: 'all', label: 'All selected' }];
    }
    return createOptions(items).filter(option => selectedValues.includes(option.value));
  };

  const sortByOrderArray = (items, orderArray) => {
    return items.sort((a, b) => {
      const indexA = orderArray.indexOf(a);
      const indexB = orderArray.indexOf(b);
      if (indexA === -1 || indexB === -1) return 0;
      return indexA - indexB;
    });
  };

  const clearAllFilters = () => {
    setFilterCriteria({
      location_name: [],
      business_unit_name: [],
      department_name: [],
      job_classification_name: [],
      length_of_service_name: [],
      generation_name: [],
      exempt_status_name: [],
      gender_name: [],
      race_name: [],
      additional_demographic_01_name: [],
      supervisor_name: [],
    });
  };

  const customStyles = {
    multiValue: (base, state) => (state.data.value === 'all' ? { ...base, display: 'none' } : base),
    multiValueLabel: (base, state) => (state.data.value === 'all' ? { ...base, display: 'none' } : base),
    multiValueRemove: (base, state) => (state.data.value === 'all' ? { ...base, display: 'none' } : base),
  };

  return (
    <div className={`filter-sidebar ${isOpen ? 'filter-sidebar-open' : 'filter-sidebar-closed'}`}>
      <div className="filter-sidebar-content">
        <label htmlFor="location_name">Location: </label>
        <Select
          name="location_name"
          options={createOptions(locations)}
          isMulti
          onChange={handleMultiSelectChange}
          value={getSelectedOptions(filterCriteria.location_name, locations)}
          styles={customStyles}
        />
        <label htmlFor="business_unit_name">Location 2 </label>
        <Select
          name="business_unit_name"
          options={createOptions(businessUnits)}
          isMulti
          onChange={handleMultiSelectChange}
          value={getSelectedOptions(filterCriteria.business_unit_name, businessUnits)}
          styles={customStyles}
        />
        <label htmlFor="department_name">Department: </label>
        <Select
          name="department_name"
          options={createOptions(departments)}
          isMulti
          onChange={handleMultiSelectChange}
          value={getSelectedOptions(filterCriteria.department_name, departments)}
          styles={customStyles}
        />
        <label htmlFor="supervisor_name">Supervisor: </label>
        <Select
          name="supervisor_name"
          options={createOptions(supervisors)}
          isMulti
          onChange={handleMultiSelectChange}
          value={getSelectedOptions(filterCriteria.supervisor_name, supervisors)}
          styles={customStyles}
        />
        <label htmlFor="job_classification_name">Job Classification: </label>
        <Select
          name="job_classification_name"
          options={createOptions(jobClassifications)}
          isMulti
          onChange={handleMultiSelectChange}
          value={getSelectedOptions(filterCriteria.job_classification_name, jobClassifications)}
          styles={customStyles}
        />
           <label htmlFor="exempt_status_name">Work Status: </label>
        <Select
          name="exempt_status_name"
          options={createOptions(exemptStatuses)}
          isMulti
          onChange={handleMultiSelectChange}
          value={getSelectedOptions(filterCriteria.exempt_status_name, exemptStatuses)}
          styles={customStyles}
        />
         <label htmlFor="generation_name">Generation: </label>
        <Select
          name="generation_name"
          options={createOptions(generations, items => sortByOrderArray(items, generationOrder))}
          isMulti
          onChange={handleMultiSelectChange}
          value={getSelectedOptions(filterCriteria.generation_name, generations)}
          styles={customStyles}
        />
        <label htmlFor="length_of_service_name">Tenure: </label>
        <Select
          name="length_of_service_name"
          options={createOptions(tenures, items => sortByOrderArray(items, tenureOrder))}
          isMulti
          onChange={handleMultiSelectChange}
          value={getSelectedOptions(filterCriteria.length_of_service_name, tenures)}
          styles={customStyles}
        />
        <label htmlFor="gender_name">Gender: </label>
        <Select
          name="gender_name"
          options={createOptions(genders)}
          isMulti
          onChange={handleMultiSelectChange}
          value={getSelectedOptions(filterCriteria.gender_name, genders)}
          styles={customStyles}
        />
        <label htmlFor="race_name">Ethnicity: </label>
        <Select
          name="race_name"
          options={createOptions(races)}
          isMulti
          onChange={handleMultiSelectChange}
          value={getSelectedOptions(filterCriteria.race_name, races)}
          styles={customStyles}
        />
      </div>
      <button onClick={clearAllFilters} className="clear-all-button">Clear All</button>
    </div>
  );
};

FilterSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  businessUnits: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  jobClassifications: PropTypes.array.isRequired,
  tenures: PropTypes.array.isRequired,
  generations: PropTypes.array.isRequired,
  exemptStatuses: PropTypes.array.isRequired,
  genders: PropTypes.array.isRequired,
  races: PropTypes.array.isRequired,
  supervisors: PropTypes.array.isRequired,
};

export default FilterSidebar;
