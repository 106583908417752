import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D01BarChartViz06 = ({ filterCriteria }) => {
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const filterData = (item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    };

    const filteredData = jsonData.filter(filterData);

    const generationCounts = filteredData.reduce((acc, item) => {
      const generation = item.generation_name;
      if (!acc[generation]) {
        acc[generation] = 0;
      }
      acc[generation]++;
      return acc;
    }, {});

    const generationOrder = [
      'Traditionalist',
      'Baby Boomer',
      'Generation X',
      'Millennial',
      'Generation Z',
      'No Response'
    ];

    // Sort generationNames according to generationOrder
    const generationNames = Object.keys(generationCounts).sort(
      (a, b) => generationOrder.indexOf(a) - generationOrder.indexOf(b)
    );
    const chartData = generationNames.map(generation => generationCounts[generation]);

    setCategories(generationNames);
    setData(chartData);

    const barHeight = 25; // Set bar height in pixels
    const minBars = 10; // Minimum number of bars to display
    const chartHeight = Math.max(
      barHeight * generationNames.length,
      barHeight * minBars
    ); // Total chart height

    const optionsViz06 = {
      series: [
        {
          name: "Count",
          data: chartData,
        },
      ],
      colors: ["#004983"],
      chart: {
        type: "bar",
        height: chartHeight, // Dynamic height based on number of bars
        width: "95%",
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
          distributed: false,
          barHeight: "22.5px",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 28, // Move labels off the bars
        style: {
          colors: ["#000"], // Set data labels to black
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 2.5,
          color: "#fff",
          opacity: 1,
        },
      },
      xaxis: {
        categories: generationNames,
      },
    };

    const viz06 = new ApexCharts(
      document.querySelector("#viz-06"),
      optionsViz06
    );
    viz06.render();

    // Clean up the chart on component unmount
    return () => {
      viz06.destroy();
    };
  }, [filterCriteria]); // Re-run effect when filterCriteria changes

  return (
    <div style={{ overflowY: "auto", maxHeight: "450px" }}>
      <div id="viz-06"></div>
    </div>
  );
};

export default D01BarChartViz06;
