import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D01BarChartViz07 = ({ filterCriteria }) => {
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const filterData = (item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    };

    const filteredData = jsonData.filter(filterData);

    const exemptStatusCounts = filteredData.reduce((acc, item) => {
      const exemptStatus = item.exempt_status_name;
      if (!acc[exemptStatus]) {
        acc[exemptStatus] = 0;
      }
      acc[exemptStatus]++;
      return acc;
    }, {});

    // Sort exemptStatusNames alphabetically
    const exemptStatusNames = Object.keys(exemptStatusCounts).sort((a, b) => a.localeCompare(b));
    const chartData = exemptStatusNames.map(exemptStatus => exemptStatusCounts[exemptStatus]);

    setCategories(exemptStatusNames);
    setData(chartData);

    const barHeight = 25;
    const minBars = 10;
    const chartHeight = Math.max(
      barHeight * exemptStatusNames.length,
      barHeight * minBars
    );

    const optionsViz07 = {
      series: [
        {
          name: "Count",
          data: chartData,
        },
      ],
      colors: ["#004983"],
      chart: {
        type: "bar",
        height: chartHeight,
        width: "95%",
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
          distributed: false,
          barHeight: "22.5px",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 28, // Move labels off the bars
        style: {
          colors: ["#000"], // Set data labels to black
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 2.5,
          color: "#fff",
          opacity: 1,
        },
      },
      xaxis: {
        categories: exemptStatusNames,
      },
    };

    const viz07 = new ApexCharts(
      document.querySelector("#viz-07"),
      optionsViz07
    );
    viz07.render();

    return () => {
      viz07.destroy();
    };
  }, [filterCriteria]);

  return (
    <div style={{ overflowY: "auto", maxHeight: "450px" }}>
      <div id="viz-07"></div>
    </div>
  );
};

export default D01BarChartViz07;
