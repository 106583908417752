import React, { useState, useEffect } from "react"; 
import Header from "../components/Header";
import NavigationSidebar from "../components/Navigationsidebar/NavigationSidebar";
import FilterSidebar from "../components/FilterSidebar/FilterSidebar";
import { useFilter } from "../FilterContext";
import jsonData from "../som_2024-sm-data-numeric.json"; 
import "./CCIEngageSorted.css"; 

const CCISortedEngagementQuestions = () => {
  const { filterCriteria, setFilterCriteria } = useFilter();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });
  const [showMessage, setShowMessage] = useState(false);
  const respondentIdField = "respondentid"; // Use the correct respondent identifier field

  useEffect(() => {
    const uniqueLocations = [...new Set(jsonData.map((item) => item.location_name))].sort();
    const uniqueBusinessUnits = [...new Set(jsonData.map((item) => item.business_unit_name))].sort();
    const uniqueDepartments = [...new Set(jsonData.map((item) => item.department_name))].sort();
    const uniqueJobClassifications = [...new Set(jsonData.map((item) => item.job_classification_name))].sort();

    const tenureOrder = ["Less than 1 year", "1 to 2 years", "3 to 5 years", "6 to 10 years", "11 to 19 years", "20 years or more", "No Response"];
    const uniqueTenures = [...new Set(jsonData.map((item) => item.length_of_service_name))].sort((a, b) => tenureOrder.indexOf(a) - tenureOrder.indexOf(b));

    const generationOrder = ["Traditionalist", "Baby Boomer", "Generation X", "Millennial", "Generation Z", "No Response"];
    const uniqueGenerations = [...new Set(jsonData.map((item) => item.generation_name))].sort((a, b) => generationOrder.indexOf(a) - generationOrder.indexOf(b));

    const uniqueExemptStatuses = [...new Set(jsonData.map((item) => item.exempt_status_name))].sort();
    const uniqueGenders = [...new Set(jsonData.map((item) => item.gender_name))].sort();
    const uniqueRaces = [...new Set(jsonData.map((item) => item.race_name))].sort();
    const uniqueSupervisors = [...new Set(jsonData.map((item) => item.additional_demographic_01_name))].sort();

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  useEffect(() => {
    const processData = () => {
      const questions = {
        "Role Clarity: Clarity": ["MQ0044", "MQ0047", "MQ0017"],
        "Role Clarity: Resources": ["MQ0048", "MQ0051", "MQ0050"],
        "Role Clarity: Work Outcomes": ["MQ0095", "MQ0016", "MQ0059"],
        "Role Clarity: Feedback": ["MQ0104", "MQ0173", "MQ0054"],
        "Work Context: Safety": ["MQ0190", "MQ0141", "MQ0343"],
        "Work Context: Compensation": ["MQ0088", "MQ0090", "MQ0091"],
        "Work Context: Leadership": ["MQ0086", "MQ0034", "MQ0041"],
        "Work Context: Communication": ["MQ0194", "MQ0321", "MQ0058"],
        "Work Context: Supervision": ["MQ0056", "MQ0057", "MQ0038"],
        "Job Satisfaction: Recognition": ["MQ0053", "MQ0171", "MQ0103"],
        "Job Satisfaction: Autonomy": ["MQ0071", "MQ0202", "MQ0027"],
        "Job Satisfaction: Meaningfulness": ["MQ0023", "MQ0024", "MQ0026"],
        "Job Satisfaction: Growth": ["MQ0064", "MQ0062", "MQ0043"],
        "Job Satisfaction: Teamwork": ["MQ0340", "MQ0341", "MQ0094"],
        "Organizational Commitment: Belonging": ["MQ0021", "MQ0080", "MQ0302"],
        "Organizational Commitment: Vision and Values": [
          "MQ0072",
          "MQ0029",
          "MQ0012",
        ],
        "Organizational Commitment: Inclusion": ["MQ0311", "MQ0312", "MQ0331"],
        "Organizational Commitment: Openness": ["MQ0320", "MQ0046", "MQ0100"],
      };

      const result = [];
      const respondentSet = new Set(); // Track unique respondents

      for (const [engagementArea, questionList] of Object.entries(questions)) {
        for (const question of questionList) {
          const filteredData = jsonData
            .filter((item) => {
              // Ensure proper filtering is applied only when there are filter criteria
              return Object.keys(filterCriteria).every((key) => {
                const filterValue = filterCriteria[key];
                const itemValue = item[key];
                return filterValue.length === 0 || filterValue.includes(itemValue);
              });
            })
            .map((item) => {
              respondentSet.add(item[respondentIdField]); // Track respondents
              const value = parseFloat(item[question]);
              return isNaN(value) ? 0 : value;
            });

          const average = filteredData.length > 0
            ? filteredData.reduce((acc, val) => acc + val, 0) / filteredData.length
            : 0;

          result.push([question, engagementArea, average.toFixed(1)]);
        }
      }

      setData(result);

      // Log the number of unique respondents for debugging
      console.log("Unique respondents after filtering:", respondentSet.size);
      
      // Only show the message if there are fewer than 5 respondents
      const uniqueRespondentsCount = respondentSet.size;
      setShowMessage(uniqueRespondentsCount < 5); 
    };

    processData();
  }, [filterCriteria]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleFilterSidebar = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };

  const downloadCSV = () => {
    const csvContent = [
      ["Question_Text", "Engagement_Area", "Average_Response"],
      ...data.map((item) => [item[0], item[1], item[2]]),
    ]
      .map((e) => e.map((field) => `"${field.replace(/"/g, '""')}"`).join(",")) // Escaping quotes
      .join("\n");

    const blob = new Blob(["\uFEFF" + csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "engagement_questions_sorted.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredData = data
    .filter((item) =>
      item.some(
        (s) =>
          typeof s === "string" &&
          s.toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    .sort((a, b) => {
      return sortOrder === "asc" ? a[2] - b[2] : b[2] - a[2];
    });

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  return (
    <div className="cciengagesorted-dashboard-container">
      <Header
        title="Engagement Questions Table"
        toggleSidebar={toggleSidebar}
        refreshPage={() => window.location.reload()}
        toggleFilterSidebar={toggleFilterSidebar}
      />
      <NavigationSidebar isOpen={isSidebarOpen} />
      <div className="cciengagesorted-main-content">
        <FilterSidebar
          filterCriteria={filterCriteria}
          locations={filterData.locations}
          businessUnits={filterData.businessUnits}
          departments={filterData.departments}
          jobClassifications={filterData.jobClassifications}
          tenures={filterData.tenures}
          generations={filterData.generations}
          exemptStatuses={filterData.exemptStatuses}
          genders={filterData.genders}
          races={filterData.races}
          supervisors={filterData.supervisors}
          handleFilterChange={(event) => {
            const { name, value } = event.target;
            setFilterCriteria((prevState) => ({
              ...prevState,
              [name]: value,
            }));
          }}
          isOpen={isFilterSidebarOpen}
        />
        <div className="cciengagesorted-app-container">
          <div className="flex justify-between mb-4">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded w-full search-bar"
            />
            <button onClick={downloadCSV} className="download-button">
              Download CSV
            </button>
          </div>

          {/* Conditionally render the table based on the number of respondents */}
          {showMessage ? (
            <div className="warning-message"style={{ color: "red", marginTop: "20px",fontSize: "10px" }}>
              Current filter selections are returning fewer than the minimum
              number of respondents. Elements of this view may be suppressed.
              Please expand the selection to include more respondents to allow
              these elements to be displayed.
            </div>
          ) : (
            <div className="cciengagesorted-scrollable-table">
              <table className="cciengagesorted-centered-table w-full border-collapse table-auto text-lg relative">
                <thead className="sticky top-0 bg-white z-10">
                  <tr>
                    <th className="border p-4">Question_Text</th>
                    <th className="border p-4">Engagement_Area</th>
                    <th
                      className="border p-4 cursor-pointer"
                      onClick={toggleSortOrder}
                    >
                      Average_Response {sortOrder === "asc" ? "▲" : "▼"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={index}>
                      <td className="border p-4">{item[0]}</td>
                      <td className="border p-4">{item[1]}</td>
                      <td className="border p-4">{item[2]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CCISortedEngagementQuestions;
