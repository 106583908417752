import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const engagementQuestions = ["MQ0044", "MQ0047", "MQ0017"];

const Score01 = ({ filterCriteria }) => {
  const [averageScore, setAverageScore] = useState(0);
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility

  useEffect(() => {
    // Filter the data based on the filter criteria
    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        const criteria = filterCriteria[key] || []; // Default to an empty array if undefined
        if (criteria.length === 0) {
          return true;
        }
        return criteria.includes(item[key]);
      });
    });

    // Show message if there are fewer than 4 respondents
    setShowMessage(filteredData.length < 4);

    // Calculate the average score for Role Clarity
    const totalScore = filteredData.reduce((acc, response) => {
      return (
        acc +
        engagementQuestions.reduce((sum, question) => {
          return sum + (parseFloat(response[question]) || 0);
        }, 0) /
          engagementQuestions.length
      );
    }, 0);

    const overallAverage = filteredData.length
      ? (totalScore / filteredData.length).toFixed(1)
      : 0;
    setAverageScore(overallAverage);
  }, [filterCriteria]);

  const scoreOptions1 = {
    series: [
      {
        name: "Role Clarity",
        data: [averageScore],
      },
    ],
    chart: {
      type: "bar",
      height: "22%",
      width: "50px",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "24px",
      },
      formatter: function (val) {
        return val ? val.toFixed(1) : "0";
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toFixed(1);
        },
      },
    },
    xaxis: {
      categories: ["Role Clarity"],
      show: false,
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      labels: {
        show: false,
      },
      title: {
        text: "Role Clarity",
        rotate: -90,
        style: {
          color: "#6d6d6d",
          fontSize: "1.0rem",
        },
      },
    },
    fill: {
      opacity: 1,
      colors: ["#004983"],
    },
  };

  return (
    <div>
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}

      {/* Chart will only be displayed if there are enough respondents */}
      {!showMessage && (
        <div id="score-01">
          <Chart
            options={scoreOptions1}
            series={scoreOptions1.series}
            type="bar"
            height="100%"
          />
        </div>
      )}
    </div>
  );
};

export default Score01;
