import React, { useState, useEffect, useCallback } from 'react';
import NavigationSidebar from '../components/Navigationsidebar/NavigationSidebar';
import Header from '../components/Header';
import DonutChart1 from '../components/Dashboardviz/D02/DB02Viz2';
import DonutChart2 from '../components/Dashboardviz/D02/DB02Viz3';
import DonutChart3 from '../components/Dashboardviz/D02/DB02Viz4';
import DonutChart4 from '../components/Dashboardviz/D02/DB02Viz5';
import PieChart1 from '../components/Dashboardviz/D02/DB02Viz1';
import FilterSidebar from '../components/FilterSidebar/FilterSidebar';
import { useFilter } from '../FilterContext';
import jsonData from '../som_2024-sm-data-numeric.json';
import TextBoxComponent from '../components/Dashboardviz/D02/DB02Viz6';
import './CCITotalEngage.css';

const CCITotalengage = ({ title }) => {
  const { filterCriteria, setFilterCriteria } = useFilter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });

  useEffect(() => {
    const uniqueLocations = [...new Set(jsonData.map(item => item.location_name))].sort();
    const uniqueBusinessUnits = [...new Set(jsonData.map(item => item.business_unit_name))].sort();
    const uniqueDepartments = [...new Set(jsonData.map(item => item.department_name))].sort();
    const uniqueJobClassifications = [...new Set(jsonData.map(item => item.job_classification_name))].sort();

    const tenureOrder = [
      'Less than 1 year',
      '1 to 2 years',
      '2 to 5 years',
      '5 to 10 years',
      '11 to 15 years',
      '16 to 20 years',
      '20+ years'
    ];
    const uniqueTenures = [...new Set(jsonData.map(item => item.length_of_service_name))].sort((a, b) => tenureOrder.indexOf(a) - tenureOrder.indexOf(b));

    const generationOrder = [
      'Traditionalists',
      'Baby Boomers',
      'Generation X',
      'Millennials',
      'Generation Z',
      'Generation Next',
      'No Response'
    ];
    const uniqueGenerations = [...new Set(jsonData.map(item => item.generation_name))].sort((a, b) => generationOrder.indexOf(a) - generationOrder.indexOf(b));

    const uniqueExemptStatuses = [...new Set(jsonData.map(item => item.exempt_status_name))].sort();
    const uniqueGenders = [...new Set(jsonData.map(item => item.gender_name))].sort();
    const uniqueRaces = [...new Set(jsonData.map(item => item.race_name))].sort();
    const uniqueSupervisors = [...new Set(jsonData.map(item => item.additional_demographic_01_name))].sort();

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prevState => !prevState);
  }, []);

  const toggleFilterSidebar = useCallback(() => {
    setIsFilterSidebarOpen(prevState => !prevState);
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterCriteria(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const donutChartNames = ['Sense of Accomplishment', 'Contribution', 'Recommendation', 'Input and Feedback'];
  const donutChartComponents = [<DonutChart1 filterCriteria={filterCriteria} />, <DonutChart2 filterCriteria={filterCriteria} />, <DonutChart3 filterCriteria={filterCriteria} />, <DonutChart4 filterCriteria={filterCriteria} />];

  return (
    <div className="CCITotalengage-container">
      <Header title={title} toggleSidebar={toggleSidebar} toggleFilterSidebar={toggleFilterSidebar} refreshPage={refreshPage} />
      <NavigationSidebar className={isSidebarOpen ? 'CCITotalengage-sidebar-open' : 'CCITotalengage-sidebar-closed'} isOpen={isSidebarOpen} />
      <div className="CCITotalengage-main-content">
        <div className="CCITotalengage-content">
          <div className="CCITotalengage-content-row">
            <div className="CCITotalengage-card CCITotalengage-pie-chart-container">
              <div className="CCITotalengage-card-header">Level of Engagement</div>
              <div className="CCITotalengage-card-content">
                <div className="CCITotalengage-pie-chart">
                  <PieChart1 filterCriteria={filterCriteria} />
                </div>
              </div>
            </div>
            <div className="CCITotalengage-card CCITotalengage-text-box-container">
              <TextBoxComponent />
            </div>
          </div>
          <div className="CCITotalengage-chart-container">
            {donutChartNames.map((chartName, index) => (
              <div key={index} className="CCITotalengage-card CCITotalengage-donut-chart">
                <div className="CCITotalengage-card-header">{chartName}</div>
                <div className="CCITotalengage-card-content">
                  {donutChartComponents[index]}
                </div>
              </div>
            ))}
          </div>
        </div>
        <FilterSidebar 
          filterCriteria={filterCriteria}
          locations={filterData.locations}
          businessUnits={filterData.businessUnits}
          departments={filterData.departments}
          jobClassifications={filterData.jobClassifications}
          tenures={filterData.tenures}
          generations={filterData.generations}
          exemptStatuses={filterData.exemptStatuses}
          genders={filterData.genders}
          races={filterData.races}
          supervisors={filterData.supervisors}
          handleFilterChange={handleFilterChange}
          isOpen={isFilterSidebarOpen}
        />
      </div>
    </div>
  );
};

export default CCITotalengage;
