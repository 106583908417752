import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const RangeChart1 = ({ selectedDemographic, filterCriteria }) => {
  const [series, setSeries] = useState([]);
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility

  const categories = {
    "Clarity": ["MQ0044", "MQ0047", "MQ0017"],
    "Resources": ["MQ0048", "MQ0051", "MQ0050"],
    "Work Outcomes": ["MQ0095", "MQ0016", "MQ0059"],
    "Feedback": ["MQ0104", "MQ0173", "MQ0054"],
  };

  useEffect(() => {
    const processData = () => {
      const filteredData = jsonData.filter((item) => {
        return Object.keys(filterCriteria).every((key) => {
          if (filterCriteria[key].length === 0) {
            return true;
          }
          return filterCriteria[key].includes(item[key]);
        });
      });

      // Show message if there are fewer than 4 respondents
      setShowMessage(filteredData.length < 4);

      // Process the data if there are enough respondents
      if (filteredData.length >= 4) {
        const demographicValues = [
          ...new Set(filteredData.map((item) => item[selectedDemographic])),
        ];

        const data = Object.keys(categories).map((category) => {
          const scores = demographicValues.map((value) => {
            const filteredDemographicData = filteredData.filter(
              (item) => item[selectedDemographic] === value
            );
            const avgScore =
              filteredDemographicData.reduce((acc, item) => {
                const total = categories[category].reduce(
                  (sum, q) => sum + (item[q] || 0),
                  0
                );
                return acc + total / categories[category].length;
              }, 0) / filteredDemographicData.length || 0;
            return {
              name: value,
              value: avgScore,
            };
          });

          return {
            x: category,
            y: [
              Math.min(...scores.map((s) => s.value)),
              Math.max(...scores.map((s) => s.value)),
            ],
            goals: scores.map((score, index) => ({
              name: score.name,
              value: score.value,
              strokeWidth: 5,
              strokeHeight: 0.5,
              strokeLineCap: "round",
              strokeColor: [
                "#E69F00",
                "#56B4E9",
                "#009E73",
                "#F0E442",
                "#0072B2",
                "#D55E00",
                "#CC79A7",
                "#E3E639",
                "#E634A2",
                "#35C7D1",
                "#4735E0",
                "#399BE6",
                "#2D8F68",
              ][index % 13],
            })),
          };
        });

        setSeries([{ name: "Avg Score", data }]);
      }
    };

    processData();
  }, [selectedDemographic, filterCriteria]);

  const options = {
    chart: {
      type: "rangeBar",
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 8,
      },
    },
    colors: ["#d3d3d3"],
    xaxis: {
      min: 0,
      max: 5,
      tickAmount: 5,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: false,
      intersect: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        // Sort goals by value, highest to lowest
        const sortedGoals = data.goals
          .slice()
          .sort((a, b) => b.value - a.value);

        // Create tooltip content
        const tooltipContent = sortedGoals
          .map(
            (goal) => ` 
          <div>
            <strong>${goal.name}</strong>: ${goal.value.toFixed(1)}
          </div>
        `
          )
          .join("");

        return `<div><strong>${data.x}</strong></div>${tooltipContent}`;
      },
    },
  };

  return (
    <div>
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}

      {/* Chart will only be displayed if there are enough respondents */}
      {!showMessage && (
        <div className="cciresponse-range-chart-container">
          <Chart
            options={options}
            series={series}
            type="rangeBar"
            height="100%"
            width="98%"
          />
        </div>
      )}
    </div>
  );
};

export default RangeChart1;
