// TextBoxComponent.jsx
import React from "react";

const TextBoxComponent = () => {
  return (
    <div className="text-box-container">
      <div className="text-box">
        <h3>Overall Level of Engagement</h3>
        <ul>
          <li>
            Favorable: The individual scored each of these four items as a 4 or
            5 on the five-point Likert scale.{" "}
          </li>

          <li>
            Neutral: The individual scored one or more items a 3, and no items
            as a 1 or 2 on the five-point Likert scale.
          </li>

          <li>
            Unfavorable: The individual scored one or more items as a 1 or 2 on
            the five-point Likert scale.
          </li>
        </ul>
      </div>
      <div className="text-box">
        <h3>Questions</h3>
        <ul>
          <li>
            Sense of Accomplishment: My job and the work I do give me a personal
            sense of accomplishment.
          </li>
          <li>
            Contribution: I understand how my work directly contributes to the
            success of the organization.
          </li>
          <li>
            Recommendation: I would recommend this company as a good place to
            work.
          </li>
          <li>
            Input and Feedback: At Sisters of Mercy, I am comfortable asking
            questions and sharing thoughts, concerns, and ideas with Management.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TextBoxComponent;
