import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D05StackedBarChart02 = ({ filterCriteria }) => {
  const [seriesData, setSeriesData] = useState({
    Unfavorable: [],
    Neutral: [],
    Favorable: [],
  });
  const [showMessage, setShowMessage] = useState(false); // State for controlling the message visibility

  useEffect(() => {
    const questions = {
      Communication: ["MQ0194", "MQ0321", "MQ0058"],
      Compensation: ["MQ0088", "MQ0090", "MQ0091"],
      Leadership: ["MQ0086", "MQ0034", "MQ0041"],
      Safety: ["MQ0190", "MQ0141", "MQ0343"],
      Supervision: ["MQ0056", "MQ0057", "MQ0038"],
    };

    const processResponses = (data, questions) => {
      const responseCounts = {
        Unfavorable: [],
        Neutral: [],
        Favorable: [],
      };

      if (!data || !questions) {
        console.error("Data or questions object is undefined or null.");
        return responseCounts;
      }

      Object.keys(questions).forEach((category) => {
        let unfavorable = 0;
        let neutral = 0;
        let favorable = 0;
        let totalResponses = 0;

        data.forEach((item) => {
          questions[category].forEach((question) => {
            const response = item[question];
            if (response === 1 || response === 2) {
              unfavorable++;
            } else if (response === 3) {
              neutral++;
            } else if (response === 4 || response === 5) {
              favorable++;
            }
            if (response !== null && response !== undefined) {
              totalResponses++;
            }
          });
        });

        responseCounts.Unfavorable.push(
          totalResponses
            ? ((unfavorable / totalResponses) * 100).toFixed(1)
            : "0.0"
        );
        responseCounts.Neutral.push(
          totalResponses ? ((neutral / totalResponses) * 100).toFixed(1) : "0.0"
        );
        responseCounts.Favorable.push(
          totalResponses
            ? ((favorable / totalResponses) * 100).toFixed(1)
            : "0.0"
        );
      });

      return responseCounts;
    };

    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria || {}).every((key) => {
        const isCriteriaEmpty =
          !filterCriteria[key] || filterCriteria[key].length === 0;
        const doesItemMatchCriteria = filterCriteria[key]?.includes(item[key]);
        return isCriteriaEmpty || doesItemMatchCriteria;
      });
    });

    // Determine the total number of respondents based on filtered data
    const totalRespondents = filteredData.length;

    // Show the message if there are fewer than 4 respondents
    setShowMessage(totalRespondents < 4);

    const newSeriesData = processResponses(filteredData, questions);
    setSeriesData(newSeriesData);
  }, [filterCriteria]);

  const options_st02 = {
    series: [
      {
        name: "Unfavorable",
        data: seriesData.Unfavorable,
      },
      {
        name: "Neutral",
        data: seriesData.Neutral,
      },
      {
        name: "Favorable",
        data: seriesData.Favorable,
      },
    ],
    colors: ["#f28e2b", "#f1ce63", "#499894"],
    chart: {
      type: "bar",
      height: "100%",
      stacked: true,
      stackType: "100%",
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: [
        "Communication",
        "Compensation",
        "Leadership",
        "Safety",
        "Supervision",
      ],
    },
    yaxis: {
      labels: {
        align: "center",
        style: {
          fontSize: "0.9rem",
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
      },
      formatter: function (val) {
        return val.toFixed(1) + "%";
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toFixed(1) + "%";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "center",
      offsetX: 60,
    },
  };

  return (
    <div>
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}
      {/* Chart will only be displayed if there are enough respondents */}
      {!showMessage && (
        <div id="st-02">
          <Chart
            options={options_st02}
            series={options_st02.series}
            type="bar"
            height="180%"
          />
        </div>
      )}
    </div>
  );
};

export default D05StackedBarChart02;
