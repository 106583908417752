import React from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FilterProvider } from './FilterContext';
import CCIDemographs from './pages/CCIDemographs.jsx';
import Login from './pages/Login';
import LandingPagePage from './pages/LandingPage.jsx';
import Signinillustration from './pages/Signinillustration.jsx';
import Discover from './pages/Discover.jsx';
import Compose from './pages/Compose.jsx';
import Analyze from './pages/Analyze.jsx';
import Analyze2 from './pages/Analyze2.jsx';
import Analyze3 from './pages/Analyze3.jsx';
import Analyze4 from './pages/Analyze4.jsx';
import CCITotalEngage from './pages/CCITotalEngage.jsx';
import CCIEngageSummary from './pages/CCIEngageSummary.jsx';
import CCIDemoResponse from './pages/CCIDemoResponse.jsx';
import CCIDistribution from './pages/CCIDistribution.jsx';
import CCIScoreDetail from './pages/CCIScoreDetail.jsx';
import CCIResponseRange from './pages/CCIResponseRange.jsx';
import CCISelfEngage from './pages/CCISelfEngage.jsx';
import CCIEngageSorted from './pages/CCIEngageSorted.jsx';
import CCITextResponse from './pages/CCITextResponse.jsx';
import CCITextMap from './pages/CCITextMap.jsx';
import CCIImpactArea from './pages/CCIImpactArea.jsx';
import CCISurveySpecific from './pages/CCISurveySpecific.jsx';
import CCIYOYArea from './pages/CCIYOYArea.jsx';
import CCITextTheme from './pages/CCITextTheme.jsx';
import GenerateReport from './pages/GenerateReport';

Amplify.configure(awsExports);

function App() {
  return (
    <Authenticator.Provider>
      <FilterProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/ccidemographs" element={<CCIDemographs />} />
            <Route path="/signinillustration" element={<Signinillustration />} />
            <Route path="/landingpage" element={<LandingPagePage />} />
            <Route path="/discover" element={<Discover />} />
            <Route path="/compose" element={<Compose />} />
            <Route path="/analyze" element={<Analyze />} />
            <Route path="/analyze2" element={<Analyze2 />} />
            <Route path="/analyze3" element={<Analyze3 />} />
            <Route path="/analyze4" element={<Analyze4 />} />
            <Route path="/ccitotalengage" element={<CCITotalEngage />} />
            <Route path="/CCIEngageSummary" element={<CCIEngageSummary />} />
            <Route path="/ccidemoresponse" element={<CCIDemoResponse />} />
            <Route path="/ccidistrbution" element={<CCIDistribution />} />
            <Route path="/cciscoredetail" element={<CCIScoreDetail />} />
            <Route path="/cciresponserange" element={<CCIResponseRange />} />
            <Route path="/cciselfengage" element={<CCISelfEngage />} />
            <Route path="/cciengagesorted" element={<CCIEngageSorted />} />
            <Route path="/ccitextresponse" element={<CCITextResponse />} />
            <Route path="/ccitextmap" element={<CCITextMap />} />
            <Route path="/cciimpactarea" element={<CCIImpactArea />} />
            <Route path="/ccisurveyspecific" element={<CCISurveySpecific />} />
            <Route path="/cciyoyarea" element={<CCIYOYArea />} />
            <Route path="/ccitexttheme" element={<CCITextTheme />} />
            <Route path="/generatereport" element={<GenerateReport />} />
          </Routes>
        </Router>
      </FilterProvider>
    </Authenticator.Provider>
  );
}

export default App;
