import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D01BarChartViz05 = ({ filterCriteria }) => {
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const tenureOrder = [
      "Less than 1 year",
      "1 to 2 years",
      "3 to 5 years",
      "6 to 10 years",
      "11 to 19 years",
      "20 years or more",
      "No Response",
    ];

    const filterData = (item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    };

    const filteredData = jsonData.filter(filterData);

    const tenureCounts = filteredData.reduce((acc, item) => {
      const tenure = item.length_of_service_name;
      if (!acc[tenure]) {
        acc[tenure] = 0;
      }
      acc[tenure]++;
      return acc;
    }, {});

    // Sort the tenures according to tenureOrder
    const sortedTenureData = tenureOrder.map((tenure) => ({
      tenure,
      count: tenureCounts[tenure] || 0,
    }));

    const tenureNames = sortedTenureData.map((item) => item.tenure);
    const chartData = sortedTenureData.map((item) => item.count);

    setCategories(tenureNames);
    setData(chartData);

    const barHeight = 25;
    const minBars = 10;
    const chartHeight = Math.max(
      barHeight * tenureNames.length,
      barHeight * minBars
    );

    const optionsViz05 = {
      series: [
        {
          name: "Count",
          data: chartData,
        },
      ],
      colors: ["#004983"],
      chart: {
        type: "bar",
        height: chartHeight,
        width: "95%",
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
          dataLabels: {
            position: "top",
          },
          // barHeight: `${barHeight}px`,
          barHeight: "22.5px",
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 25, // Move labels off the bars
        style: {
          colors: ["#000"], // Set data labels to black
        },
        // dropShadow: {
        //   enabled: true,
        //   top: 0,
        //   left: 0,
        //   blur: 2.5,
        //   color: "#fff",
        //   opacity: 1,
        // },
      },
      xaxis: {
        categories: tenureNames,
      },
    };

    const viz05 = new ApexCharts(
      document.querySelector("#viz-05"),
      optionsViz05
    );
    viz05.render();

    return () => {
      viz05.destroy();
    };
  }, [filterCriteria]);

  return (
    <div style={{ overflowY: "auto", maxHeight: "450px" }}>
      <div id="viz-05"></div>
    </div>
  );
};

export default D01BarChartViz05;
