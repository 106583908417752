import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D02DonutChart2 = ({ filterCriteria }) => {
  const [favorableCount, setFavorableCount] = useState(0);
  const [neutralCount, setNeutralCount] = useState(0);
  const [unfavorableCount, setUnfavorableCount] = useState(0);
  const [noResponseCount, setNoResponseCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false); // State for showing/hiding the message

  useEffect(() => {
    // Filter the data based on the criteria
    const filterData = (item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    };

    const filteredData = jsonData.filter(filterData);

    let favorable = 0;
    let neutral = 0;
    let unfavorable = 0;
    let noResponse = 0;

    // Calculate the counts based on the responses
    filteredData.forEach((response) => {
      const { MQ0095 } = response;

      if (MQ0095 === 4 || MQ0095 === 5) {
        favorable++;
      } else if (MQ0095 === 3) {
        neutral++;
      } else if (MQ0095 === 1 || MQ0095 === 2) {
        unfavorable++;
      } else {
        noResponse++;
      }
    });

    setFavorableCount(favorable);
    setNeutralCount(neutral);
    setUnfavorableCount(unfavorable);
    setNoResponseCount(noResponse);

    // Prepare and render the chart only after the counts are updated
    const totalRespondents = favorable + neutral + unfavorable + noResponse;

    if (totalRespondents < 4) {
      setShowMessage(true); // Show the message if respondents are fewer than 4
      return;
    } else {
      setShowMessage(false); // Hide the message if there are enough respondents
    }

    const chartData = [
      { name: "No Response", value: noResponse, color: "#bab0ac" },
      { name: "Unfavorable", value: unfavorable, color: "#f28e2b" },
      { name: "Neutral", value: neutral, color: "#f1ce63" },
      { name: "Favorable", value: favorable, color: "#499894" },
    ];

    const filteredChartData = chartData.filter(item => item.value >= 4);
    const series = filteredChartData.map(item => item.value);
    const labels = filteredChartData.map(item => item.name);
    const colors = filteredChartData.map(item => item.color);

    const optionsDonut2 = {
      series: series,
      labels: labels,
      colors: colors,
      chart: {
        type: "donut",
        height: "300px",
        toolbar: {
          show: true,
        },
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 5,
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "16px",
        },
      },
    };

    const chartElement = document.querySelector("#donut-2");
    const donut2 = new ApexCharts(chartElement, optionsDonut2);
    donut2.render();

    // Clean up the chart on component unmount
    return () => {
      donut2.destroy();
    };
  }, [filterCriteria]);

  return (
    <div>
      {/* Show message only if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}
      {/* Chart will be rendered here */}
      <div id="donut-2" style={{ display: showMessage ? "none" : "block" }}></div>
    </div>
  );
};

export default D02DonutChart2;
