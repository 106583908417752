import React, { useEffect, useState } from "react";
import Chart1 from "react-apexcharts"; // Updated to Chart1
import jsonData from "../../../../som_2024-sm-data-numeric.json";
import "./D03Scatterplot01.css"; // Import the CSS file

const D03Scatterplot01 = ({ filterCriteria }) => {
  const [seriesData, setSeriesData] = useState([]);
  const [legendData, setLegendData] = useState([]);
  const [xAverage, setXAverage] = useState(0);
  const [yAverage, setYAverage] = useState(0);

  useEffect(() => {
    const rValueMap = {
      MQ0044: 0.54067588,
      MQ0047: 0.57228388,
      MQ0017: 0.553318708,
      MQ0048: 0.523197022,
      MQ0051: 0.598237795,
      MQ0050: 0.523031036,
      MQ0095: 0.699322233,
      MQ0016: 0.608670317,
      MQ0059: 0.559975188,
      MQ0104: 0.575167332,
      MQ0173: 0.58524578,
      MQ0054: 0.552950634,
      MQ0088: 0.473707971,
      MQ0090: 0.387181027,
      MQ0091: 0.509172371,
      MQ0086: 0.647191624,
      MQ0034: 0.640978291,
      MQ0041: 0.624789024,
      MQ0194: 0.693484794,
      MQ0321: 0.798834647,
      MQ0058: 0.628779549,
      MQ0056: 0.596627162,
      MQ0057: 0.590579776,
      MQ0038: 0.592657296,
      MQ0053: 0.605433982,
      MQ0171: 0.602494348,
      MQ0103: 0.587106964,
      MQ0071: 0.700412379,
      MQ0202: 0.629744104,
      MQ0027: 0.684096413,
      MQ0023: 0.581966261,
      MQ0024: 0.658101186,
      MQ0026: 0.771359412,
      MQ0064: 0.600200118,
      MQ0043: 0.616499221,
      MQ0062: 0.596811974,
      MQ0340: 0.608198728,
      MQ0341: 0.623314382,
      MQ0094: 0.655158798,
      MQ0021: 0.709837046,
      MQ0080: 0.729500166,
      MQ0302: 0.817926934,
      MQ0072: 0.640661753,
      MQ0029: 0.689790238,
      MQ0012: 0.63483892,
      MQ0331: 0.498666703,
      MQ0311: 0.621608117,
      MQ0312: 0.66257588,
      MQ0320: 0.710023282,
      MQ0046: 0.681113058,
      MQ0100: 0.601889321,
      MQ0190: 0.508585348,
      MQ0141: 0.558658177,
      MQ0343: 0.536280501,
    };

    const categories = {
      "Role Clarity: Clarity": ["MQ0044", "MQ0047", "MQ0017"],
      "Role Clarity: Resources": ["MQ0048", "MQ0051", "MQ0050"],
      "Role Clarity: Work Outcomes": ["MQ0095", "MQ0016", "MQ0059"],
      "Role Clarity: Feedback": ["MQ0104", "MQ0173", "MQ0054"],
      "Work Context: Safety": ["MQ0190", "MQ0141", "MQ0343"],
      "Work Context: Compensation": ["MQ0088", "MQ0090", "MQ0091"],
      "Work Context: Leadership": ["MQ0086", "MQ0034", "MQ0041"],
      "Work Context: Communication": ["MQ0194", "MQ0321", "MQ0058"],
      "Work Context: Supervision": ["MQ0056", "MQ0057", "MQ0038"],
      "Job Satisfaction: Recognition": ["MQ0053", "MQ0171", "MQ0103"],
      "Job Satisfaction: Autonomy": ["MQ0071", "MQ0202", "MQ0027"],
      "Job Satisfaction: Meaningfulness": ["MQ0023", "MQ0024", "MQ0026"],
      "Job Satisfaction: Growth": ["MQ0064", "MQ0062", "MQ0043"],
      "Job Satisfaction: Teamwork": ["MQ0340", "MQ0341", "MQ0094"],
      "Organizational Commitment: Belonging": ["MQ0021", "MQ0080", "MQ0302"],
      "Organizational Commitment: Vision and Values": [
        "MQ0072",
        "MQ0029",
        "MQ0012",
      ],
      "Organizational Commitment: Inclusion": ["MQ0311", "MQ0312", "MQ0331"],
      "Organizational Commitment: Openness": ["MQ0320", "MQ0046", "MQ0100"],
    };

    const categoryColors = {
      "Role Clarity": "#004983", // Blue
      "Job Satisfaction": "#008000", // Yellow
      "Work Context": "#ff8c00", // Purple
      "Organizational Commitment": "#ff0000", // Green
    };

    const processData = () => {
      let filteredData = jsonData;

      // Apply filter criteria
      if (
        filterCriteria.location_name &&
        filterCriteria.location_name.length > 0
      ) {
        filteredData = filteredData.filter((item) =>
          filterCriteria.location_name.includes(item.location_name)
        );
      }
      if (
        filterCriteria.business_unit_name &&
        filterCriteria.business_unit_name.length > 0
      ) {
        filteredData = filteredData.filter((item) =>
          filterCriteria.business_unit_name.includes(item.business_unit_name)
        );
      }
      if (
        filterCriteria.department_name &&
        filterCriteria.department_name.length > 0
      ) {
        filteredData = filteredData.filter((item) =>
          filterCriteria.department_name.includes(item.department_name)
        );
      }
      if (
        filterCriteria.job_classification_name &&
        filterCriteria.job_classification_name.length > 0
      ) {
        filteredData = filteredData.filter((item) =>
          filterCriteria.job_classification_name.includes(
            item.job_classification_name
          )
        );
      }
      if (
        filterCriteria.length_of_service_name &&
        filterCriteria.length_of_service_name.length > 0
      ) {
        filteredData = filteredData.filter((item) =>
          filterCriteria.length_of_service_name.includes(
            item.length_of_service_name
          )
        );
      }
      if (
        filterCriteria.generation_name &&
        filterCriteria.generation_name.length > 0
      ) {
        filteredData = filteredData.filter((item) =>
          filterCriteria.generation_name.includes(item.generation_name)
        );
      }
      if (
        filterCriteria.exempt_status_name &&
        filterCriteria.exempt_status_name.length > 0
      ) {
        filteredData = filteredData.filter((item) =>
          filterCriteria.exempt_status_name.includes(item.exempt_status_name)
        );
      }
      if (filterCriteria.gender_name && filterCriteria.gender_name.length > 0) {
        filteredData = filteredData.filter((item) =>
          filterCriteria.gender_name.includes(item.gender_name)
        );
      }
      if (filterCriteria.race_name && filterCriteria.race_name.length > 0) {
        filteredData = filteredData.filter((item) =>
          filterCriteria.race_name.includes(item.race_name)
        );
      }
      if (
        filterCriteria.additional_demographic_01_name &&
        filterCriteria.additional_demographic_01_name.length > 0
      ) {
        filteredData = filteredData.filter((item) =>
          filterCriteria.additional_demographic_01_name.includes(
            item.additional_demographic_01_name
          )
        );
      }

      console.log("Filtered Data:", filteredData); // Debug: Log filtered data

      let totalRValues = 0;
      let totalScores = 0;
      let count = 0;

      const data = Object.keys(categories).map((key) => {
        const category = key.split(":")[0].trim();
        const engArea = key.split(":")[1].trim();
        const questions = categories[key];
        const rValues = questions.map((q) => rValueMap[q] || 0);
        const averageRValue =
          rValues.reduce((a, b) => a + b, 0) / rValues.length;

        const scores = questions.map((q) => {
          const validScores = filteredData
            .map((item) => item[q])
            .filter(
              (score) =>
                score !== undefined &&
                score !== null &&
                score >= 1 &&
                score <= 5
            );
          return validScores.length
            ? validScores.reduce((a, b) => a + b, 0) / validScores.length
            : 0;
        });

        const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;

        totalRValues += averageRValue;
        totalScores += averageScore;
        count += 1;

        return {
          name: engArea,
          data: [{ x: averageRValue, y: averageScore }],
          color: categoryColors[category] || "#000000",
        };
      });

      setSeriesData(data);
      setXAverage(totalRValues / count);
      setYAverage(totalScores / count);

      // Prepare data for the legend
      const legendSeries = Object.keys(categoryColors).map((mainCategory) => ({
        name: mainCategory,
        color: categoryColors[mainCategory],
      }));
      setLegendData(legendSeries);
    };

    processData();
  }, [filterCriteria]);

  const options = {
    chart: {
      height: "100%",
      width: "100%",
      type: "scatter",
      zoom: {
        enabled: false,
        type: "xy",
      },
    },
    markers: {
      size: 10,
    },
    title: {
      text: 'Engagement Areas Plotted by Impact',
      align: 'Center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '20px',
        fontWeight:  'bold',
        fontFamily:  undefined,
        color:  '#263238'
      },
  },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      offsetX: 0,
      offsetY: 8,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return w.config.series[seriesIndex].name;
      },
      style: {
        fontSize: "11px",
        colors: ["#4c4c4c"],
      },
      background: {
        enabled: false,
      },
    },
    xaxis: {
      tickAmount: 5,
      stepSize: 0.1,
      min: 0.4,
      max: 0.8,
      labels: {
        formatter: function (val) {
          return parseFloat(val).toFixed(1);
        },
      },
    },
    yaxis: {
      tickAmount: 5,
      stepSize: 1,
      min: 2.5, // Adjusting to minimum valid score
      max: 5,
      labels: {
        formatter: function (val) {
          return parseFloat(val).toFixed(1);
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    annotations: {
      xaxis: [
        {
          x: xAverage,
          borderColor: "#000000",
        },
      ],
      yaxis: [
        {
          y: yAverage,
          borderColor: "#000000",
        },
      ],
      points: [
        {
          x: 0.425,
          y: 4.8,
          marker: {
            size: 0,
          },
          label: {
            borderColor: "#000000",
            style: {
              color: "#000000",
              background: "#fff",
              fontSize: "16px",
              fontWeight: "bold",
            },
            text: "Monitor",
          },
        },
        {
          x: 0.78,
          y: 4.8,
          marker: {
            size: 0,
          },
          label: {
            borderColor: "#000000",
            style: {
              color: "#000000",
              background: "#fff",
              fontSize: "16px",
              fontWeight: "bold",
            },
            text: "Leverage",
          },
        },
        {
          x: 0.425,
          y: 2.55,
          marker: {
            size: 0,
          },
          label: {
            borderColor: "#000000",
            style: {
              color: "#000000",
              background: "#fff",
              fontSize: "16px",
              fontWeight: "bold",
            },
            text: "Maintain",
          },
        },
        {
          x: 0.78,
          y: 2.55,
          marker: {
            size: 0,
          },
          label: {
            borderColor: "#000000",
            style: {
              color: "#000000",
              background: "#fff",
              fontSize: "16px",
              fontWeight: "bold",
            },
            text: "Improve",
          },
        },
      ],
    },
    legend: {
      show: false, // Hide default legend
    },
  };

  return (
    <div className="scatterplot-chart-container1">
      <Chart1 options={options} series={seriesData} type="scatter" /> {/* Updated to Chart1 */}
      <div className="scatterplot-legend-container">
        {legendData.map((item, index) => (
          <div key={index} className="scatterplot-legend-item">
            <span
              className="scatterplot-legend-dot"
              style={{ backgroundColor: item.color }}
            ></span>
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default D03Scatterplot01;
