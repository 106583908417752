// File: CCITextThemeDashboard.js

import React, { useState, useEffect, useCallback } from 'react';
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import NavigationSidebar from '../components/Navigationsidebar/NavigationSidebar';
import Header from '../components/Header';
import FilterSidebar from '../components/FilterSidebar/FilterSidebar';
import rawData from '../Open-Text-Theme-Processing (1).json'; // Ensure this file is correctly formatted and accessible
import './CCITextThemeDashboard.css';

const qNumOptions = [
  { value: 'MQ0305', label: 'What Makes this Company a Great Place to Work' },
  { value: 'MQ0156', label: 'What Would Make this Place a Better Place to Work' },
  { value: 'MQ0160', label: 'What Would you Like to Tell the Senior Leadership Team' },
  { value: 'SS_SOM_0016', label: 'For non-essential workers, please share your thoughts on the current work from home policy.' },
];

const CCITextThemeDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState({});
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });
  const [selectedQuestion, setSelectedQuestion] = useState(qNumOptions[0].value);

  useEffect(() => {
    const uniqueLocations = [...new Set(rawData.map(item => item.location_name).filter(Boolean))].sort();
    const uniqueBusinessUnits = [...new Set(rawData.map(item => item.business_unit_name).filter(Boolean))].sort();
    const uniqueDepartments = [...new Set(rawData.map(item => item.department_name).filter(Boolean))].sort();
    const uniqueJobClassifications = [...new Set(rawData.map(item => item.job_classification_name).filter(Boolean))].sort();

    const tenureOrder = [
      'Less than 1 year',
      '1 to 2 years',
      '2 to 5 years',
      '5 to 10 years',
      '11 to 15 years',
      '16 to 20 years',
      '20+ years'
    ];
    const uniqueTenures = [...new Set(rawData.map(item => item.length_of_service_name).filter(Boolean))].sort((a, b) => tenureOrder.indexOf(a) - tenureOrder.indexOf(b));

    const generationOrder = [
      'Traditionalists',
      'Baby Boomers',
      'Generation X',
      'Millennials',
      'Generation Z',
      'Generation Next',
      'NR'
    ];
    const uniqueGenerations = [...new Set(rawData.map(item => item.generation_name).filter(Boolean))].sort((a, b) => generationOrder.indexOf(a) - generationOrder.indexOf(b));

    const uniqueExemptStatuses = [...new Set(rawData.map(item => item.exempt_status_name).filter(Boolean))].sort();
    const uniqueGenders = [...new Set(rawData.map(item => item.gender_name).filter(Boolean))].sort();
    const uniqueRaces = [...new Set(rawData.map(item => item.race_name).filter(Boolean))].sort();
    const uniqueSupervisors = [...new Set(rawData.map(item => item.additional_demographic_01_name).filter(Boolean))].sort();

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  useEffect(() => {
    if (rawData.length > 0) {
      const myCCI = ["#004983", "#0083ca", "#68ae45"];
      const chart = echarts.init(document.getElementById('main'));

      // Filtering data by the selected question
      const filteredData = rawData.filter(item => item.Q_Num === selectedQuestion);

      // Calculate theme counts for the filtered data
      const themeCounts = filteredData.reduce((acc, entry) => {
        const themes = [
          entry.Theme_1,
          entry.Theme_2,
          entry.Theme_3,
          entry.Theme_4,
          entry.Theme_5,
          entry.Theme_6,
          entry.Theme_7,
          entry.Theme_8
        ];

        themes.forEach(theme => {
          if (theme) {
            acc[theme] = (acc[theme] || 0) + 1;
          }
        });

        return acc;
      }, {});

      const wordCloudData = Object.entries(themeCounts).map(([name, value]) => ({
        name,
        value
      }));

      const option = {
        tooltip: {},
        title: {
          text: `Open Text Question: ${qNumOptions.find(q => q.value === selectedQuestion)?.label}`,
          left: 'center',
          top: '10',
          textStyle: {
            fontSize: 20,
            fontWeight: 'normal',
            color: '#3d3d3d',
          },
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
              title: 'Save Image',
            },
          },
        },
        series: [
          {
            type: 'wordCloud',
            color: myCCI,
            gridSize: 14,
            sizeRange: [20, 75],
            rotationRange: [-75, 45],
            shape: 'random-dark',
            width: '100%',
            height: '95%',
            drawOutOfBound: true,
            textStyle: {
              fontFamily: 'sans-serif',
              fontWeight: 'normal',
            },
            colorBy: 'data',
            data: wordCloudData,
          },
        ],
      };

      chart.setOption(option);

      window.onresize = chart.resize;

      return () => {
        chart.dispose();
      };
    }
  }, [rawData, selectedQuestion]);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prevState => !prevState);
  }, []);

  const toggleFilterSidebar = useCallback(() => {
    setIsFilterSidebarOpen(prevState => !prevState);
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterCriteria(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleQuestionChange = (event) => {
    setSelectedQuestion(event.target.value);
  };

  return (
    <div className="ccitexttheme-dashboard-container flex flex-col h-screen">
      <Header
        title="Engagement Questions Word Cloud"
        toggleSidebar={toggleSidebar}
        toggleFilterSidebar={toggleFilterSidebar}
        refreshPage={refreshPage}
      />
      <NavigationSidebar
        className={isSidebarOpen ? 'ccitexttheme-sidebar-open' : 'ccitexttheme-sidebar-closed'}
        isOpen={isSidebarOpen}
      />
      <FilterSidebar
        isOpen={isFilterSidebarOpen}
        filterCriteria={filterCriteria}
        locations={filterData.locations}
        businessUnits={filterData.businessUnits}
        departments={filterData.departments}
        jobClassifications={filterData.jobClassifications}
        tenures={filterData.tenures}
        generations={filterData.generations}
        exemptStatuses={filterData.exemptStatuses}
        genders={filterData.genders}
        races={filterData.races}
        supervisors={filterData.supervisors}
        handleFilterChange={handleFilterChange}
      />
      <div className="ccitexttheme-main-content flex flex-grow">
        <div className="ccitexttheme-chart-container p-8 w-full">
          <div className="question-selector">
            <label htmlFor="question-dropdown">Select Question:</label>
            <select
              id="question-dropdown"
              value={selectedQuestion}
              onChange={handleQuestionChange}
            >
              {qNumOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div id="main" className="ccitexttheme-chart w-full h-full"></div>
        </div>
      </div>
    </div>
  );
};

export default CCITextThemeDashboard;
