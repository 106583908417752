import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import NavigationSidebar from '../components/Navigationsidebar/NavigationSidebar';
import Scatterplot01 from '../components/Dashboardviz/D12/D12Viz01/index';
import FilterSidebar from '../components/FilterSidebar/FilterSidebar';
import { useFilter } from '../FilterContext';
import jsonData from '../som_2024-sm-data-numeric.json'; // Ensure the path is correct
import './CCIImpactAreaDashboard.css'; // Import the Engage Survey CSS file

const Dashboard = () => {
  const { filterCriteria, setFilterCriteria } = useFilter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });

  useEffect(() => {
    const uniqueLocations = [...new Set(jsonData.map(item => item.location_name))].sort();
    const uniqueBusinessUnits = [...new Set(jsonData.map(item => item.business_unit_name))].sort();
    const uniqueDepartments = [...new Set(jsonData.map(item => item.department_name))].sort();
    const uniqueJobClassifications = [...new Set(jsonData.map(item => item.job_classification_name))].sort();

    const tenureOrder = [
      'Less than 1 year',
      '1 to 2 years',
      '2 to 5 years',
      '5 to 10 years',
      '11 to 15 years',
      '16 to 20 years',
      '20+ years'
    ];
    const uniqueTenures = [...new Set(jsonData.map(item => item.length_of_service_name))].sort((a, b) => tenureOrder.indexOf(a) - tenureOrder.indexOf(b));

    const generationOrder = [
      'Traditionalists',
      'Baby Boomers',
      'Generation X',
      'Millennials',
      'Generation Z',
      'Generation Next',
      'NR'
    ];
    const uniqueGenerations = [...new Set(jsonData.map(item => item.generation_name))].sort((a, b) => generationOrder.indexOf(a) - generationOrder.indexOf(b));

    const uniqueExemptStatuses = [...new Set(jsonData.map(item => item.exempt_status_name))].sort();
    const uniqueGenders = [...new Set(jsonData.map(item => item.gender_name))].sort();
    const uniqueRaces = [...new Set(jsonData.map(item => item.race_name))].sort();
    const uniqueSupervisors = [...new Set(jsonData.map(item => item.additional_demographic_01_name))].sort();

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleFilterSidebar = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterCriteria(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="cciimpactarea-dashboard-container">
      <Header title="Engagement Summary" toggleSidebar={toggleSidebar} refreshPage={refreshPage} toggleFilterSidebar={toggleFilterSidebar} />
      <NavigationSidebar isOpen={isSidebarOpen} />
      <div className="cciimpactarea-main-content">
        <FilterSidebar 
          filterCriteria={filterCriteria}
          locations={filterData.locations}
          businessUnits={filterData.businessUnits}
          departments={filterData.departments}
          jobClassifications={filterData.jobClassifications}
          tenures={filterData.tenures}
          generations={filterData.generations}
          exemptStatuses={filterData.exemptStatuses}
          genders={filterData.genders}
          races={filterData.races}
          supervisors={filterData.supervisors}
          handleFilterChange={handleFilterChange}
          isOpen={isFilterSidebarOpen}
        />
        <div className="cciimpactarea-content-wrapper">
          <div className="cciimpactarea-charts-wrapper">
            <div className="cciimpactarea-charts-container">
              <div className="cciimpactarea-chart">
                <h2 className="cciimpactarea-chart-title">Engagement Areas plotted by Impact</h2>
                <Scatterplot01 filterCriteria={filterCriteria} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="toggle-filter-sidebar-btn" onClick={toggleFilterSidebar}>
      </button>
    </div>
  );
};

export default Dashboard;
