// RespondentsSummary.jsx
import React from 'react';
import PropTypes from 'prop-types';
import jsonData from '../../../../som_2024-sm-data-numeric.json'; // Ensure the path is correct

const RespondentsSummary = ({ filterCriteria }) => {
  const totalRespondents = jsonData.length;

  const filteredRespondents = jsonData.filter(item => {
    return Object.keys(filterCriteria).every(key => {
      if (!filterCriteria[key] || filterCriteria[key].length === 0) return true;
      return filterCriteria[key].includes(item[key]);
    });
  }).length;

  return (
    <div className="respondents-summary">
      <div className="respondents-total">
        <h3>Total Respondents:</h3>
        <p>{totalRespondents}</p>
      </div>
      <div className="respondents-filtered">
        <h3>Filtered Respondents:</h3>
        <p>{filteredRespondents}</p>
      </div>
    </div>
  );
};

RespondentsSummary.propTypes = {
  filterCriteria: PropTypes.object.isRequired,
};

export default RespondentsSummary;
