import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Img, Text, Heading } from '../components';
import AnalyzeCardchannels from '../components/AnalyzeCardchannels';
import Header from '../components/Header';
import { ReactTable } from '../components/ReactTable';
import Sidebar2 from '../components/Sidebar2';
import { createColumnHelper } from '@tanstack/react-table';
import '../App.css';
import { TableauEmbed } from '@stoddabr/react-tableau-embed-live';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>AI Architect</title>
      </Helmet>
      {/* <Header /> */}
      <div className="flex w-full h-full">
        <Sidebar2 className="w-1/4 h-full" />
        <div className="flex-grow flex items-center justify-center">
          <TableauEmbed
            sourceUrl="https://prod-useast-b.online.tableau.com/#/site/acumenanalytics/views/ArriaDemo-SupplierNarrative/SupplierAnalysis"
            className="App"
              style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "80vw",
              height: "95vh",
              toolbar: "hidden",}}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
