import React, { useState, useEffect } from "react";
import NavigationSidebar from "../components/Navigationsidebar/NavigationSidebar";
import Header from "../components/Header";
import RangeChart1 from "../components/Dashboardviz/D07/D07Viz01";
import RangeChart2 from "../components/Dashboardviz/D07/D07Viz02";
import RangeChart3 from "../components/Dashboardviz/D07/D07Viz03";
import RangeChart4 from "../components/Dashboardviz/D07/D07Viz04";
import FilterSidebar from "../components/FilterSidebar/FilterSidebar"; // Ensure the path is correct
import { useFilter } from "../FilterContext"; // Ensure the path is correct
import jsonData from "../som_2024-sm-data-numeric.json"; // Ensure the path is correct
import "./CCIResponseRangeDashboard.css"; // Import the CSS file

const CCIResponseRangeDashboard = () => {
  const { filterCriteria, setFilterCriteria } = useFilter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [selectedDemographic, setSelectedDemographic] =
    useState("generation_name"); // Default to "Generation"
  const [legendItems, setLegendItems] = useState([]);
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });

  useEffect(() => {
    const uniqueLocations = getUniqueFieldValues("location_name");
    const uniqueBusinessUnits = getUniqueFieldValues("business_unit_name");
    const uniqueDepartments = getUniqueFieldValues("department_name");
    const uniqueJobClassifications = getUniqueFieldValues(
      "job_classification_name"
    );
    const uniqueTenures = getSortedFieldValues("length_of_service_name", [
      "Less than 1 year",
      "1 to 2 years",
      "3 to 5 years",
      "6 to 10 years",
      "11 to 19 years",
      "20 or more years",
    ]);
    const uniqueGenerations = getSortedFieldValues("generation_name", [
      "Traditionalist",
      "Baby Boomer",
      "Generation X",
      "Millennial",
      "Generation Z",
      "Generation Next",
      "No Response",
    ]);
    const uniqueExemptStatuses = getUniqueFieldValues("exempt_status_name");
    const uniqueGenders = getUniqueFieldValues("gender_name");
    const uniqueRaces = getUniqueFieldValues("race_name");
    const uniqueSupervisors = getUniqueFieldValues(
      "additional_demographic_01_name"
    );

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleFilterSidebar = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleDemographicChange = (event) => {
    setSelectedDemographic(event.target.value);
  };

  const handleFilterChange = (selectedOptions, actionMeta) => {
    const { name } = actionMeta;
    const allOptions = filterData[name];
    if (
      selectedOptions &&
      selectedOptions.some((option) => option.value === "select-all")
    ) {
      if (selectedOptions.length === allOptions.length + 1) {
        setFilterCriteria((prevState) => ({
          ...prevState,
          [name]: [],
        }));
      } else {
        setFilterCriteria((prevState) => ({
          ...prevState,
          [name]: allOptions.map((option) => option.value),
        }));
      }
    } else {
      setFilterCriteria((prevState) => ({
        ...prevState,
        [name]: selectedOptions
          ? selectedOptions.map((option) => option.value)
          : [],
      }));
    }
  };

  const getUniqueFieldValues = (fieldName) => {
    return [...new Set(jsonData.map((item) => item[fieldName]))].sort();
  };

  const getSortedFieldValues = (fieldName, order) => {
    return [...new Set(jsonData.map((item) => item[fieldName]))].sort(
      (a, b) => order.indexOf(a) - order.indexOf(b)
    );
  };

  useEffect(() => {
    const demographicValues = [
      ...new Set(jsonData.map((item) => item[selectedDemographic])),
    ];
    setLegendItems(
      demographicValues.map((value, index) => ({
        color: [
          "#E69F00",
          "#56B4E9",
          "#009E73",
          "#F0E442",
          "#0072B2",
          "#D55E00",
          "#CC79A7",
          "#E3E639",
          "#E634A2",
          "#35C7D1",
          "#4735E0",
          "#399BE6",
          "#2D8F68",
        ][index % 13],
        label: value,
      }))
    );
  }, [selectedDemographic]);

  const chartComponents = [
    {
      component: (
        <RangeChart1
          selectedDemographic={selectedDemographic}
          filterCriteria={filterCriteria}
        />
      ),
      title: "Precursor: Role Clarity",
      span: "2 / span 5",
    },
    {
      component: (
        <RangeChart2
          selectedDemographic={selectedDemographic}
          filterCriteria={filterCriteria}
        />
      ),
      title: "Precursor: Work Context",
      span: "2 / span 5",
    },
    {
      component: (
        <RangeChart3
          selectedDemographic={selectedDemographic}
          filterCriteria={filterCriteria}
        />
      ),
      title: "Catalyst: Job Satisfaction",
      span: "2 / span 5",
    },
    {
      component: (
        <RangeChart4
          selectedDemographic={selectedDemographic}
          filterCriteria={filterCriteria}
        />
      ),
      title: "Catalyst: Org Commitment",
      span: "2 / span 5",
    },
  ];

  return (
    <div className="cciresponse-range-dashboard-container">
      <Header
        title="Dashboard Title"
        toggleSidebar={toggleSidebar}
        toggleFilterSidebar={toggleFilterSidebar}
        refreshPage={refreshPage}
      />
      <NavigationSidebar
        className={
          isSidebarOpen
            ? "navigation-sidebar-open"
            : "navigation-sidebar-closed"
        }
        isOpen={isSidebarOpen}
      />
      <div className="cciresponse-range-main-content">
        <div className="cciresponse-range-content">
          <FilterSidebar
            filterCriteria={filterCriteria}
            locations={filterData.locations}
            businessUnits={filterData.businessUnits}
            departments={filterData.departments}
            jobClassifications={filterData.jobClassifications}
            tenures={filterData.tenures}
            generations={filterData.generations}
            exemptStatuses={filterData.exemptStatuses}
            genders={filterData.genders}
            races={filterData.races}
            supervisors={filterData.supervisors}
            handleFilterChange={handleFilterChange}
            isOpen={isFilterSidebarOpen}
          />
          <div className="cciresponse-range-charts-wrapper">
            <div className="custom-legend-container">
            <div className="demographic-selector">
              <label>
                Select Demographic:
               <select
                  value={selectedDemographic}
                  onChange={(event) => {
                    console.log('Dropdown clicked', event.target.value);
                    handleDemographicChange(event);
                  }}
                >
                  <option value="business_unit_name">Location 2</option>
                  <option value="department_name">Department</option>
                  <option value="exempt_status_name">Work Status</option>
                  <option value="generation_name">Generation</option>
                  <option value="length_of_service_name">Tenure</option>
                  <option value="gender_name">Gender</option>
                  <option value="race_name">Ethnicity</option>
                </select>
              </label>
            </div>

              <div className="custom-legend">
                {legendItems.map((item, index) => (
                  <div key={index} className="legend-item">
                    <span
                      className="legend-marker"
                      style={{ backgroundColor: item.color }}
                    ></span>
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="cciresponse-range-content">
              <div className="cciresponse-range-charts-container">
                {chartComponents.map((chart, index) => (
                  <div
                    key={index}
                    className="cciresponse-range-chart"
                    style={{ gridColumn: chart.span }}
                  >
                    <h2 className="cciresponse-range-chart-title">
                      {chart.title}
                    </h2>
                    {chart.component}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCIResponseRangeDashboard;
