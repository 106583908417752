import { Helmet } from "react-helmet";
import Sidebar2 from "../components/Sidebar2";
import React from "react";
// import "App.css";

export default function DiscoverPage() {
  return (
    <>
      <Helmet>
        <title>Discover Features - AI Architect Compose</title>
        <meta
          name="description"
          content="Discover the powerful features of AI Architect Compose. From composing to analyzing, learn how our platform can streamline your workflow."
        />
      </Helmet>

      <div className="flex w-full h-screen">
        <Sidebar2 className="w-1/4 h-full" />
        <div className="flex-grow">
          <iframe
            style={{
              width: "85vw",
              height: "100vh",
              marginLeft: "2vw",
              marginTop: "0px",
            }}
            src="https://alpha.docxonomy.cloud/"
            title="Discover"
          ></iframe>
        </div>
      </div>
    </>
  );
}

