import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const engagementGroups = {
  "Role Clarity": ["MQ0044", "MQ0047", "MQ0017"],
  "Resources": ["MQ0048", "MQ0051", "MQ0050"],
  "Work Outcomes": ["MQ0095", "MQ0016", "MQ0059"],
  "Feedback": ["MQ0104", "MQ0173", "MQ0054"],
};

const Heatmap1 = ({ selectedDemographic, filterCriteria }) => {
  const [chartData, setChartData] = useState({
    series: [],
    categories: [],
  });
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility

  useEffect(() => {
    // Filter the data based on the filter criteria
    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true;
        }
        return filterCriteria[key].includes(item[key]);
      });
    });

    // Show message if there are fewer than 4 respondents
    setShowMessage(filteredData.length < 4);

    // Process the JSON data if enough respondents
    if (filteredData.length >= 4) {
      const demographicValues = [
        ...new Set(filteredData.map((item) => item[selectedDemographic])),
      ].sort();

      const seriesData = Object.keys(engagementGroups).map((group) => {
        return {
          name: group,
          data: demographicValues.map((value) => {
            const responses = filteredData.filter(
              (item) => item[selectedDemographic] === value
            );
            const scores = responses.map((response) => {
              const totalScore = engagementGroups[group].reduce(
                (acc, question) => {
                  return acc + (parseFloat(response[question]) || 0);
                },
                0
              );
              return totalScore / engagementGroups[group].length; // Average score for the group
            });
            const averageScore = scores.length
              ? scores.reduce((acc, score) => acc + score, 0) / scores.length
              : 0;
            return {
              x: value,
              y: averageScore.toFixed(1), // Keep the average score between 1 and 5
            };
          }),
        };
      });

      setChartData({
        series: seriesData,
        categories: demographicValues,
      });
    }
  }, [selectedDemographic, filterCriteria]);

  const options1 = {
    series: chartData.series,
    chart: {
      height: "100%", // Ensure the chart takes full height of the container
      type: "heatmap",
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            {
              from: 1,
              to: 1.99,
              color: "#ffffff", // White
              name: "Very Low",
            },
            {
              from: 2,
              to: 2.99,
              color: "#cce6ff", // Light Blue
              name: "Low",
            },
            {
              from: 3,
              to: 3.99,
              color: "#66b3ff", // Medium Blue
              name: "Medium",
            },
            {
              from: 4,
              to: 4.99,
              color: "#0073e6", // Dark Blue
              name: "High",
            },
            {
              from: 5,
              to: 5,
              color: "#004080", // Very Dark Blue
              name: "Very High",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000000"],
      },
      formatter: function (val) {
        return val.toFixed(1);
      },
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: ["#6d6d6d"],
          fontSize: "0.8rem",
        },
      },
    },
    colors: ["#008FFB"],
  };

  return (
    <div>
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}

      {/* Chart will only be displayed if there are enough respondents */}
      {!showMessage && (
        <div>
          <Chart
            options={options1}
            series={chartData.series}
            type="heatmap"
            height="100%"
            width="100%"
          />
        </div>
      )}
    </div>
  );
};

export default Heatmap1;
