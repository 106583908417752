import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct
import "../../../../pages/CCIDemoResponseDashboard.css"; // Import the CSS file

const StackedBarChart = ({
  selectedQuestion,
  selectedDemographic,
  filterCriteria,
}) => {
  const [chartData, setChartData] = useState({
    favorable: [],
    neutral: [],
    unfavorable: [],
    noResponse: [],
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getSortedFieldValues = (fieldName, order) => {
      return [...new Set(jsonData.map((item) => item[fieldName]))].sort(
        (a, b) => order.indexOf(a) - order.indexOf(b)
      );
    };

    const demographicValues =
      selectedDemographic === "length_of_service_name"
        ? getSortedFieldValues("length_of_service_name", [
            "Less than 1 year",
            "1 to 2 years",
            "3 to 5 years",
            "6 to 10 years",
            "11 to 19 years",
            "20 years or more",
          ])
        : selectedDemographic === "generation_name"
        ? getSortedFieldValues("generation_name", [
            "Traditionalist",
            "Baby Boomer",
            "Generation X",
            "Millennial",
            "Generation Z",
            "Generation Next",
            "No Response",
          ])
        : getFilteredDemographics(selectedDemographic);

    const totalResponses = jsonData.length;

    const data = demographicValues.reduce(
      (acc, value) => {
        const filteredData = filterDataByCriteria(
          jsonData,
          selectedDemographic,
          value,
          filterCriteria
        );
        let favorable = 0;
        let neutral = 0;
        let unfavorable = 0;
        let noResponse = 0;

        filteredData.forEach((response) => {
          const answer = response[selectedQuestion];

          if (answer === 4 || answer === 5) {
            favorable++;
          } else if (answer === 3) {
            neutral++;
          } else if (answer === 1 || answer === 2) {
            unfavorable++;
          } else {
            noResponse++;
          }
        });

        acc.favorable.push((favorable / totalResponses) * 100);
        acc.neutral.push((neutral / totalResponses) * 100);
        acc.unfavorable.push((unfavorable / totalResponses) * 100);
        acc.noResponse.push((noResponse / totalResponses) * 100);

        return acc;
      },
      { favorable: [], neutral: [], unfavorable: [], noResponse: [] }
    );

    setChartData(data);
    setCategories(demographicValues);
  }, [selectedQuestion, selectedDemographic, filterCriteria]);

  useEffect(() => {
    const barHeight = 20; // Standard height for each bar
    const chartHeight = categories.length * barHeight + 100; // 100px for padding and legends

    const stackedBarsOptions = {
      series: [
        {
          name: "Favorable",
          data: chartData.favorable,
        },
        {
          name: "Neutral",
          data: chartData.neutral,
        },
        {
          name: "Unfavorable",
          data: chartData.unfavorable,
        },
        {
          name: "No Response",
          data: chartData.noResponse,
        },
      ],
      chart: {
        type: "bar",
        height: "350px",
        width: "700px",
        stacked: true,
        toolbar: {
          show: true,
        },
      },
      title: {
        text: 'Percentages within each Demographic Category',
        align: 'Center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '20px',
          fontWeight:  'bold',
          fontFamily:  undefined,
          color:  '#263238'
        },
    },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: `20px`, // Adjusted bar height to control size
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 8,
              style: {
                fontSize: "12px",
                fontWeight: 700,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: categories,
      },
      dataLabels: {
        enabled: false,
        textAnchor: "start",
        style: {
          colors: ["#000"],
        },
        formatter: function (val) {
          return isNaN(val) ? "0%" : `${val.toFixed(1)}%`;
        },
      },
      colors: ["#499894", "#f1ce63", "#f28e2b", "#bab0ac"], // Colors corresponding to series order
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return isNaN(val) ? "0%" : `${val.toFixed(1)}%`;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
    };

    const stackedBarsChart = new ApexCharts(
      document.querySelector("#stacked-bar-chart"),
      stackedBarsOptions
    );
    stackedBarsChart.render();

    return () => {
      stackedBarsChart.destroy();
    };
  }, [chartData, categories]);

  const getFilteredDemographics = (demographicType) => {
    return [...new Set(jsonData.map((item) => item[demographicType]))].sort();
  };

  const filterDataByCriteria = (
    data,
    demographicType,
    demographicValue,
    criteria
  ) => {
    return data.filter((item) => {
      let match = true;
      Object.keys(criteria).forEach((key) => {
        if (criteria[key].length > 0 && !criteria[key].includes(item[key])) {
          match = false;
        }
      });
      return match && item[demographicType] === demographicValue;
    });
  };

  return (
    <div className="CCIDemoResponse-internal-charts">
      <div className="CCIDemoResponse-internal-chart-container">
        <div
          id="stacked-bar-chart"
          className="CCIDemoResponse-internal-chart"
        ></div>
      </div>
    </div>
  );
};

export default StackedBarChart;
