import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import { useFilter } from "../../../../FilterContext";
import jsonData from "../../../../som_2024-sm-data-numeric.json";
import "./engagement-summary.css"; // Import the prefixed CSS file

const OrganizationalCommitmentChart = () => {
  const { filterCriteria } = useFilter();

  const calculateAverageScores = (data, categories) => {
    const scores = Object.keys(categories).reduce((acc, key) => {
      const categoryScores = categories[key].map((question) =>
        data
          .map((item) => item[question])
          .filter(
            (score) =>
              score !== undefined && score !== null && score >= 1 && score <= 5
          )
      );

      const categoryAverages = categoryScores.map((scoresArray) =>
        scoresArray.length
          ? scoresArray.reduce((a, b) => a + b, 0) / scoresArray.length
          : 0
      );

      acc[key] =
        categoryAverages.reduce((a, b) => a + b, 0) / categoryAverages.length;
      return acc;
    }, {});

    Object.keys(scores).forEach((key) => {
      scores[key] = parseFloat(scores[key].toFixed(1));
    });

    return scores;
  };

  useEffect(() => {
    const categories = {
      "Organizational Commitment: Belonging": ["MQ0021", "MQ0080", "MQ0302"],
      "Organizational Commitment: Vision and Values": ["MQ0072", "MQ0029", "MQ0012"],
      "Organizational Commitment: Inclusion": ["MQ0311", "MQ0312", "MQ0331"],
      "Organizational Commitment: Openness": ["MQ0320", "MQ0046", "MQ0100"],
    };

    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        const criteria = filterCriteria[key];
        if (Array.isArray(criteria)) {
          return criteria.length === 0 || criteria.includes(item[key]);
        }
        return criteria === "" || item[key] === criteria;
      });
    });

    const scores = calculateAverageScores(filteredData, categories);

    const options = {
      chart: {
        type: "bar",
        height: "100%",  // This will make the chart fill the height
        width: "100%",   // This will make the chart fill the width
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      legend: {
        show: false,
      },
      title: {
        text: "Average Scores by Organizational Commitment",
        align: "Center",
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          color: "#263238",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          horizontal: true,
          dataLabels: {
            position: "top",
          },
          barHeight: "50%",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => val.toFixed(1),
        offsetX: 35,
        style: {
          colors: ["#000"],
        },
      },
      series: [
        {
          name: "Avg Score",
          data: [
            scores["Organizational Commitment: Belonging"],
            scores["Organizational Commitment: Inclusion"],
            scores["Organizational Commitment: Openness"],
            scores["Organizational Commitment: Vision and Values"],
          ],
        },
      ],
      colors: ["#ff0000"],
      xaxis: {
        max: 5.0,
        categories: ["Belonging", "Inclusion", "Openness", "Vision and Values"],
      },
      yaxis: {
        labels: {
          show: true,
          minWidth: 120,
        },
      },
    };

    const chart = new ApexCharts(document.querySelector("#oc-bar"), options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [filterCriteria]);

  return <div className="engagement-summary-chart-container"><div id="oc-bar"></div></div>;
};

export default OrganizationalCommitmentChart;
