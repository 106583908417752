import { Helmet } from "react-helmet";
import { Text, Button, Switch, Input, Heading, Img } from "../components";
import React from "react";

export default function SigninillustrationPage() {
  return (
    <>
      <Helmet>
        <title>Sign In - Access Your Account</title>
        <meta
          name="description"
          content="Sign in to your account using your email and password. Remember your credentials for faster access. Don't have an account? Sign up now."
        />
      </Helmet>

      {/* sign in section */}
      <div className="flex w-full items-center gap-[219px] bg-white-A700 p-4 md:flex-col md:gap-5">
        {/* illustration image section */}
        <Img
          src="images/img_image_illustrator.png"
          alt="illustration image"
          className="ml-3 h-[1168px] w-[55%] rounded-lg object-cover md:ml-0 md:w-full"
        />
        <div className="flex w-[22%] flex-col items-center md:w-full">
          <a href="#">
            <Heading size="2xl" as="h1" className="!text-blue_gray-800">
              Sign In
            </Heading>
          </a>
          <Text size="md" as="p" className="mt-[15px]">
            Enter your email and password to sign in
          </Text>

          {/* sign in form section */}
          <div className="mt-[57px] self-stretch">
            <div className="flex flex-col items-center">
              <Input shape="round" type="email" name="Email Field" placeholder={`Email`} />
              <Input
                shape="round"
                type="password"
                name="Password Field"
                placeholder={`Current password`}
                className="mt-3.5"
              />
              <a href="landingpage" target="_blank">
                <Button variant="gradient" shape="round" color="pink_400_pink_600" className="mt-[45px] w-full sm:px-5">
                  SIGN IN
                </Button>
              </a>
              <Text as="p" className="mt-[30px]">
                <span className="text-blue_gray-400_01">Don’t have an account?&nbsp;</span>
                <span className="font-bold text-pink-500">Sign up</span>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
