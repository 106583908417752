import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const BarChartViz01 = ({ filterCriteria }) => {
  const [seriesData, setSeriesData] = useState([]);
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility

  useEffect(() => {
    const countResponses = (data, criteria) => {
      const counts = { NR: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

      // Filter the data based on criteria
      const filteredData = data.filter((item) => {
        return Object.keys(criteria).every((key) => {
          if (!criteria[key] || criteria[key].length === 0) {
            return true;
          }
          if (Array.isArray(criteria[key])) {
            return criteria[key].includes(item[key]);
          }
          return item[key] === criteria[key];
        });
      });

      // Show message if there are fewer than 4 respondents
      setShowMessage(filteredData.length < 4);

      // If there are fewer than 4 respondents, return empty counts
      if (filteredData.length < 4) {
        return Object.values(counts); // Return an empty series if not enough respondents
      }

      // Count responses for filtered data
      filteredData.forEach((item) => {
        const response = item.SS_SOM_0010;
        if (response >= 1 && response <= 5) {
          counts[response]++;
        } else {
          counts.NR++;
        }
      });

      return Object.values(counts);
    };

    const newSeriesData = countResponses(jsonData, filterCriteria);
    setSeriesData(newSeriesData);
  }, [filterCriteria]);

  const chartOptions = {
    series: [
      {
        name: "Count of Respondents",
        data: seriesData,
      },
    ],
    colors: ["#f28e2b", "#f28e2b", "#f1ce63", "#499894", "#499894", "#bab0ac"],
    chart: {
      type: "bar",
      height: "100%", // Full height
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#263238"],
      },
    },
    xaxis: {
      categories: ["Not At All", "Slightly", "Somewhat", "Strongly", "Very Strongly", "NR"],
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
  };

  return (
    <div style={{ height: "100%" }}>
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Please expand the selection to include more respondents.
        </div>
      )}

      {!showMessage && (
        <div className="ccisurveyspecific-chart-container">
          <Chart
            options={chartOptions}
            series={chartOptions.series}
            type={chartOptions.chart.type}
            height="85%" // Make sure the chart fills the container height
            width="100%" // Make sure the chart fills the container width
          />
        </div>
      )}
    </div>
  );
};

export default BarChartViz01;
