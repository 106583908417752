import React, { useState, useEffect, useCallback } from 'react';
import NavigationSidebar from '../components/Navigationsidebar/NavigationSidebar';
import Header from '../components/Header';
import FilterSidebar from '../components/FilterSidebar/FilterSidebar';
import jsonData from '../Open-Text-Theme-Processing (1).json';
import './CCITextResponseTable.css'; // Import the CSS file
import { useFilter } from '../FilterContext'; // Import the filter context

const CCITextResponseTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [questionNumber, setQuestionNumber] = useState("MQ0305");
  const [theme, setTheme] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [themes, setThemes] = useState([]);
  const [showMessage, setShowMessage] = useState(false); // For displaying message when data points < 4

  const { filterCriteria } = useFilter(); // Get filter criteria from context

  useEffect(() => {
    setData(jsonData);
    setFilteredData(jsonData.filter((item) => item.Q_Num === questionNumber));

    const uniqueThemes = new Set();
    jsonData.forEach(item => {
      for (let i = 1; i <= 8; i++) {
        const themeKey = `Theme_${i}`;
        if (item[themeKey]) uniqueThemes.add(item[themeKey]);
      }
    });
    setThemes(Array.from(uniqueThemes));
  }, [questionNumber]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleQuestionChange = (event) => {
    setQuestionNumber(event.target.value);
  };

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prevState) => !prevState);
  }, []);

  const toggleFilterSidebar = useCallback(() => {
    setIsFilterSidebarOpen((prevState) => !prevState);
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    const filtered = data.filter((item) => {
      const matchesQuestion = item.Q_Num === questionNumber;
      const matchesSearchTerm = item.Open_Response?.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesTheme = theme === "" || Object.values(item).includes(theme);

      const matchesLocation = !filterCriteria.location_name || filterCriteria.location_name.length === 0 || filterCriteria.location_name.includes(item.location_name);
      const matchesBusinessUnit = !filterCriteria.business_unit_name || filterCriteria.business_unit_name.length === 0 || filterCriteria.business_unit_name.includes(item.business_unit_name);
      const matchesDepartment = !filterCriteria.department_name || filterCriteria.department_name.length === 0 || filterCriteria.department_name.includes(item.department_name);
      const matchesJobClassification = !filterCriteria.job_classification_name || filterCriteria.job_classification_name.length === 0 || filterCriteria.job_classification_name.includes(item.job_classification_name);
      const matchesTenure = !filterCriteria.length_of_service_name || filterCriteria.length_of_service_name.length === 0 || filterCriteria.length_of_service_name.includes(item.length_of_service_name);
      const matchesGeneration = !filterCriteria.generation_name || filterCriteria.generation_name.length === 0 || filterCriteria.generation_name.includes(item.generation_name);
      const matchesExemptStatus = !filterCriteria.exempt_status_name || filterCriteria.exempt_status_name.length === 0 || filterCriteria.exempt_status_name.includes(item.exempt_status_name);
      const matchesGender = !filterCriteria.gender_name || filterCriteria.gender_name.length === 0 || filterCriteria.gender_name.includes(item.gender_name);
      const matchesRace = !filterCriteria.race_name || filterCriteria.race_name.length === 0 || filterCriteria.race_name.includes(item.race_name);
      const matchesSupervisor = !filterCriteria.supervisor_name || filterCriteria.supervisor_name.length === 0 || filterCriteria.supervisor_name.includes(item.additional_demographic_01_name);

      return (
        matchesQuestion &&
        matchesSearchTerm &&
        matchesTheme &&
        matchesLocation &&
        matchesBusinessUnit &&
        matchesDepartment &&
        matchesJobClassification &&
        matchesTenure &&
        matchesGeneration &&
        matchesExemptStatus &&
        matchesGender &&
        matchesRace &&
        matchesSupervisor
      );
    });

    // Show message if there are fewer than 4 data points
    setShowMessage(filtered.length < 4);
    setFilteredData(filtered);
  }, [searchTerm, questionNumber, theme, data, filterCriteria]);

  const downloadCSV = () => {
    const csvData = filteredData.map((item) => ({
      Response: item.Open_Response,
    }));

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        "Response",
        ...csvData.map((e) => `"${Object.values(e).join('","')}"`),
      ].join("\n");

    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "text_responses.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uniqueLocations = Array.from(new Set(jsonData.map(item => item.location_name)));
  const uniqueBusinessUnits = Array.from(new Set(jsonData.map(item => item.business_unit_name)));
  const uniqueDepartments = Array.from(new Set(jsonData.map(item => item.department_name)));
  const uniqueJobClassifications = Array.from(new Set(jsonData.map(item => item.job_classification_name)));
  const uniqueTenures = Array.from(new Set(jsonData.map(item => item.length_of_service_name)));
  const uniqueGenerations = Array.from(new Set(jsonData.map(item => item.generation_name)));
  const uniqueExemptStatuses = Array.from(new Set(jsonData.map(item => item.exempt_status_name)));
  const uniqueGenders = Array.from(new Set(jsonData.map(item => item.gender_name)));
  const uniqueRaces = Array.from(new Set(jsonData.map(item => item.race_name)));
  const uniqueSupervisors = Array.from(new Set(jsonData.map(item => item.additional_demographic_01_name))); 

  return (
    <div className="ccitextresponse-dashboard-container">
      <Header
        title="Engagement Questions Table"
        toggleSidebar={toggleSidebar}
        toggleFilterSidebar={toggleFilterSidebar}
        refreshPage={refreshPage}
      />
      <NavigationSidebar
        className={
          isSidebarOpen
            ? "ccitextresponse-sidebar-open"
            : "ccitextresponse-sidebar-closed"
        }
        isOpen={isSidebarOpen}
      />
      {uniqueLocations.length > 0 && (
        <FilterSidebar
          isOpen={isFilterSidebarOpen}
          toggleSidebar={toggleFilterSidebar}
          locations={uniqueLocations}
          businessUnits={uniqueBusinessUnits}
          departments={uniqueDepartments}
          jobClassifications={uniqueJobClassifications}
          tenures={uniqueTenures}
          generations={uniqueGenerations}
          exemptStatuses={uniqueExemptStatuses}
          genders={uniqueGenders}
          races={uniqueRaces}
          supervisors={uniqueSupervisors}
        />
      )}
      <div className="ccitextresponse-main-content">
        <div className="ccitextresponse-app-container p-8 w-full">
          <div className="ccitextresponse-controls">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleChange}
              className="searchbar"
            />
            <select
              value={questionNumber}
              onChange={handleQuestionChange}
              className="searchbar2"
            >
              <option value="MQ0305">
                What Makes this Company a Great Place to Work
              </option>
              <option value="MQ0156">
                What Would Make this Place a Better Place to Work
              </option>
              <option value="MQ0160">
                What Would you Like to Tell the Senior Leadership Team
              </option>
              <option value="SS_SOM_0016">For non-essential workers, please share your thoughts on the current work from home policy.</option>
            </select>
            <select
              value={theme}
              onChange={handleThemeChange}
              className="searchbar2"
            >
              <option value="">All Themes</option>
              {themes.map((theme, index) => (
                <option key={index} value={theme}>{theme}</option>
              ))}
            </select>
            <button
              onClick={downloadCSV}
              className="download-button"
            >
              Download CSV
            </button>
          </div>
          <div className="ccitextresponse-scrollable-table">
            {showMessage ? (
              <div style={{ color: 'red', marginTop: '20px' }}>
                Current filter selections are returning fewer than the minimum number of respondents. Please expand the selection to include more respondents.
              </div>
            ) : (
              <table className="ccitextresponse-centered-table w-full border-collapse table-auto text-lg relative">
                <thead className="sticky top-0 bg-white z-10">
                  <tr>
                    <th className="border p-4">Response</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={index}>
                      <td className="border p-4">{item.Open_Response}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCITextResponseTable;
