import React from 'react';
import Chart from 'react-apexcharts';

const Viz01RoleClarity = () => {
  const optionsViz01 = {
    series: [
      {
        name: "Clarity",
        group: "Role Clarity",
        data: [44000, 55000, 41000, 67000, 22000],
      },
      {
        name: "Feedback",
        group: "Role Clarity",
        data: [48000, 50000, 40000, 65000, 25000],
      },
      {
        name: "Resources",
        group: "Role Clarity",
        data: [13000, 36000, 20000, 8000, 13000],
      },
      {
        name: "Work Outcomes",
        group: "Role Clarity",
        data: [20000, 40000, 25000, 10000, 12000],
      },
    ],
    colors: ["#004983"], // colors: ["#0000cd", "#ff8c00", "#008000", "#ff0000"],

    chart: {
      type: 'bar',
      height: '700px',
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: ["Clarity", "Feedback", "Resources", "Work Outcomes"],
    },

    plotOptions: {
      bar: {
        borderRadius: 2,
        borderRadiusApplication: 'end',
        horizontal: false,
        distributed: false,
        dataLabels: {
          position: 'top',
        },
      },
    },

    dataLabels: {
      enabled: true,
    },
  };

  return (
    <div id="rc-bar">
      <Chart
        options={optionsViz01}
        series={optionsViz01.series}
        type={optionsViz01.chart.type}
        height={optionsViz01.chart.height}
      />
    </div>
  );
};

export default Viz01RoleClarity;
