import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D02PieChart1 = ({ filterCriteria }) => {
  useEffect(() => {
    const filterData = (item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    };

    const filteredData = jsonData.filter(filterData);

    let favorable = 0;
    let neutral = 0;
    let unfavorable = 0;
    let noResponse = 0;

    filteredData.forEach((response) => {
      const { MQ0026, MQ0095, MQ0302, MQ0321 } = response;

      if (
        (MQ0026 === 4 || MQ0026 === 5) &&
        (MQ0095 === 4 || MQ0095 === 5) &&
        (MQ0302 === 4 || MQ0302 === 5) &&
        (MQ0321 === 4 || MQ0321 === 5)
      ) {
        favorable++;
      } else if (
        (MQ0026 === 3 || MQ0095 === 3 || MQ0302 === 3 || MQ0321 === 3) &&
        !(
          MQ0026 === 1 ||
          MQ0026 === 2 ||
          MQ0095 === 1 ||
          MQ0095 === 2 ||
          MQ0302 === 1 ||
          MQ0302 === 2 ||
          MQ0321 === 1 ||
          MQ0321 === 2
        )
      ) {
        neutral++;
      } else if (
        MQ0026 === 1 ||
        MQ0026 === 2 ||
        MQ0095 === 1 ||
        MQ0095 === 2 ||
        MQ0302 === 1 ||
        MQ0302 === 2 ||
        MQ0321 === 1 ||
        MQ0321 === 2
      ) {
        unfavorable++;
      } else {
        noResponse++;
      }
    });

    const optionsPie1 = {
      series: [favorable, neutral, unfavorable, noResponse],
      chart: {
        height: "550px",
        type: "pie",
        toolbar: {
          show: true,
        },
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      title: {
        text: 'Level of Engagement',
        align: 'Center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '20px',
          fontWeight:  'bold',
          fontFamily:  undefined,
          color:  '#263238'
        },
    },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -8,
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "16px",
        },
      },

      labels: ["Favorable", "Neutral", "Unfavorable", "No Response"],
      colors: ["#499894", "#f1ce63", "#f28e2b", "#bab0ac"],
      legend: {
        position: "bottom",
      },
    };

    const pie1 = new ApexCharts(document.querySelector("#pie-1"), optionsPie1);
    pie1.render();

    // Clean up the chart on component unmount
    return () => {
      pie1.destroy();
    };
  }, [filterCriteria]);

  return (
    <div>
      <div id="pie-1"></div>
    </div>
  );
};

export default D02PieChart1;
