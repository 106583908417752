import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const D02DonutChart4 = ({ filterCriteria }) => {
  const [favorableCount, setFavorableCount] = useState(0);
  const [neutralCount, setNeutralCount] = useState(0);
  const [unfavorableCount, setUnfavorableCount] = useState(0);
  const [noResponseCount, setNoResponseCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false); // State for controlling the message visibility

  useEffect(() => {
    // Filter the data based on filterCriteria
    const filterData = (item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true; // If no filter is selected for this key, include all items
        }
        return filterCriteria[key].includes(item[key]);
      });
    };

    const filteredData = jsonData.filter(filterData);

    let favorable = 0;
    let neutral = 0;
    let unfavorable = 0;
    let noResponse = 0;

    // Calculate the counts based on the MQ0321 field
    filteredData.forEach((response) => {
      const { MQ0321 } = response;

      if (MQ0321 === 4 || MQ0321 === 5) {
        favorable++;
      } else if (MQ0321 === 3) {
        neutral++;
      } else if (MQ0321 === 1 || MQ0321 === 2) {
        unfavorable++;
      } else {
        noResponse++;
      }
    });

    setFavorableCount(favorable);
    setNeutralCount(neutral);
    setUnfavorableCount(unfavorable);
    setNoResponseCount(noResponse);

    // Check the total respondents and show the message if necessary
    const totalRespondents = favorable + neutral + unfavorable + noResponse;

    if (totalRespondents < 4) {
      setShowMessage(true);
      return; // Stop rendering the chart if fewer than 4 respondents
    } else {
      setShowMessage(false);
    }

    // Prepare the data for the chart
    const chartData = [
      { name: "Favorable", value: favorable, color: "#499894" },
      { name: "Neutral", value: neutral, color: "#f1ce63" },
      { name: "Unfavorable", value: unfavorable, color: "#f28e2b" },
      { name: "No Response", value: noResponse, color: "#bab0ac" },
    ];

    const filteredChartData = chartData.filter(item => item.value > 0);

    const series = filteredChartData.map(item => item.value);
    const labels = filteredChartData.map(item => item.name);
    const colors = filteredChartData.map(item => item.color);

    const optionsDonut4 = {
      series: series,
      labels: labels,
      colors: colors,
      chart: {
        type: "donut",
        height: "300px",
        toolbar: {
          show: true,
        },
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 5,
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "16px",
        },
      },
    };

    const chartElement = document.querySelector("#donut-4");

    if (chartElement) {
      chartElement.innerHTML = ''; // Clear any existing chart content
    }

    const donut4 = new ApexCharts(chartElement, optionsDonut4);
    donut4.render();

    // Clean up the chart on component unmount
    return () => {
      donut4.destroy();
    };
  }, [filterCriteria, favorableCount, neutralCount, unfavorableCount, noResponseCount]);

  return (
    <div>
      {/* Show the message if fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}
      {/* Chart will only be displayed if there are enough respondents */}
      <div id="donut-4" style={{ display: showMessage ? "none" : "block" }}></div>
    </div>
  );
};

export default D02DonutChart4;
