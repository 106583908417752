import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const engagementGroups = {
  Recognition: ["MQ0053", "MQ0171", "MQ0103"],
  Autonomy: ["MQ0071", "MQ0202", "MQ0027"],
  Meaningfulness: ["MQ0023", "MQ0024", "MQ0026"],
  Growth: ["MQ0064", "MQ0062", "MQ0043"],
  Teamwork: ["MQ0340", "MQ0341", "MQ0094"],
};

const Heatmap3 = ({ selectedDemographic, filterCriteria }) => {
  const [chartData, setChartData] = useState({
    series: [],
    categories: [],
  });

  useEffect(() => {
    // Filter the data based on the filter criteria
    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        if (filterCriteria[key].length === 0) {
          return true;
        }
        return filterCriteria[key].includes(item[key]);
      });
    });

    // Process the JSON data
    const demographicValues = [
      ...new Set(filteredData.map((item) => item[selectedDemographic])),
    ].sort();

    const seriesData = Object.keys(engagementGroups).map((group) => {
      return {
        name: group,
        data: demographicValues.map((value) => {
          const responses = filteredData.filter(
            (item) => item[selectedDemographic] === value
          );
          const scores = responses.map((response) => {
            const totalScore = engagementGroups[group].reduce(
              (acc, question) => {
                return acc + (parseFloat(response[question]) || 0);
              },
              0
            );
            return totalScore / engagementGroups[group].length; // Average score for the group
          });
          const averageScore = scores.length
            ? scores.reduce((acc, score) => acc + score, 0) / scores.length
            : 0;
          return {
            x: value,
            y: averageScore.toFixed(1), // Keep the average score between 1 and 5
          };
        }),
      };
    });

    setChartData({
      series: seriesData,
      categories: demographicValues,
    });
  }, [selectedDemographic, filterCriteria]);

  const options3 = {
    series: chartData.series,
    chart: {
      height: "100%", // Ensure the chart takes full height of the container
      type: "heatmap",
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    title: {
      text: 'Job Satisfaction',
      align: 'Center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '20px',
        fontWeight:  'bold',
        fontFamily:  undefined,
        color:  '#263238'
      },
  },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            {
              from: 1,
              to: 1.99,
              color: "#e6ffe6", // Lightest shade
              name: "Very Low",
            },
            {
              from: 2,
              to: 2.99,
              color: "#b3ffb3", // Lighter shade
              name: "Low",
            },
            {
              from: 3,
              to: 3.99,
              color: "#66ff66", // Medium shade
              name: "Medium",
            },
            {
              from: 4,
              to: 4.99,
              color: "#33cc33", // Darker shade
              name: "High",
            },
            {
              from: 5,
              to: 5,
              color: "#008000", // Darkest shade
              name: "Very High",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000000"],
      },
      formatter: function (val) {
        return val.toFixed(1);
      },
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: ["#6d6d6d"],
          fontSize: "0.8rem",
        },
      },
    },
    colors: ["#008000"],
  };

  return (
    <div
      id="heat-03"
      style={{ height: "180%", width: "100%", position: "relative" }}
    >
      <Chart
        options={options3}
        series={chartData.series}
        type="heatmap"
        height="180%"
        width="100%"
      />
    </div>
  );
};

export default Heatmap3;
