import React from 'react';
import { useLocation } from 'react-router-dom';
import Refresh_img from '../Header/Refresh_img.png'; // Adjust the path accordingly
import hamburgerMenuImg from '../Sidebar3/HamburgerMenu_img.png'; // Import the hamburger menu image
import Filter_icon from '../Header/Filter_icon.png'; // Import the filter icon image
import './Header.css'; // Import the CSS file

const Header = ({ refreshPage, toggleFilterSidebar, toggleSidebar }) => {
  const location = useLocation();

  const getTitle = () => {
    switch (location.pathname) {
      case '/ccidemographs':
        return 'Survey Demographics';
      case '/signinillustration':
        return 'Signin Illustration';
      case '/landingpage':
        return 'Landing Page';
      case '/discover':
        return 'Discover';
      case '/compose':
        return 'Compose';
      case '/analyze':
      case '/analyze2':
      case '/analyze3':
      case '/analyze4':
        return 'Analyze';
      case '/ccitotalengage':
        return 'Overall Engagement';
      case '/CCIEngageSummary':
        return 'Engagement Summary';
      case '/ccidemoresponse':
        return 'Responses by Demographic Category';
      case '/ccidistrbution':
        return 'Response Distribution';
      case '/cciscoredetail':
        return 'Detailed Scores by Demographic Category';
      case '/cciresponserange':
        return 'Range of Responses';
      case '/cciselfengage':
        return 'Self Reported Engagement';
      case '/cciengagesorted':
        return 'Engagement Questions Sorted';
      case '/ccitextresponse':
        return 'Open Text Responses';
      case '/ccitextmap':
        return 'Open Text Heat Map';
      case '/cciimpactarea':
        return 'Engagement Areas by Impact';
      case '/ccisurveyspecific':
        return 'Survey Specific Questions';
      case '/cciyoyarea':
        return 'Year Over Year by Area';
      case '/ccitexttheme':
        return 'Open Text Top Themes';
      case '/generatereport':
        return 'Generate Report';
      default:
        return 'Page';
    }
  };

  const showFilterButton = location.pathname !== '/';

  return (
    <header className="header-container">
      <button onClick={() => {console.log('Sidebar toggle clicked'); toggleSidebar();}} className="toggle-sidebar-btn">
        <img src={hamburgerMenuImg} alt="Sidebar" className="sidebar-img" />
      </button>
      <h1 className="header-title">{getTitle()}</h1>
      <div className="header-buttons">
        {showFilterButton && (
          <button onClick={() => {console.log('Filter sidebar toggle clicked'); toggleFilterSidebar();}} className="toggle-filter-sidebar-btn">
            <img src={Filter_icon} alt="Filter" className="filter-icon-img" />
          </button>
        )}
        <button onClick={refreshPage} className="refresh-button">
          <img src={Refresh_img} alt="Refresh" />
        </button>
      </div>
    </header>
  );
};

export default Header;
