import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const TopCenterChart = ({ filterCriteria }) => {
  const [filteredScores, setFilteredScores] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [unfilteredScores, setUnfilteredScores] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [showMessage, setShowMessage] = useState(false); // State to control message visibility

  useEffect(() => {
    const processScores = (data) => {
      const scores = {
        NR: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      };

      data.forEach((item) => {
        const score = item.MQ0008;
        if (
          score === 1 ||
          score === 2 ||
          score === 3 ||
          score === 4 ||
          score === 5
        ) {
          scores[score]++;
        } else {
          scores.NR++;
        }
      });

      return scores;
    };

    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every(
        (key) =>
          filterCriteria[key].length === 0 ||
          filterCriteria[key].includes(item[key])
      );
    });

    // Show message if there are fewer than 4 respondents
    setShowMessage(filteredData.length < 4);

    // Process the data if there are enough respondents
    if (filteredData.length >= 4) {
      const newFilteredScores = processScores(filteredData);
      const newUnfilteredScores = processScores(jsonData);

      setFilteredScores(newFilteredScores);
      setUnfilteredScores(newUnfilteredScores);
    }
  }, [filterCriteria]);

  const labelToScoreKeyMap = {
    "Intensely Dislike": 1,
    "Dislike": 2,
    "Neither Like nor Dislike": 3,
    "Like my Job": 4,
    "Love my Job": 5,
    "NR": "NR",
  };

  // Calculate the total counts
  const totalFiltered = Object.values(filteredScores).reduce(
    (acc, curr) => acc + curr,
    0
  );
  const totalUnfiltered = Object.values(unfilteredScores).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const seriesData = Object.keys(filteredScores).map((key) => {
    const filteredPercentage =
      totalFiltered > 0 ? (filteredScores[key] / totalFiltered) * 100 : 0;
    const unfilteredPercentage =
      totalUnfiltered > 0 ? (unfilteredScores[key] / totalUnfiltered) * 100 : 0;

    return {
      x: key,
      y: filteredPercentage, // Use the filtered percentage for the y-axis
      goals: [
        {
          name: "Unfiltered",
          value: unfilteredPercentage, // Use unfiltered percentage for goals
          strokeHeight: 12,
          strokeColor: "#6D6D6D",
        },
      ],
    };
  });

  const options = {
    chart: {
      type: "bar",
      height: "300px",
    },
    colors: ["#F28E2B", "#F28E2B", "#F1CE63", "#499894", "#499894", "#BAB0AC"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        const xAxisLabel = opt.w.globals.labels[opt.dataPointIndex];
        const scoreKey = labelToScoreKeyMap[xAxisLabel];
        const filteredValue = filteredScores[scoreKey] || 0;
        const unfilteredValue = unfilteredScores[scoreKey] || 0;

        const filteredPercentage =
          totalFiltered > 0
            ? ((filteredValue / totalFiltered) * 100).toFixed(1)
            : "0.00";
        const unfilteredPercentage =
          totalUnfiltered > 0
            ? ((unfilteredValue / totalUnfiltered) * 100).toFixed(1)
            : "0.00";

        return `${filteredPercentage}% | ${unfilteredPercentage}%`; // Return formatted data label
      },
      style: {
        colors: ["#000"], // Set data labels to black
      },
      offsetX: 45, // Move labels off the bars
    },
    xaxis: {
      categories: [
        "Intensely Dislike",
        "Dislike",
        "Neither Like nor Dislike",
        "Like my Job",
        "Love my Job",
        "NR",
      ],
      title: {
        text: "% of Responses", // Add title to the y-axis
        style: {
          fontSize: "12px",
          fontWeight: "normal",
          color: "#000",
        },
      },
    },
    yaxis: {
      max: 100, // Set maximum y-axis value to 100%
      labels: {
        formatter: (val) => {
          return typeof val === "number" ? `${val.toFixed(0)}` : val; // Ensure val is a number
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const xAxisLabel = w.globals.labels[dataPointIndex];
        const scoreKey = labelToScoreKeyMap[xAxisLabel];
        const filteredValue = filteredScores[scoreKey] || 0;
        const unfilteredValue = unfilteredScores[scoreKey] || 0;

        const filteredPercentage =
          totalFiltered > 0
            ? ((filteredValue / totalFiltered) * 100).toFixed(1)
            : "0.00";
        const unfilteredPercentage =
          totalUnfiltered > 0
            ? ((unfilteredValue / totalUnfiltered) * 100).toFixed(1)
            : "0.00";

        return `<div class="apexcharts-tooltip-text">
                  <div id="tooltip-title">Response:&nbsp; ${xAxisLabel}</div>  
                  <ul>
                  <li>Filtered: &nbsp; ${filteredPercentage}%</li>
                  <li>Unfiltered: &nbsp; ${unfilteredPercentage}%</li>
                  </ul>
                </div>`;
      },
    },
    annotations: {
      points: Object.keys(unfilteredScores).map((key, index) => {
        return {
          x: key,
          y: unfilteredScores[key],
          marker: {
            size: 5, // Make the marker thicker
            fillColor: "#fff",
            strokeColor: "#fff",
          },
          label: {
            borderColor: "#fff",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#fff",
            },
            text: `${unfilteredScores[key]}`,
          },
        };
      }),
    },
    legend: {
      show: false,
      labels: {
        useSeriesColors: true,
      },
    },
  };

  return (
    <div>
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}

      {/* Chart will only be displayed if there are enough respondents */}
      {!showMessage && (
        <div id="d08-top-ctr">
          <Chart
            options={options}
            series={[{ name: "Filtered", data: seriesData }]} // Set the name for the filtered data
            type="bar"
            height="300px"
          />
        </div>
      )}
    </div>
  );
};

export default TopCenterChart;
