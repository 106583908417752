import React, { useEffect } from 'react';
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';


const Login = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === "authenticated") {
      navigate("/ccidemographs");
    }
  }, [authStatus, navigate]);

  return (
    <div className="App">
      <div id="login-page-title">
        <h1>Career Concepts &#183; Employee Engagement</h1>
      </div>
      <div id="login-img-container">
        <img
          id="login-image"
          src="/images/pexels-thirdman-8485714.jpg"
          alt="woman holding documents"
        />
      </div>

      <div id="login-logo">
        <img src="/images/CCI-consulting-logo-white_250px.png" alt="CCI logo" />
      </div>

      <div className="custom-shape-divider-bottom-1710522688">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div id="auth-container">
        <Authenticator hideSignUp={true} />
      </div>
    </div>
  );
}

export default Login;
