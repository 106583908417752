import React, { useState, useEffect, useCallback } from 'react';
import './CCIDemoResponseDashboard.css';
import NavigationSidebar from '../components/Navigationsidebar/NavigationSidebar';
import Header from '../components/Header';
import StackedBarChart from '../components/Dashboardviz/D04/DB04Viz1'; // Ensure the path is correct
import MultipleBarCharts from '../components/Dashboardviz/D04/DB04Viz2'; // Ensure the path is correct
import FilterSidebar from '../components/FilterSidebar/FilterSidebar'; // Ensure the path is correct
import { useFilter } from '../FilterContext'; // Ensure the path is correct
import jsonData from '../som_2024-sm-data-numeric.json'; // Ensure the path is correct
import Select from 'react-select';

const CCIDemoResponseDashboard = () => {
  const { filterCriteria, setFilterCriteria } = useFilter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState('MQ0026');
  const [selectedDemographic, setSelectedDemographic] = useState('generation_name');
  const [expandedCards, setExpandedCards] = useState([]);
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });

  useEffect(() => {
    const uniqueLocations = getUniqueFieldValues('location_name');
    const uniqueBusinessUnits = getUniqueFieldValues('business_unit_name');
    const uniqueDepartments = getUniqueFieldValues('department_name');
    const uniqueJobClassifications = getUniqueFieldValues('job_classification_name');
    const uniqueTenures = getSortedFieldValues('length_of_service_name', [
      'Less than 1 year',
      '1 to 2 years',
      '3 to 5 years',
      '6 to 10 years',
      '11 to 19 years',
      '20 years or more'
    ]);
    const uniqueGenerations = getSortedFieldValues('generation_name', [
      'Traditionalist',
      'Baby Boomer',
      'Generation X',
      'Millennial',
      'Generation Z',
      'Generation Next',
      'NR'
    ]);
    const uniqueExemptStatuses = getUniqueFieldValues('exempt_status_name');
    const uniqueGenders = getUniqueFieldValues('gender_name');
    const uniqueRaces = getUniqueFieldValues('race_name');
    const uniqueSupervisors = getUniqueFieldValues('additional_demographic_01_name');

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prevState => !prevState);
  }, []);

  const toggleFilterSidebar = useCallback(() => {
    setIsFilterSidebarOpen(prevState => !prevState);
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  const handleFilterChange = (selectedOptions, actionMeta) => {
    const { name } = actionMeta;
    const allOptions = filterData[name];
    if (selectedOptions && selectedOptions.some(option => option.value === 'select-all')) {
      if (selectedOptions.length === allOptions.length + 1) {
        setFilterCriteria(prevState => ({
          ...prevState,
          [name]: [],
        }));
      } else {
        setFilterCriteria(prevState => ({
          ...prevState,
          [name]: allOptions.map(option => option.value),
        }));
      }
    } else {
      setFilterCriteria(prevState => ({
        ...prevState,
        [name]: selectedOptions ? selectedOptions.map(option => option.value) : [],
      }));
    }
  };

  const handleDemographicChange = selectedOption => {
    setSelectedDemographic(selectedOption.value);
  };

  const handleQuestionChange = selectedOption => {
    setSelectedQuestion(selectedOption.value);
  };

  const toggleCardExpansion = index => {
    setExpandedCards(prevState =>
      prevState.includes(index) ? prevState.filter(i => i !== index) : [...prevState, index]
    );
  };

  const getUniqueFieldValues = (fieldName) => {
    return [...new Set(jsonData.map(item => item[fieldName]))].sort();
  };

  const getSortedFieldValues = (fieldName, order) => {
    return [...new Set(jsonData.map(item => item[fieldName]))].sort((a, b) => order.indexOf(a) - order.indexOf(b));
  };

  const demographicOptions = [
    { value: 'business_unit_name', label: 'Location 2' },  
    { value: 'department_name', label: 'Department' },
    { value: 'exempt_status_name', label: 'Work Status' }, 
    { value: 'generation_name', label: 'Generation' },
    { value: 'length_of_service_name', label: 'Tenure' },
    { value: 'gender_name', label: 'Gender' },
    { value: 'race_name', label: 'Ethnicity' },
  ];

  const questionOptions = [
    { value: 'MQ0044', label: 'My job responsibilities are clear to me.' },
    { value: 'MQ0047', label: 'I am given realistic and achievable goals and objectives.' },
    { value: 'MQ0017', label: 'I know what my Manager/Supervisor expects of me.' },
    { value: 'MQ0048', label: 'I have the tools and resources to do my job effectively.' },
    { value: 'MQ0051', label: 'I am given sufficient opportunity to improve the skills I use in my current job.' },
    { value: 'MQ0050', label: 'When moved to a new position or given a new assignment, I am given sufficient training/support to do the new job properly.' },
    { value: 'MQ0095', label: 'I understand how my work directly contributes to the success of the organization.' },
    { value: 'MQ0016', label: 'I understand my department‘s role and how it supports the company’s overall objectives.' },
    { value: 'MQ0059', label: 'My Manager/Supervisor clearly defines performance goals and expectations.' },
    { value: 'MQ0104', label: 'I receive useful and constructive feedback from my Manager or Supervisor.' },
    { value: 'MQ0173', label: 'Feedback from my Manager/Supervisor helps me to improve my performance.' },
    { value: 'MQ0054', label: 'I am given feedback by my Manager/Supervisor on a regular and timely basis.' },
    { value: 'MQ0190', label: 'This company has a safe working environment or I feel safe at work.' },
    { value: 'MQ0141', label: 'This company is committed to the health and safety of all employees.' },
    { value: 'MQ0343', label: 'This organization demonstrates its commitment to providing an environment that does not tolerate harassment or discrimination.' },
    { value: 'MQ0088', label: 'My compensation/salary is competitive with similar jobs elsewhere.' },
    { value: 'MQ0090', label: 'I believe our benefits are comparable to those offered by other companies.' },
    { value: 'MQ0091', label: 'Overall, I am compensated fairly for the work I do.' },
    { value: 'MQ0086', label: 'The Senior Leadership Team acts with integrity.' },
    { value: 'MQ0034', label: 'The Senior Leadership Team is trustworthy.' },
    { value: "MQ0041", label: "The Senior Leadership is 'in touch' or connected to its workers." }, // Use double quotes to avoid conflict
    { value: 'MQ0194', label: 'The company provides an environment for the free and open expression of ideas, opinions, and beliefs.' },
    { value: 'MQ0321', label: 'I am comfortable asking questions and sharing thoughts, concerns and ideas with management.' },
    { value: 'MQ0058', label: 'My Manager/Supervisor values my input and is open to hearing my ideas and suggestions.' },
    { value: 'MQ0056', label: 'My Manager/Supervisor is open, consistent, and honest.' },
    { value: 'MQ0057', label: 'My Manager/Supervisor is engaged and responsive.' },
    { value: 'MQ0038', label: 'My Manager/Supervisor is an effective leader.' },
    { value: 'MQ0053', label: 'I get praise and recognition when I do a good job.' },
    { value: 'MQ0171', label: 'Good performance is rewarded with recognition.' },
    { value: 'MQ0103', label: 'Recognition is effectively used to motivate employees.' },
    { value: 'MQ0071', label: 'My ideas and opinions matter.' },
    { value: 'MQ0202', label: 'I have an appropriate level of autonomy in how I accomplish tasks.' },
    { value: 'MQ0027', label: 'I feel empowered to do the right thing to meet company objectives.' },
    { value: 'MQ0023', label: 'Our products and/or services provide great value to our customers.' },
    { value: 'MQ0024', label: 'I feel that my efforts contribute to the success of this company.' },
    { value: 'MQ0026', label: 'My job and the work I do give me a personal sense of accomplishment.' },
    { value: 'MQ0064', label: 'I have opportunities for career development.' },
    { value: 'MQ0043', label: 'This is a good company for career growth and development.' },
    { value: 'MQ0062', label: 'My Manager/Supervisor supports me when I express interest in career development.' },
    { value: 'MQ0340', label: 'There is a strong spirit of teamwork and cooperation amongst the team.' },
    { value: 'MQ0341', label: 'In this organization, it feels like everyone is on the same team and working towards common goals.' },
    { value: 'MQ0094', label: 'Collaboration and teamwork are encouraged.' },
    { value: 'MQ0021', label: 'I am proud to tell others that I am part of this company.' },
    { value: 'MQ0080', label: 'I feel a strong sense of belonging to this company.' },
    { value: 'MQ0302', label: 'I would recommend this company as a good place to work.' },
    { value: 'MQ0072', label: 'I understand the company’s strategy and guiding principles.' },
    { value: 'MQ0029', label: 'Trust, respect, and integrity are evident in the way employees are treated at all levels in the company.' },
    { value: 'MQ0012', label: 'I feel confident that the company has positioned itself well to achieve future performance.' },
    { value: 'MQ0331', label: 'This company has a diverse workforce.' },
    { value: 'MQ0311', label: 'Through its actions, Management shows they value diversity.' },
    { value: 'MQ0312', label: 'Within this organization, people are treated respectfully regardless of their differences.' },
    { value: 'MQ0320', label: 'There is ample opportunity for me to ask questions and share thoughts, concerns, ideas with the Management Team.' },
    { value: 'MQ0046', label: 'There is an atmosphere of trust at this organization.' },
    { value: 'MQ0100', label: 'Within this organization, important information is readily shared and available.' }
  ];
  

  return (
    <div className="ccidemographs-dashboard-container">
      <Header toggleSidebar={toggleSidebar} toggleFilterSidebar={toggleFilterSidebar} refreshPage={refreshPage} />
      <NavigationSidebar 
        className={isSidebarOpen ? 'ccidemographics-sidebar-open' : 'ccidemographics-sidebar-closed'} 
        isOpen={isSidebarOpen} 
      />
      <FilterSidebar
        className={isFilterSidebarOpen ? 'ccidemographics-filter-sidebar-open' : 'ccidemographics-filter-sidebar-closed'}
        isOpen={isFilterSidebarOpen}
        filterCriteria={filterCriteria}
        locations={filterData.locations}
        businessUnits={filterData.businessUnits}
        departments={filterData.departments}
        jobClassifications={filterData.jobClassifications}
        tenures={filterData.tenures}
        generations={filterData.generations}
        exemptStatuses={filterData.exemptStatuses}
        genders={filterData.genders}
        races={filterData.races}
        supervisors={filterData.supervisors}
        handleFilterChange={handleFilterChange}
      />
      <div className="ccidemographs-main-content">
        <div className="ccidemographs-grid-container">
          <div className="ccidemographs-dropdown">
            <h2>Select Demographic Category</h2>
            <Select
              options={demographicOptions}
              value={demographicOptions.find(option => option.value === selectedDemographic)}
              onChange={handleDemographicChange}
            />
          </div>
          <div className="ccidemographs-dropdown">
            <h2>Select Question</h2>
            <Select
              options={questionOptions}
              value={questionOptions.find(option => option.value === selectedQuestion)}
              onChange={handleQuestionChange}
            />
          </div>
          <div className={`ccidemographs-card ${expandedCards.includes(0) ? 'expanded' : ''}`} onClick={() => toggleCardExpansion(0)}>
            <h2 className="ccidemographs-card-header">Percentages within each Demographic Category</h2>
            <div className="ccidemographs-card-content">
              <div className="scrollable-content">
                <StackedBarChart 
                  selectedQuestion={selectedQuestion} 
                  selectedDemographic={selectedDemographic} 
                  filterCriteria={filterCriteria} 
                />
              </div>
            </div>
          </div>
          <div className={`ccidemographs-card ${expandedCards.includes(1) ? 'expanded' : ''}`} onClick={() => toggleCardExpansion(1)}>
            <h2 className="ccidemographs-card-header">Percentages within each Favorability Group</h2>
            <div className="ccidemographs-card-content">
              <div className="scrollable-content">
                <MultipleBarCharts 
                  selectedQuestion={selectedQuestion} 
                  selectedDemographic={selectedDemographic} 
                  filterCriteria={filterCriteria} 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCIDemoResponseDashboard;
