import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useFilter } from "../../../../FilterContext"; // Ensure the path is correct
import jsonData from "../../../../som_2024-sm-data-numeric.json";

const D03HorizontalBarCharts = () => {
  const { filterCriteria } = useFilter();

  const [filteredScores1, setFilteredScores1] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [unfilteredScores1, setUnfilteredScores1] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [filteredScores2, setFilteredScores2] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [unfilteredScores2, setUnfilteredScores2] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  useEffect(() => {
    const processScores = (data, question) => {
      const scores = {
        NR: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      };

      data.forEach((item) => {
        const score = item[question];
        if (
          score === 1 ||
          score === 2 ||
          score === 3 ||
          score === 4 ||
          score === 5
        ) {
          scores[score]++;
        } else {
          scores.NR++;
        }
      });

      return scores;
    };

    if (!jsonData || !Array.isArray(jsonData)) {
      console.error("jsonData is not loaded correctly or is not an array.");
      return;
    }

    if (!filterCriteria || typeof filterCriteria !== "object") {
      console.error("filterCriteria is not an object.");
      return;
    }

    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        const criteria = filterCriteria[key];
        if (Array.isArray(criteria)) {
          return criteria.length === 0 || criteria.includes(item[key]);
        }
        return criteria === "" || item[key] === criteria;
      });
    });

    console.log("Filtered Data:", filteredData); // Debugging: Check filtered data

    const newFilteredScores1 = processScores(filteredData, "MQ0299");
    const newUnfilteredScores1 = processScores(jsonData, "MQ0299");

    console.log("Filtered Scores for MQ0299:", newFilteredScores1); // Debugging: Check processed scores
    console.log("Unfiltered Scores for MQ0299:", newUnfilteredScores1); // Debugging: Check processed scores

    setFilteredScores1(newFilteredScores1);
    setUnfilteredScores1(newUnfilteredScores1);

    const newFilteredScores2 = processScores(filteredData, "MQ0301");
    const newUnfilteredScores2 = processScores(jsonData, "MQ0301");

    console.log("Filtered Scores for MQ0301:", newFilteredScores2); // Debugging: Check processed scores
    console.log("Unfiltered Scores for MQ0301:", newUnfilteredScores2); // Debugging: Check processed scores

    setFilteredScores2(newFilteredScores2);
    setUnfilteredScores2(newUnfilteredScores2);
  }, [filterCriteria]);

  const createSeriesData = (filteredScores, unfilteredScores) => {
    return Object.keys(filteredScores).map((key) => {
      return {
        x: key,
        y: filteredScores[key],
        goals: [
          {
            name: "Unfiltered",
            value: unfilteredScores[key],
            strokeWidth: 5,
            strokeHeight: 10,
            strokeLineCap: "round",
            strokeColor: "#888888",
          },
        ],
      };
    });
  };

  const seriesData1 = createSeriesData(filteredScores1, unfilteredScores1);
  const seriesData2 = createSeriesData(filteredScores2, unfilteredScores2);

  const options = {
    chart: {
      type: "bar",
      height: "225px",
    },
    colors: ["#BAB0AC", "#F28E2B", "#F28E2B", "#F1CE63", "#499894", "#499894"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 70,
      textAnchor: "end",
      style: {
        colors: ["#000"],
      },

      formatter: function (val, opt) {
        const goals =
          opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;
        if (goals && goals.length) {
          return `${parseFloat(val).toFixed(0)} / ${parseFloat(
            goals[0].value
          ).toFixed(0)}`;
        }
        return parseFloat(val).toFixed(0);
      },
    },
    xaxis: {
      categories: ["NR", "1", "2", "3", "4", "5"],
    },
    tooltip: {
      enabled: true,
      y: {
        title: "Score",
      },
    },
    legend: {
      show: false,
    },
    annotations: {
      points: Object.keys(unfilteredScores1).map((key, index) => {
        return {
          x: key,
          y: unfilteredScores1[key],
          marker: {
            size: 0,
            fillColor: "#888888",
            strokeColor: "#888888",
          },
          label: {
            borderColor: "#888888",
            offsetY: 15,
            style: {
              color: "#fff",
              background: "#888888",
            },
            // text: `${unfilteredScores1[key]}`,
            // text: "Unfiltered Counts",
          },
        };
      }),
    },
  };

  return (
    <div>
      <p>Self Reported Engagement</p>
      <div id="sre-bar">
        <Chart
          options={options}
          series={[{ name: "Filtered", data: seriesData1 }]}
          type="bar"
          height="225px"
          width="700px"
        />
      </div>
      <p>Self Reported Retention</p>
      <div id="srr-bar">
        <Chart
          options={options}
          series={[{ name: "Filtered", data: seriesData2 }]}
          type="bar"
          height="225px"
        />
      </div>
    </div>
  );
};

export default D03HorizontalBarCharts;
