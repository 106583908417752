import React, { useEffect, useState } from "react";
import "../../../../pages/CCIDistributionDashboard.css"; // Ensure this path matches your project structure

const OrganizationalCommitmentDistributionTable = ({ data, filterCriteria = {} }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility

  useEffect(() => {
    const applyFilterCriteria = (data, filterCriteria) => {
      return data.filter((item) => {
        return Object.keys(filterCriteria).every((key) => {
          const isCriteriaEmpty = !filterCriteria[key] || filterCriteria[key].length === 0;
          const doesItemMatchCriteria = filterCriteria[key]?.includes(item[key]);
          return isCriteriaEmpty || doesItemMatchCriteria;
        });
      });
    };

    const newFilteredData = applyFilterCriteria(data, filterCriteria);
    setFilteredData(newFilteredData);

    // Show message if there are fewer than 4 respondents
    setShowMessage(newFilteredData.length < 4);

  }, [data, filterCriteria]);

  console.log("Filtered Data:", filteredData);

  if (!filteredData || !Array.isArray(filteredData) || filteredData.length === 0) {
    return <div>No data available</div>;
  }

  // Define key mappings for Organizational Commitment engagement areas
  const keyMappings = {
    Belonging: ["MQ0021", "MQ0080", "MQ0302"],
    Inclusion: ["MQ0311", "MQ0312", "MQ0331"],
    Openness: ["MQ0320", "MQ0046", "MQ0100"],
    VisionAndValues: ["MQ0072", "MQ0029", "MQ0012"],
  };

  // Function to calculate percentage
  const calculatePercentage = (value, total) => {
    return total > 0 ? ((value / total) * 100).toFixed(1) : "0.0";
  };

  // Function to calculate response distribution for each group
  const calculateResponseDistribution = (group) => {
    const keys = keyMappings[group];
    if (!keys) return { NR: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

    const responses = filteredData.flatMap((item) => keys.map((key) => item[key]));

    console.log(`Responses for ${group}:`, responses);

    const distribution = { NR: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

    let validResponses = 0;

    responses.forEach((response) => {
      if (response === null) {
        distribution.NR++;
      } else if (response >= 1 && response <= 5) {
        distribution[response]++;
        validResponses++;
      }
    });

    return {
      NR: calculatePercentage(distribution.NR, responses.length),
      1: calculatePercentage(distribution[1], validResponses),
      2: calculatePercentage(distribution[2], validResponses),
      3: calculatePercentage(distribution[3], validResponses),
      4: calculatePercentage(distribution[4], validResponses),
      5: calculatePercentage(distribution[5], validResponses),
    };
  };

  return (
    <div>
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}

      {/* Table will only be displayed if there are enough respondents */}
      {!showMessage && (
        <div className="ccidistribution-table">
          <div className="table-header">
            <div className="table-column">Area</div>
            <div className="table-column">NR</div>
            <div className="table-column">1</div>
            <div className="table-column">2</div>
            <div className="table-column">3</div>
            <div className="table-column">4</div>
            <div className="table-column">5</div>
          </div>
          {Object.keys(keyMappings).map((group) => {
            const distribution = calculateResponseDistribution(group);
            return (
              <div className="table-row" key={group}>
                <div className="table-column">{group}</div>
                <div className="table-column">{distribution.NR}%</div>
                <div className="table-column">{distribution[1]}%</div>
                <div className="table-column">{distribution[2]}%</div>
                <div className="table-column">{distribution[3]}%</div>
                <div className="table-column">{distribution[4]}%</div>
                <div className="table-column">{distribution[5]}%</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OrganizationalCommitmentDistributionTable;
