import React, { useState, useEffect, useCallback } from "react";
import NavigationSidebar from "../components/Navigationsidebar/NavigationSidebar";
import Header from "../components/Header";
import BarChartViz01 from "../components/Dashboardviz/D08/D08Viz01";
import BarChartViz02 from "../components/Dashboardviz/D08/D08Viz02";
import BarChartViz03 from "../components/Dashboardviz/D08/D08Viz03";
import BarChartViz04 from "../components/Dashboardviz/D08/D08Viz04";
import BarChartViz05 from "../components/Dashboardviz/D08/D08Viz05";
import BarChartViz06 from "../components/Dashboardviz/D08/D08Viz06";
import FilterSidebar from "../components/FilterSidebar/FilterSidebar";
import { useFilter } from "../FilterContext";
import jsonData from "../som_2024-sm-data-numeric.json"; // Ensure the path is correct
import "./CCISelfEngageDashboard.css"; // Import the CSS file

const Dashboard = () => {
  const { filterCriteria, setFilterCriteria } = useFilter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Default state is now closed
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });

  useEffect(() => {
    const uniqueLocations = [
      ...new Set(jsonData.map((item) => item.location_name)),
    ].sort();
    const uniqueBusinessUnits = [
      ...new Set(jsonData.map((item) => item.business_unit_name)),
    ].sort();
    const uniqueDepartments = [
      ...new Set(jsonData.map((item) => item.department_name)),
    ].sort();
    const uniqueJobClassifications = [
      ...new Set(jsonData.map((item) => item.job_classification_name)),
    ].sort();

    const tenureOrder = [
      "Less than 1 year",
      "1 to 2 years",
      "2 to 5 years",
      "5 to 10 years",
      "11 to 15 years",
      "16 to 20 years",
      "20+ years",
    ];
    const uniqueTenures = [
      ...new Set(jsonData.map((item) => item.length_of_service_name)),
    ].sort((a, b) => {
      return tenureOrder.indexOf(a) - tenureOrder.indexOf(b);
    });

    const generationOrder = [
      "Traditionalists",
      "Baby Boomers",
      "Generation X",
      "Millennials",
      "Generation Z",
      "Generation Next",
      "NR",
    ];
    const uniqueGenerations = [
      ...new Set(jsonData.map((item) => item.generation_name)),
    ].sort((a, b) => {
      return generationOrder.indexOf(a) - generationOrder.indexOf(b);
    });

    const uniqueExemptStatuses = [
      ...new Set(jsonData.map((item) => item.exempt_status_name)),
    ].sort();
    const uniqueGenders = [
      ...new Set(jsonData.map((item) => item.gender_name)),
    ].sort();
    const uniqueRaces = [
      ...new Set(jsonData.map((item) => item.race_name)),
    ].sort();
    const uniqueSupervisors = [
      ...new Set(jsonData.map((item) => item.additional_demographic_01_name)),
    ].sort();

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  const toggleSidebar = useCallback(() => {
    console.log("Toggling sidebar visibility");
    setIsSidebarOpen((prevState) => !prevState);
    console.log("Sidebar state:", !isSidebarOpen);
  }, [isSidebarOpen]);

  const toggleFilterSidebar = useCallback(() => {
    console.log("Toggling filter sidebar visibility");
    setIsFilterSidebarOpen((prevState) => !prevState);
    console.log("Filter sidebar state:", !isFilterSidebarOpen);
  }, [isFilterSidebarOpen]);

  const refreshPage = useCallback(() => {
    console.log("Refreshing page");
    window.location.reload();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const chartComponents = [
    {
      component: <BarChartViz01 filterCriteria={filterCriteria} />,
      title: "Type of Work",
    },
    {
      component: <BarChartViz02 filterCriteria={filterCriteria} />,
      title: "Current Job",
    },
    {
      component: <BarChartViz06 filterCriteria={filterCriteria} />,
      title: "Questions",
    },
    {
      component: <BarChartViz03 filterCriteria={filterCriteria} />,
      title: "Our Company Compared to Others",
    },
    {
      component: <BarChartViz04 filterCriteria={filterCriteria} />,
      title: "Self Reported Retention",
    },
    {
      component: <BarChartViz05 filterCriteria={filterCriteria} />,
      title: "Self Reported Engagement",
    },
  ];

  return (
    <div className="cciselfengage-dashboard-container">
      <Header
        title="Dashboard Title"
        toggleSidebar={toggleSidebar}
        toggleFilterSidebar={toggleFilterSidebar}
        refreshPage={refreshPage}
      />
      <NavigationSidebar
        className={
          isSidebarOpen
            ? "cciselfengage-navigation-sidebar-open"
            : "cciselfengage-navigation-sidebar-closed"
        }
        isOpen={isSidebarOpen}
      />
      <FilterSidebar
        className={
          isFilterSidebarOpen
            ? "cciselfengage-filter-sidebar-open"
            : "cciselfengage-filter-sidebar-closed"
        }
        isOpen={isFilterSidebarOpen}
        filterCriteria={filterCriteria}
        locations={filterData.locations}
        businessUnits={filterData.businessUnits}
        departments={filterData.departments}
        jobClassifications={filterData.jobClassifications}
        tenures={filterData.tenures}
        generations={filterData.generations}
        exemptStatuses={filterData.exemptStatuses}
        genders={filterData.genders}
        races={filterData.races}
        supervisors={filterData.supervisors}
        handleFilterChange={handleFilterChange}
      />
      <div className="cciselfengage-main-content">
        <div className="cciselfengage-charts-container">
          {chartComponents.map((chart, index) => (
            <div key={index} className="cciselfengage-chart">
              <h2 className="cciselfengage-chart-title">{chart.title}</h2>
              <div className="cciselfengage-chart-container">
                {chart.component}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
