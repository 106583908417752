// TextBoxComponent.jsx
import React from "react";

const TextBoxComponent = () => {
  return (
    <div className="text-box-container">
      <div className="text-box">
        <ul>
          <li>
            <span class="Q-bold">Type of Work: </span> How do you feel about the
            type of work you do?
          </li>
          <li>
            <span class="Q-bold">Current Job: </span>Overall, how do you feel
            about your current job?
          </li>
          <li>
            <span class="Q-bold">Our Organization Compared to Others: </span>{" "}
            Compared with other organizations, how you rate Sisters of Mercy
            overall as a place to work?
          </li>
          <li>
            <span class="Q-bold">Self-Reported Retention: </span> I intend to
            still be working for the Sisters of Mercy in 12 months.
          </li>
          <li>
            <span class="Q-bold">Self-Reported Engagement: </span> Highly
            engaged employees go 'above and beyond' the effort outlined in their
            job description. How well do you believe you fit this definition?
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TextBoxComponent;
