import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const StackedBarChart04 = ({ filterCriteria }) => {
  const [seriesData, setSeriesData] = useState({
    Unfavorable: [],
    Neutral: [],
    Favorable: [],
  });

  useEffect(() => {
    const questions = {
      "Belonging": ["MQ0021", "MQ0080", "MQ0302"],
      "Inclusion": ["MQ0311", "MQ0312", "MQ0331"],
      "Openness": ["MQ0320", "MQ0046", "MQ0100"],
      "Vision and Values": ["MQ0072", "MQ0029", "MQ0012"],
    };

    const processResponses = (data, questions) => {
      const responseCounts = {
        Unfavorable: [],
        Neutral: [],
        Favorable: [],
      };

      Object.keys(questions).forEach((category) => {
        let unfavorable = 0;
        let neutral = 0;
        let favorable = 0;
        let totalResponses = 0;

        data.forEach((item) => {
          questions[category].forEach((question) => {
            const response = item[question];
            if (response === 1 || response === 2) {
              unfavorable++;
            } else if (response === 3) {
              neutral++;
            } else if (response === 4 || response === 5) {
              favorable++;
            }
            if (response !== null && response !== undefined) {
              totalResponses++;
            }
          });
        });

        responseCounts.Unfavorable.push(
          totalResponses
            ? ((unfavorable / totalResponses) * 100).toFixed(1)
            : "0.0"
        );
        responseCounts.Neutral.push(
          totalResponses ? ((neutral / totalResponses) * 100).toFixed(1) : "0.0"
        );
        responseCounts.Favorable.push(
          totalResponses
            ? ((favorable / totalResponses) * 100).toFixed(1)
            : "0.0"
        );
      });

      return responseCounts;
    };

    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        const isCriteriaEmpty =
          !filterCriteria[key] || filterCriteria[key].length === 0;
        const doesItemMatchCriteria = filterCriteria[key]?.includes(item[key]);
        return isCriteriaEmpty || doesItemMatchCriteria;
      });
    });

    console.log("Filter Criteria:", filterCriteria);
    console.log("Filtered Data:", filteredData); // Log filtered data to check

    if (filteredData.length === 0) {
      console.warn("No data matches the filter criteria.");
    }

    const newSeriesData = processResponses(filteredData, questions);

    console.log("Processed Response Counts:", newSeriesData); // Log processed data to check

    setSeriesData(newSeriesData);
  }, [filterCriteria]);

  const options_st04 = {
    series: [
      {
        name: "Unfavorable",
        data: seriesData.Unfavorable,
      },
      {
        name: "Neutral",
        data: seriesData.Neutral,
      },
      {
        name: "Favorable",
        data: seriesData.Favorable,
      },
    ],
    colors: ["#f28e2b", "#f1ce63", "#499894"],
    chart: {
      type: "bar",
      height: "100%",
      stacked: true,
      stackType: "100%",
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    title: {
      text: 'Organizational Commitment',
      align: 'Center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '20px',
        fontWeight:  'bold',
        fontFamily:  undefined,
        color:  '#263238'
      },
  },
    xaxis: {
      categories: ["Belonging", "Inclusion", "Openness", "Vision and Values"],
    },
    yaxis: {
      labels: {
        align: "center",
        style: {
          fontSize: "0.9rem",
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
      },
      formatter: function (val) {
        return val.toFixed(1) + "%";
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toFixed(1) + "%";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "center",
      offsetX: 60,
    },
  };

  return (
    <div id="st-04">
      <Chart
        options={options_st04}
        series={options_st04.series}
        type="bar"
        height="180%"
        width="700px"
      />
    </div> //Sets height of stacked bar chart
  );
};

export default StackedBarChart04;
