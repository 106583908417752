import React, { useState, useEffect, useCallback } from 'react';
import { useFilter } from '../FilterContext'; // Import useFilter to access filter criteria
import NavigationSidebar from '../components/Navigationsidebar/NavigationSidebar';
import Header from '../components/Header';
import FilterSidebar from '../components/FilterSidebar/FilterSidebar';
import ApexCharts from 'react-apexcharts';
import rawData from '../Open-Text-Theme-Processing (1).json';
import Select from 'react-select';
import './CCITextMapDashboard.css';

const xAxisOptions = [
  { value: 'business_unit_name', label: 'Location 2' },
  { value: 'department_name', label: 'Department' },
  { value: 'exempt_status_name', label: 'Work Status' },
  { value: 'generation_name', label: 'Generation' },
  { value: 'length_of_service_name', label: 'Tenure' },
  { value: 'gender_name', label: 'Gender' },
  { value: 'race_name', label: 'Ethnicity' },
];

const qNumOptions = [
  { value: 'MQ0305', label: 'What Makes this Company a Great Place to Work' },
  { value: 'MQ0156', label: 'What Would Make this Place a Better Place to Work' },
  { value: 'MQ0160', label: 'What Would you Like to Tell the Senior Leadership Team' },
  { value: 'SS_SOM_0016', label: 'For non-essential workers, please share your thoughts on the current work from home policy.' },
];

const lengthOfServiceOrder = [
  'Less than 1 year',
  '1 to 2 years',
  '3 to 5 years',
  '6 to 10 years',
  '11 to 19 years',
  '20 years or more',
];

const generationOrder = [
  'Traditionalist',
  'Baby Boomer',
  'Generation X',
  'Millennial',
  'Generation Z',
  'No Response',
];

const CCITextMap = () => {
  const [heatmapData, setHeatmapData] = useState([]);
  const [selectedXAxis, setSelectedXAxis] = useState(xAxisOptions[4]);
  const [selectedQNum, setSelectedQNum] = useState(qNumOptions[0]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const { filterCriteria } = useFilter(); // Access filter criteria from context
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });
  const [showMessage, setShowMessage] = useState(false); // For displaying message when data points < 4

  // Load filter data for unique values in each category
  useEffect(() => {
    if (!Array.isArray(rawData)) {
      console.error('rawData is not an array');
      return;
    }

    const uniqueLocations = [...new Set(rawData.map((item) => item?.location_name).filter(Boolean))].sort();
    const uniqueBusinessUnits = [...new Set(rawData.map((item) => item?.business_unit_name).filter(Boolean))].sort();
    const uniqueDepartments = [...new Set(rawData.map((item) => item?.department_name).filter(Boolean))].sort();
    const uniqueJobClassifications = [...new Set(rawData.map((item) => item?.job_classification_name).filter(Boolean))].sort();
    const uniqueTenures = [...new Set(rawData.map((item) => item?.length_of_service_name).filter(Boolean))].sort(
      (a, b) => lengthOfServiceOrder.indexOf(a) - lengthOfServiceOrder.indexOf(b)
    );
    const uniqueGenerations = [...new Set(rawData.map((item) => item?.generation_name).filter(Boolean))].sort(
      (a, b) => generationOrder.indexOf(a) - generationOrder.indexOf(b)
    );
    const uniqueExemptStatuses = [...new Set(rawData.map((item) => item?.exempt_status_name).filter(Boolean))].sort();
    const uniqueGenders = [...new Set(rawData.map((item) => item?.gender_name).filter(Boolean))].sort();
    const uniqueRaces = [...new Set(rawData.map((item) => item?.race_name).filter(Boolean))].sort();
    const uniqueSupervisors = [...new Set(rawData.map((item) => item?.additional_demographic_01_name).filter(Boolean))].sort();

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  // Update heatmap data when filters or axis options change
  useEffect(() => {
    if (!Array.isArray(rawData)) {
      console.error('rawData is not an array');
      return;
    }

    const countData = {};

    // Ensure filterCriteria fields are always initialized to empty arrays if undefined
    const {
      location_name = [],
      business_unit_name = [],
      department_name = [],
      job_classification_name = [],
      length_of_service_name = [],
      generation_name = [],
      exempt_status_name = [],
      gender_name = [],
      race_name = [],
      supervisor_name = [],
    } = filterCriteria || {}; // Add fallback in case filterCriteria is null or undefined

    const filteredData = rawData
      .filter((record) => record?.Q_Num === selectedQNum.value)
      .filter((record) => {
        // Apply all the selected filters to the data
        return (
          (location_name.length === 0 || location_name.includes(record?.location_name)) &&
          (business_unit_name.length === 0 || business_unit_name.includes(record?.business_unit_name)) &&
          (department_name.length === 0 || department_name.includes(record?.department_name)) &&
          (job_classification_name.length === 0 || job_classification_name.includes(record?.job_classification_name)) &&
          (length_of_service_name.length === 0 || length_of_service_name.includes(record?.length_of_service_name)) &&
          (generation_name.length === 0 || generation_name.includes(record?.generation_name)) &&
          (exempt_status_name.length === 0 || exempt_status_name.includes(record?.exempt_status_name)) &&
          (gender_name.length === 0 || gender_name.includes(record?.gender_name)) &&
          (race_name.length === 0 || race_name.includes(record?.race_name)) &&
          (supervisor_name.length === 0 || supervisor_name.includes(record?.supervisor_name))
        );
      });

    // Show a message if there are fewer than 4 data points
    setShowMessage(filteredData.length < 4);

    if (filteredData.length < 4) {
      setHeatmapData([]); // Reset the data to avoid rendering with insufficient data
      return;
    }

    // Transform the filtered data for the heatmap
    filteredData.forEach((record) => {
      const xValue = record?.[selectedXAxis.value];
      if (!xValue) return;

      for (let i = 1; i <= 8; i++) {
        const theme = record?.[`Theme_${i}`];
        if (!theme || theme.toLowerCase() === 'na') continue;

        const key = `${xValue}|${theme}`;
        if (!countData[key]) {
          countData[key] = 0;
        }
        countData[key] += 1;
      }
    });

    let transformedData = [];

    Object.keys(countData).forEach((key) => {
      const [xValue, theme] = key.split('|');
      if (xValue && theme) {
        let series = transformedData.find((s) => s.name === theme);
        if (!series) {
          series = { name: theme, data: [] };
          transformedData.push(series);
        }
        series.data.push({ x: xValue, y: countData[key] });
      }
    });

    const xOrder =
      selectedXAxis.value === 'generation_name' ? generationOrder : selectedXAxis.value === 'length_of_service_name' ? lengthOfServiceOrder : null;

    if (xOrder) {
      transformedData.forEach((series) => {
        series.data.sort((a, b) => xOrder.indexOf(a.x) - xOrder.indexOf(b.x));
      });
    }

    transformedData.sort((a, b) => {
      const sumA = a.data.reduce((sum, point) => sum + point.y, 0);
      const sumB = b.data.reduce((sum, point) => sum + point.y, 0);
      return sumB - sumA;
    });

    setHeatmapData(transformedData);
  }, [selectedXAxis, selectedQNum, filterCriteria]); // Re-run the effect whenever filterCriteria changes

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prevState) => !prevState);
  }, []);

  const toggleFilterSidebar = useCallback(() => {
    setIsFilterSidebarOpen((prevState) => !prevState);
  }, []);

  const chartOptions = {
    chart: {
      type: 'heatmap',
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['black'],
      },
      formatter: function (val) {
        return val;
      },
    },
    colors: ['#00A5E3'], // Customize the color of the heatmap
    xaxis: {
      title: {
        text: selectedXAxis.label,
      },
      labels: {
        rotate: -45, // Rotating the x-axis labels to avoid overlap
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Themes',
      },
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            { from: 0, to: 5, color: '#b3e5fc' },
            { from: 6, to: 10, color: '#81d4fa' },
            { from: 11, to: 15, color: '#4fc3f7' },
            { from: 16, to: 20, color: '#29b6f6' },
            { from: 21, to: 30, color: '#039be5' },
            { from: 31, to: 40, color: '#0288d1' },
            { from: 41, to: 50, color: '#0277bd' },
            { from: 51, to: 60, color: '#01579b' },
          ],
        },
      },
    },
  };

  return (
    <div className="ccitextmap-dashboard-container">
      <Header toggleSidebar={toggleSidebar} toggleFilterSidebar={toggleFilterSidebar} />
      <NavigationSidebar isOpen={isSidebarOpen} />
      {filterData.locations.length > 0 && (
        <FilterSidebar
          isOpen={isFilterSidebarOpen}
          locations={filterData.locations}
          businessUnits={filterData.businessUnits}
          departments={filterData.departments}
          jobClassifications={filterData.jobClassifications}
          tenures={filterData.tenures}
          generations={filterData.generations}
          exemptStatuses={filterData.exemptStatuses}
          genders={filterData.genders}
          races={filterData.races}
          supervisors={filterData.supervisors}
        />
      )}
      <div className="ccitextmap-main-content">
        <div className="ccitextmap-filters">
          <div className="ccitextmap-dropdown">
            <label>X-Axis</label>
            <Select options={xAxisOptions} value={selectedXAxis} onChange={setSelectedXAxis} isClearable={false} />
          </div>
          <div className="ccitextmap-dropdown">
            <label>Question</label>
            <Select options={qNumOptions} value={selectedQNum} onChange={setSelectedQNum} isClearable={false} />
          </div>
        </div>
        <div className="ccitextmap-content-wrapper">
          <div className="ccitextmap-charts-wrapper">
            <div className="ccitextmap-charts-container">
              <div className="ccitextmap-chart">
                <h2 className="ccitextmap-chart-title">Heatmap</h2>
                <div className="ccitextmap-chart-container">
                  {showMessage ? (
                    <div style={{ color: 'red', marginTop: '20px' }}>
                      Current filter selections are returning fewer than the minimum number of respondents. Please expand the selection to include more respondents.
                    </div>
                  ) : (
                    <ApexCharts options={chartOptions} series={heatmapData} type="heatmap" height="800px" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCITextMap;
