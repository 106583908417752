import React, { useState, useEffect, useCallback } from 'react';
import './CCIDemographicsDashboard.css';
import NavigationSidebar from '../components/Navigationsidebar/NavigationSidebar';
import Header from '../components/Header';
import D01BarChartViz01 from '../components/Dashboardviz/D01/DB01Viz1';
import D01BarChartViz02 from '../components/Dashboardviz/D01/DB01Viz2';
import D01BarChartViz03 from '../components/Dashboardviz/D01/DB01Viz3';
import D01BarChartViz04 from '../components/Dashboardviz/D01/DB01Viz4';
import D01BarChartViz05 from '../components/Dashboardviz/D01/DB01Viz5';
import D01BarChartViz06 from '../components/Dashboardviz/D01/DB01Viz6';
import D01BarChartViz07 from '../components/Dashboardviz/D01/DB01Viz7';
import D01BarChartViz08 from '../components/Dashboardviz/D01/DB01Viz8';
import D01BarChartViz09 from '../components/Dashboardviz/D01/DB01Viz9';
import D01BarChartViz10 from '../components/Dashboardviz/D01/Db01Viz10';
import FilterSidebar from '../components/FilterSidebar/FilterSidebar';
import RespondentsSummary from '../components/Dashboardviz/D01/DB01Viz11';
import { useFilter } from '../FilterContext';
import jsonData from '../som_2024-sm-data-numeric.json';

const CCIDemographs = () => {
  const { filterCriteria, setFilterCriteria } = useFilter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    locations: [],
    businessUnits: [],
    departments: [],
    jobClassifications: [],
    tenures: [],
    generations: [],
    exemptStatuses: [],
    genders: [],
    races: [],
    supervisors: [],
  });

  useEffect(() => {
    const sortAlphabetically = (array) => array.sort((a, b) => a.localeCompare(b));

    const uniqueLocations = sortAlphabetically([...new Set(jsonData.map(item => item.location_name))]);
    const uniqueBusinessUnits = sortAlphabetically([...new Set(jsonData.map(item => item.business_unit_name))]);
    const uniqueDepartments = sortAlphabetically([...new Set(jsonData.map(item => item.department_name))]);
    const uniqueJobClassifications = sortAlphabetically([...new Set(jsonData.map(item => item.job_classification_name))]);

    const tenureOrder = [
      'Less than 1 year',
      '1 to 2 years',
      '3 to 5 years',
      '6 to 10 years',
      '11 to 19 years',
      '20 years or more',
      'No Response'
    ];
    const uniqueTenures = [...new Set(jsonData.map(item => item.length_of_service_name))].sort((a, b) => tenureOrder.indexOf(a) - tenureOrder.indexOf(b));

    const generationOrder = [
      'Traditionalist',
      'Baby Boomer',
      'Generation X',
      'Millennial',
      'Generation Z',
      'No Response'
    ];
    const uniqueGenerations = [...new Set(jsonData.map(item => item.generation_name))].sort((a, b) => generationOrder.indexOf(a) - generationOrder.indexOf(b));

    const uniqueExemptStatuses = sortAlphabetically([...new Set(jsonData.map(item => item.exempt_status_name))]);
    const uniqueGenders = sortAlphabetically([...new Set(jsonData.map(item => item.gender_name))]);
    const uniqueRaces = sortAlphabetically([...new Set(jsonData.map(item => item.race_name))]);
    const uniqueSupervisors = sortAlphabetically([...new Set(jsonData.map(item => item.additional_demographic_01_name))]);

    setFilterData({
      locations: uniqueLocations,
      businessUnits: uniqueBusinessUnits,
      departments: uniqueDepartments,
      jobClassifications: uniqueJobClassifications,
      tenures: uniqueTenures,
      generations: uniqueGenerations,
      exemptStatuses: uniqueExemptStatuses,
      genders: uniqueGenders,
      races: uniqueRaces,
      supervisors: uniqueSupervisors,
    });
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prevState => !prevState);
  }, []);

  const toggleFilterSidebar = useCallback(() => {
    setIsFilterSidebarOpen(prevState => !prevState);
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterCriteria(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="ccidemographics-dashboard-container">
      <Header 
        toggleSidebar={toggleSidebar} 
        toggleFilterSidebar={toggleFilterSidebar} 
        refreshPage={refreshPage} 
      />
      <NavigationSidebar 
        className={isSidebarOpen ? 'ccidemographics-sidebar-open' : 'ccidemographics-sidebar-closed'} 
        isOpen={isSidebarOpen} 
      />
      <FilterSidebar 
        filterCriteria={filterCriteria}
        locations={filterData.locations}
        businessUnits={filterData.businessUnits}
        departments={filterData.departments}
        jobClassifications={filterData.jobClassifications}
        tenures={filterData.tenures}
        generations={filterData.generations}
        exemptStatuses={filterData.exemptStatuses}
        genders={filterData.genders}
        races={filterData.races}
        supervisors={filterData.supervisors}
        handleFilterChange={handleFilterChange}
        isOpen={isFilterSidebarOpen}
      />
      <div className="ccidemographics-main-content">
        <div className="ccidemographics-grid-container">
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Location</h2>
            <div className="ccidemographics-card-content">
              <D01BarChartViz01 filterCriteria={filterCriteria} />
            </div>
          </div>
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Location 2</h2>
            <div className="ccidemographics-card-content">
              <D01BarChartViz02 filterCriteria={filterCriteria} />
            </div>
          </div>
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Department</h2>
            <div className="ccidemographics-card-content">
              <D01BarChartViz03 filterCriteria={filterCriteria} />
            </div>
          </div>
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Job Classification</h2>
            <div className="ccidemographics-card-content">
              <D01BarChartViz04 filterCriteria={filterCriteria} />
            </div>
          </div>
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Tenure</h2>
            <div className="ccidemographics-card-content">
              <D01BarChartViz05 filterCriteria={filterCriteria} />
            </div>
          </div>
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Generation</h2>
            <div className="ccidemographics-card-content">
              <D01BarChartViz06 filterCriteria={filterCriteria} />
            </div>
          </div>
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Work Status</h2>
            <div className="ccidemographics-card-content">
              <D01BarChartViz07 filterCriteria={filterCriteria} />
            </div>
          </div>
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Gender</h2>
            <div className="ccidemographics-card-content">
              <D01BarChartViz08 filterCriteria={filterCriteria} />
            </div>
          </div>
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Ethnicity</h2>
            <div className="ccidemographics-card-content">
              <D01BarChartViz09 filterCriteria={filterCriteria} />
            </div>
          </div>
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Supervisor</h2>
            <div className="ccidemographics-card-content">
              <D01BarChartViz10 filterCriteria={filterCriteria} />
            </div>
          </div>
        </div>
        <div className="ccidemographics-right-sidebar">
          <div className="ccidemographics-card">
            <h2 className="ccidemographics-card-header">Respondents Summary</h2>
            <div className="ccidemographics-card-content">
              <RespondentsSummary filterCriteria={filterCriteria} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCIDemographs;
