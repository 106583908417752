import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const BottomLeftChart = ({ filterCriteria }) => {
  const [filteredScores, setFilteredScores] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const [unfilteredScores, setUnfilteredScores] = useState({
    NR: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  useEffect(() => {
    const processScores = (data) => {
      const scores = {
        NR: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      };

      data.forEach((item) => {
        const score = item.MQ0301;
        if (
          score === 1 ||
          score === 2 ||
          score === 3 ||
          score === 4 ||
          score === 5
        ) {
          scores[score]++;
        } else {
          scores.NR++;
        }
      });

      return scores;
    };

    // Apply filter criteria
    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every(
        (key) =>
          filterCriteria[key].length === 0 ||
          filterCriteria[key].includes(item[key])
      );
    });

    // Calculate filtered and unfiltered scores
    const newFilteredScores = processScores(filteredData);
    const newUnfilteredScores = processScores(jsonData);

    setFilteredScores(newFilteredScores);
    setUnfilteredScores(newUnfilteredScores);
  }, [filterCriteria]);

  const labelToScoreKeyMap = {
    "Not at all": 1,
    "Slightly": 2,
    "Somewhat": 3,
    "Strongly": 4,
    "Very Strongly": 5,
    "NR": "NR", // Assuming NR is a valid key in filteredScores
  };

  const totalFiltered = Object.values(filteredScores).reduce(
    (acc, curr) => acc + curr,
    0
  );
  const totalUnfiltered = Object.values(unfilteredScores).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const seriesData = Object.keys(filteredScores).map((key) => {
    const filteredPercentage =
      totalFiltered > 0 ? (filteredScores[key] / totalFiltered) * 100 : 0;
    const unfilteredPercentage =
      totalUnfiltered > 0 ? (unfilteredScores[key] / totalUnfiltered) * 100 : 0;

    return {
      x: key,
      y: filteredPercentage, // Use the filtered percentage for the y-axis
      goals: [
        {
          name: "Unfiltered",
          value: unfilteredPercentage, // Use unfiltered percentage for goals
          strokeHeight: 12,
          strokeColor: "#6D6D6D",
        },
      ],
    };
  });

  const options = {
    chart: {
      type: "bar",
      height: "300px",
      width:"700px",
    },
    colors: ["#F28E2B", "#F28E2B", "#F1CE63", "#499894", "#499894", "#BAB0AC"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        const xAxisLabel = opt.w.globals.labels[opt.dataPointIndex];
        const scoreKey = labelToScoreKeyMap[xAxisLabel];
        const filteredValue = filteredScores[scoreKey] || 0;
        const unfilteredValue = unfilteredScores[scoreKey] || 0;

        const filteredPercentage =
          totalFiltered > 0
            ? ((filteredValue / totalFiltered) * 100).toFixed(1)
            : "0.00";
        const unfilteredPercentage =
          totalUnfiltered > 0
            ? ((unfilteredValue / totalUnfiltered) * 100).toFixed(1)
            : "0.00";

        return `${filteredPercentage}% | ${unfilteredPercentage}%`; // Display filtered and unfiltered percentages
      },
      style: {
        colors: ["#000"], // Set data labels to black
      },
      offsetX: 45, // Move labels off the bars
    },
    xaxis: {
      categories: [
        "Strongly Disagree",
        "Disagree",
        "Neither Agree nor Disagree",
        "Agree",
        "Strongly Agree",
        "NR",
      ],
      title: {
        text: "% of Responses", // Add title to the y-axis
        style: {
          fontSize: "12px",
          fontWeight: "normal",
          color: "#000",
        },
      },
    },
    yaxis: {
      max: 100, // Set maximum y-axis value to 100%
      labels: {
        formatter: (val) => {
          return typeof val === "number" ? `${val.toFixed(0)}` : val; // Ensure val is a number
        },
      },
      title: {
        text: "% of Responses", // Add title to the y-axis
        style: {
          fontSize: "12px",
          fontWeight: "normal",
          color: "#000",
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const xAxisLabel = w.globals.labels[dataPointIndex];
        const scoreKey = labelToScoreKeyMap[xAxisLabel];
        const filteredValue = filteredScores[scoreKey] || 0;
        const unfilteredValue = unfilteredScores[scoreKey] || 0;

        const filteredPercentage =
          totalFiltered > 0
            ? ((filteredValue / totalFiltered) * 100).toFixed(1)
            : "0.00";
        const unfilteredPercentage =
          totalUnfiltered > 0
            ? ((unfilteredValue / totalUnfiltered) * 100).toFixed(1)
            : "0.00";

        return `<div class="apexcharts-tooltip-text">
                  <div id="tooltip-title">Response:&nbsp; ${xAxisLabel}</div>  
                  <ul>
                  <li>Filtered: &nbsp; ${filteredPercentage}%</li>
                  <li>Unfiltered: &nbsp; ${unfilteredPercentage}%</li>
                  </ul>
                </div>`;
      },
    },
    annotations: {
      points: Object.keys(unfilteredScores).map((key, index) => {
        return {
          x: key,
          y: unfilteredScores[key],
          marker: {
            size: 5, // Make the marker thicker
            fillColor: "#fff",
            strokeColor: "#fff",
          },
          label: {
            borderColor: "#fff",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#fff",
            },
            text: `${unfilteredScores[key]}`,
          },
        };
      }),
    },
    legend: {
      show: false,
      labels: {
        useSeriesColors: true,
      },
    },
  };

  return (
    <div id="d08-bottom-left">
      <Chart
        options={options}
        series={[{ name: "Filtered", data: seriesData }]} // Set the name for the filtered data
        type="bar"
        height="300px"
        width="700px"
      />
    </div>
  );
};

export default BottomLeftChart;
