import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import { useFilter } from "../../../../FilterContext"; // Ensure the path is correct
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct

const calculateAverageScores = (data, categories) => {
  const scores = Object.keys(categories).reduce((acc, key) => {
    const categoryScores = categories[key].map((question) =>
      data
        .map((item) => item[question])
        .filter(
          (score) =>
            score !== undefined && score !== null && score >= 1 && score <= 5
        )
    );

    const categoryAverages = categoryScores.map((scoresArray) =>
      scoresArray.length
        ? scoresArray.reduce((a, b) => a + b, 0) / scoresArray.length
        : 0
    );

    acc[key] =
      categoryAverages.reduce((a, b) => a + b, 0) / categoryAverages.length;
    return acc;
  }, {});

  // Format scores to 1 decimal place
  Object.keys(scores).forEach((key) => {
    scores[key] = parseFloat(scores[key].toFixed(1));
  });

  return scores;
};

const D03BarCharts = () => {
  const { filterCriteria } = useFilter();
  const [showMessage, setShowMessage] = useState(false); // State for controlling the message visibility

  useEffect(() => {
    const categories = {
      "Role Clarity: Clarity": ["MQ0044", "MQ0047", "MQ0017"],
      "Role Clarity: Resources": ["MQ0048", "MQ0051", "MQ0050"],
      "Role Clarity: Work Outcomes": ["MQ0095", "MQ0016", "MQ0059"],
      "Role Clarity: Feedback": ["MQ0104", "MQ0173", "MQ0054"],
      "Work Context: Safety": ["MQ0190", "MQ0141", "MQ0343"],
      "Work Context: Compensation": ["MQ0088", "MQ0090", "MQ0091"],
      "Work Context: Leadership": ["MQ0086", "MQ0034", "MQ0041"],
      "Work Context: Communication": ["MQ0194", "MQ0321", "MQ0058"],
      "Work Context: Supervision": ["MQ0056", "MQ0057", "MQ0038"],
      "Job Satisfaction: Recognition": ["MQ0053", "MQ0171", "MQ0103"],
      "Job Satisfaction: Autonomy": ["MQ0071", "MQ0202", "MQ0027"],
      "Job Satisfaction: Meaningfulness": ["MQ0023", "MQ0024", "MQ0026"],
      "Job Satisfaction: Growth": ["MQ0064", "MQ0062", "MQ0043"],
      "Job Satisfaction: Teamwork": ["MQ0340", "MQ0341", "MQ0094"],
      "Organizational Commitment: Belonging": ["MQ0021", "MQ0080", "MQ0302"],
      "Organizational Commitment: Vision and Values": [
        "MQ0072",
        "MQ0029",
        "MQ0012",
      ],
      "Organizational Commitment: Inclusion": ["MQ0311", "MQ0312", "MQ0331"],
      "Organizational Commitment: Openness": ["MQ0320", "MQ0046", "MQ0100"],
    };

    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        const criteria = filterCriteria[key];
        if (Array.isArray(criteria)) {
          return criteria.length === 0 || criteria.includes(item[key]);
        }
        return criteria === "" || item[key] === criteria;
      });
    });

    // Determine the total number of respondents
    const totalRespondents = filteredData.length;

    // Show or hide the message based on respondent count
    setShowMessage(totalRespondents < 4);

    // If not enough respondents, don't proceed with chart data processing
    if (totalRespondents < 4) return;

    const scores = calculateAverageScores(filteredData, categories);

    const options = {
      chart: {
        type: "bar",
        height: "115vh",
        margin: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          borderRadiusApplication: "end",
          horizontal: true,
          distributed: false,
          dataLabels: {
            position: "top",
          },
          barHeight: "90%", // Adjust the height of the bars to reduce spacing
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(1);
        },
        offsetX: 35, // Move labels off the bars
        style: {
          colors: ["#000"], // Set data labels to black
        },
      },
    };

    const createChart = (element, data, categories, color) => {
      const optionsViz = {
        ...options,
        series: [
          {
            name: "Avg Score",
            data,
          },
        ],
        colors: [color],
        grid: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        },

        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          max: 5.0,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          categories,
        },
        yaxis: {
          labels: {
            show: true,
            minWidth: 120,
          },
        },
      };

      const chart = new ApexCharts(document.querySelector(element), optionsViz);
      chart.render();
      return chart;
    };

    const charts = [
      createChart(
        "#rc-bar",
        [
          scores["Role Clarity: Clarity"],
          scores["Role Clarity: Feedback"],
          scores["Role Clarity: Resources"],
          scores["Role Clarity: Work Outcomes"],
        ],
        ["Clarity", "Feedback", "Resources", "Work Outcomes"],
        "#004983"
      ),

      createChart(
        "#js-bar",
        [
          scores["Job Satisfaction: Autonomy"],
          scores["Job Satisfaction: Growth"],
          scores["Job Satisfaction: Meaningfulness"],
          scores["Job Satisfaction: Recognition"],
          scores["Job Satisfaction: Teamwork"],
        ],
        ["Autonomy", "Growth", "Meaningfulness", "Recognition", "Teamwork"],
        "#008000"
      ),

      createChart(
        "#wc-bar",
        [
          scores["Work Context: Communication"],
          scores["Work Context: Compensation"],
          scores["Work Context: Leadership"],
          scores["Work Context: Safety"],
          scores["Work Context: Supervision"],
        ],
        [
          "Communication",
          "Compensation",
          "Leadership",
          "Safety",
          "Supervision",
        ],
        "#ff8c00"
      ),

      createChart(
        "#oc-bar",
        [
          scores["Organizational Commitment: Belonging"],
          scores["Organizational Commitment: Inclusion"],
          scores["Organizational Commitment: Openness"],
          scores["Organizational Commitment: Vision and Values"],
        ],
        ["Belonging", "Inclusion", "Openness", "Vision and Values"],
        "#ff0000"
      ),
    ];

    // Clean up the charts on component unmount
    return () => {
      charts.forEach((chart) => chart.destroy());
    };
  }, [filterCriteria]);

  return (
    <div>
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}
      {/* Only render charts if there are enough respondents */}
      <div style={{ display: showMessage ? "none" : "block" }}>
        <div id="rc-bar"></div>
        <div id="js-bar"></div>
        <div id="wc-bar"></div>
        <div id="oc-bar"></div>
      </div>
    </div>
  );
};

export default D03BarCharts;
