import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import { useFilter } from "../../../../FilterContext"; // Ensure the path is correct
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct
import "./engagement-summary.css"; // Import the CSS file

const RoleClarityChart = () => {
  const { filterCriteria } = useFilter();

  const calculateAverageScores = (data, categories) => {
    const scores = Object.keys(categories).reduce((acc, key) => {
      const categoryScores = categories[key].map((question) =>
        data
          .map((item) => item[question])
          .filter(
            (score) =>
              score !== undefined && score !== null && score >= 1 && score <= 5
          )
      );

      const categoryAverages = categoryScores.map((scoresArray) =>
        scoresArray.length
          ? scoresArray.reduce((a, b) => a + b, 0) / scoresArray.length
          : 0
      );

      acc[key] =
        categoryAverages.reduce((a, b) => a + b, 0) / categoryAverages.length;
      return acc;
    }, {});

    Object.keys(scores).forEach((key) => {
      scores[key] = parseFloat(scores[key].toFixed(1));
    });

    return scores;
  };

  useEffect(() => {
    const categories = {
      "Role Clarity: Clarity": ["MQ0044", "MQ0047", "MQ0017"],
      "Role Clarity: Resources": ["MQ0048", "MQ0051", "MQ0050"],
      "Role Clarity: Work Outcomes": ["MQ0095", "MQ0016", "MQ0059"],
      "Role Clarity: Feedback": ["MQ0104", "MQ0173", "MQ0054"],
    };

    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        const criteria = filterCriteria[key];
        if (Array.isArray(criteria)) {
          return criteria.length === 0 || criteria.includes(item[key]);
        }
        return criteria === "" || item[key] === criteria;
      });
    });

    const scores = calculateAverageScores(filteredData, categories);

    const options = {
      chart: {
        type: "bar",
        height: "100%",  // This will make the chart fill the height
        width: "100%",   // This will make the chart fill the width
        margin: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      legend: {
        show: false,
      },
      title: {
        text: "Average Scores by Role Clarity",
        align: "Center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          color: "#263238",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          horizontal: true,
          dataLabels: {
            position: "top",
          },
          barHeight: "50%",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => val.toFixed(1),
        offsetX: 35,
        style: {
          colors: ["#000"],
        },
      },
      series: [
        {
          name: "Avg Score",
          data: [
            scores["Role Clarity: Clarity"],
            scores["Role Clarity: Feedback"],
            scores["Role Clarity: Resources"],
            scores["Role Clarity: Work Outcomes"],
          ],
        },
      ],
      colors: ["#004983"],
      xaxis: {
        max: 5.0,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: ["Clarity", "Feedback", "Resources", "Work Outcomes"],
      },
      yaxis: {
        labels: {
          show: true,
          minWidth: 120,
        },
      },
    };

    const chart = new ApexCharts(document.querySelector("#rc-bar"), options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [filterCriteria]);

  return <div className="engagement-summary-chart-container"><div id="rc-bar"></div></div>;
};

export default RoleClarityChart;
