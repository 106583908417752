import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filterCriteria, setFilterCriteria] = useState({
    location_name: '',
    business_unit_name: '',
    department_name: '',
    job_classification_name: '',
    length_of_service_name: '',
    generation_name: '',
    exempt_status_name: '',
    gender_name: '',
    race_name: '',
    additional_demographic_01_name: '',
  });

  // Order arrays for consistent ordering
  const tenureOrder = [
    'Less than 1 year',
    '1 to 2 years',
    '3 to 5 years',
    '6 to 10 years',
    '11 to 19 years',
    '20 years or more'
  ];

  const generationOrder = [
    'Traditionalist',  // Ensure singular form to match JSON data
    'Baby Boomer',     // Ensure singular form to match JSON data
    'Generation X',
    'Millennial',      // Ensure singular form to match JSON data
    'Generation Z',
    'No Response'
  ];

  // Function to standardize tenure names
  const standardizeTenure = (tenure) => {
    switch (tenure) {
      case '11 to 19 years': 
        return '11 to 19 years'; // Ensure consistent terminology
      case '20+ years': 
      case '20 years or more':
        return '20 years or more'; // Standardize to common format
      default: 
        return tenure;
    }
  };

  // Function to standardize generation names
  const standardizeGeneration = (generation) => {
    // Ensure the generation name matches the order array format
    return generation;
  };

  // Function to set filter criteria with standardization
  const updateFilterCriteria = (name, value) => {
    let standardizedValue = value;
    if (name === 'length_of_service_name') {
      standardizedValue = standardizeTenure(value);
    } else if (name === 'generation_name') {
      standardizedValue = standardizeGeneration(value);
    }

    setFilterCriteria(prevState => ({
      ...prevState,
      [name]: standardizedValue,
    }));
  };

  return (
    <FilterContext.Provider value={{ filterCriteria, setFilterCriteria, updateFilterCriteria }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);
