import React, { useEffect, useState } from "react";
import jsonData from "../../../../som_2024-sm-data-numeric.json"; // Ensure the path is correct
import "../../../../pages/CCIDistributionDashboard.css"; // Import the CSS file for styling

const AverageScoresTable = ({ filterCriteria = {} }) => {
  const [data, setData] = useState({
    "Clarity": { average: 0, responses: 0 },
    "Feedback": { average: 0, responses: 0 },
    "Resources": { average: 0, responses: 0 },
    "Work Outcomes": { average: 0, responses: 0 },
  });
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility

  useEffect(() => {
    const questions = {
      "Clarity": ["MQ0044", "MQ0047", "MQ0017"],
      "Feedback": ["MQ0104", "MQ0173", "MQ0054"],
      "Resources": ["MQ0048", "MQ0051", "MQ0050"],
      "Work Outcomes": ["MQ0095", "MQ0016", "MQ0059"],
    };

    const calculateAverageScores = (data, questions) => {
      const result = {};

      Object.keys(questions).forEach((category) => {
        let totalScore = 0;
        let totalResponses = 0;

        data.forEach((item) => {
          questions[category].forEach((question) => {
            const response = item[question];
            if (
              response !== null &&
              response !== undefined &&
              response >= 1 &&
              response <= 5
            ) {
              totalScore += response;
              totalResponses++;
            }
          });
        });

        // Calculate average and ensure it's between 1 and 5
        let average =
          totalResponses > 0 ? (totalScore / totalResponses).toFixed(1) : "0.0";
        average = Math.min(Math.max(parseFloat(average), 1), 5).toFixed(1); // Ensure between 1 and 5
        result[category] = { average, responses: totalResponses };
      });

      return result;
    };

    const filteredData = jsonData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        const isCriteriaEmpty =
          !filterCriteria[key] || filterCriteria[key].length === 0;
        const doesItemMatchCriteria = filterCriteria[key]?.includes(item[key]);
        return isCriteriaEmpty || doesItemMatchCriteria;
      });
    });

    // Determine the total number of respondents based on filtered data
    const totalRespondents = filteredData.length;

    // Show message if there are fewer than 4 respondents
    setShowMessage(totalRespondents < 4);

    const newData = calculateAverageScores(filteredData, questions);
    setData(newData);
  }, [filterCriteria]);

  return (
    <div className="ccidistribution-table">
      {/* Show message if there are fewer than 4 respondents */}
      {showMessage && (
        <div id="message" style={{ color: "red", marginTop: "20px" }}>
          Current filter selections are returning fewer than the minimum number of respondents. Elements of this view may be suppressed. Please expand the selection to include more respondents to allow these elements to be displayed.
        </div>
      )}

      {/* Table will only be displayed if there are enough respondents */}
      {!showMessage && (
        <div>
          <div className="table-header">
            <div className="table-column">Engagement Area</div>
            <div className="table-column">Avg Score</div>
            <div className="table-column"># Responses</div>
          </div>
          {Object.keys(data).map((category) => (
            <div className="table-row" key={category}>
              <div className="table-column">{category}</div>
              <div className="table-column">{data[category].average}</div>
              <div className="table-column">{data[category].responses}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AverageScoresTable;
